import React from "react"
import {VictoryChart, VictoryLegend, VictoryLine, VictoryScatter, VictoryAxis, VictoryTheme, VictoryTooltip, VictoryBrushContainer, createContainer} from 'victory';
import { calculateZScore, formatNumber } from "../../utility/Helper";
import { Settings } from "../../utility/Global";
import { mean, std } from "../../utility/MathHelper";

let colors = ['#CADEED', '#B7D0E1', '#91AEC4', '#5F84A2', '#194569', '#000000']

const VictoryZoomVoronoiContainer = createContainer("zoom", "voronoi");

function filterOutAnythingHigh(data) {
    let allY = data.filter(_ => _.y > 0).map(_ => _.y)
    if (allY.length === 0) return data
    let yStd = std(allY)
    let avgY = mean(allY)

    data.forEach(_ => {
        _.zScore = calculateZScore(_.y, avgY, yStd)
    })
    let filtered = data.filter(_ => _.zScore <= 2.5 && _.zScore >= -2.5)

    return filtered
}

const SaleChart = ({chartData, movingAverage, unitMovingAverage, weightedAverages, scatterSize = 5, brushArea = 0, showLegend = true, showOutliers = false, sameColor = false, cWidth = 450, cHeight = 450}) => {
    const legendData = chartData?.map(_ => {
        return {
            name: _.year,
            symbol: {fill : sameColor ? colors[colors.length-1] : colors[_.year%6]} 
        }
    })    

    let brushStyles = {}
    
    let lowBrushX = (Math.floor(brushArea/100)*100) * (1 - Settings.bldgareaPercent)
    let highBrushX = (Math.ceil(brushArea/100)*100) * (1 + Settings.bldgareaPercent)
    let brushDomain = {}
    if (brushArea > 0) {
        brushDomain = {x: [lowBrushX, highBrushX]}

        brushStyles = {
            //axisLabel: {fontSize: 20, padding: 30},
            tickLabels: {padding: 0}
          }

    } 

    if (!showOutliers && chartData)
        chartData.forEach(_ => {
            _.data = filterOutAnythingHigh(_.data)
        })

    return <VictoryChart
        width={cWidth}
        height={cHeight}
        //categories={{x: months}}
        // animate={{
        //     duration: 2000,
        //     onLoad: { duration: 500 }
        // }}
        domainPadding={10}
        theme={VictoryTheme.material}
        containerComponent={
            brushArea > 0 ?
            <VictoryBrushContainer
            //brushDimension="y"
            brushDomain={brushDomain}
            disable={true}
            // allowDrag={false}
            // allowDraw={false}
            // allowResize={false}
            brushStyle={{stroke: "transparent", fill: "black", fillOpacity: 0.07}}
            //onZoomDomainChange={(domain, props) => checkZoomLevels(domain, props)}
            />
            :
            <VictoryZoomVoronoiContainer 
                voronoiDimension="x"
                //brushDimension="y"
                //brushDomain={{x: [1900,2100], y: [0,1000]}}
                //disable={true}
                labels={({ datum }) => `${formatNumber(datum.x)} | $${formatNumber(datum.y, 2)} | ${datum.year ?? ''}`}
                labelComponent={<VictoryTooltip cornerRadius={10} pointerLength={20} />}
              /> }
        >
            <VictoryAxis
                dependentAxis
                //label='PSF ($)'
                tickCount={12}
                standalone={false}
                //style={brushStyles}
                style={{
                    tickLabels: {padding: 8},
                    axisLabel: {padding: 10},
                  }}
                tickFormat={(t) => t < 0 ? '' : `$${t}`}
            />
            <VictoryAxis
                //label="Date"
                //tickFormat={() => ''}
                //tickValues={[]}
                tickCount={5}
                standalone={false}
                //style={brushStyles}
                style={{
                    tickLabels: {padding: 8},
                    axisLabel: {padding: 10},
                  }}
            />
            {showLegend && legendData && legendData.length > 0 ? (
                <VictoryLegend x={75} y={0}
                title="Years"
                centerTitle
                orientation="horizontal"
                gutter={20}
                style={{ border: { stroke: "black" }, title: {fontSize: 20 } }}
                data={legendData}
            />
            ) : null}
        {chartData?.map((element, index) => { 
        
        return <VictoryScatter
            key={index}
            animate={{
                duration: 1000,
                onLoad: { duration: 500 }
            }}
            interpolation='basis'
            style={{
                data: { fill: sameColor ? colors[colors.length-1] : colors[element?.year%6]},
            }}
            size={scatterSize}
            data={element?.data}
        />   
        } 
    )}
     {movingAverage && movingAverage.length > 0 ? (
          <VictoryLine
          animate={{
              duration: 1000,
              onLoad: { duration: 500 }
          }}
          interpolation='basis'
          style={{
              data: { strokeWidth: scatterSize, stroke: '#FFA500' },
              //parent: { border: `11px solid #ccc`}
              //labels:{fill: stringToColor(element?.neighborhood)}
          }}
          data={movingAverage}
        />   
     ) : null} 

    {unitMovingAverage && unitMovingAverage.length > 0 ? (
          <VictoryLine
          animate={{
              duration: 1000,
              onLoad: { duration: 500 }
          }}
          interpolation='basis'
          style={{
              data: { strokeWidth: scatterSize, stroke: '#FFD68A' },
              //parent: { border: `11px solid #ccc`}
              //labels:{fill: stringToColor(element?.neighborhood)}
          }}
          data={unitMovingAverage}
        />   
     ) : null} 

    {weightedAverages && weightedAverages.length > 0 ? (
         weightedAverages.map((_, index) => {
             return (
                <VictoryLine
                key={index}
                animate={{
                    duration: 1000,
                    onLoad: { duration: 500 }
                }}
                interpolation='basis'
                style={{
                    data: { strokeWidth: scatterSize, stroke: _.color },
                    //parent: { border: `11px solid #ccc`}
                    //labels:{fill: stringToColor(element?.neighborhood)}
                }}
                data={_.data}
              />   
             )
         })
     ) : null} 
    </VictoryChart>
}

export default React.memo(SaleChart)