import React from 'react';

const ArrowTriangle = ({ direction = 'right', size = 16, color = 'black' }) => {
  const getRotation = () => {
    switch (direction) {
      case 'up':
        return '270';
      case 'down':
        return '90';
      case 'left':
        return '180';
      case 'right':
      default:
        return '0';
    }
  };

  const rotation = getRotation();

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${rotation}deg)` }}
    >
      <polygon points="10,10 90,50 10,90" fill={color} />
    </svg>
  );
};

export default ArrowTriangle;