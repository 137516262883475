import React from "react"
import {VictoryChart, VictoryBar, VictoryAxis, VictoryTheme, VictoryTooltip, VictoryGroup} from 'victory';
import { formatNumber, roundToCeilByNearest, roundToFloorByNearest } from "../../utility/Helper";
import { min, max } from "../../utility/MathHelper";

const LTDSQCBarChart = ({chartData, width = 350, height = 350, tickFormat = ""}) => {

    let allZ = chartData?.map(_ => _.x)
    let minX = allZ.length > 0 ? min(allZ) : 0
    let maxX = allZ.length > 0 ? max(allZ) : 0

    let minXToUse = roundToFloorByNearest(minX)
    let maxXToUse = roundToCeilByNearest(maxX)
    if (minXToUse >= minX - .5) minXToUse -= .5
    if (maxXToUse <= maxX + .5) maxXToUse += .5

    //console.log(minX, maxX)
    //console.log(minXToUse, maxXToUse)
    let interval = 1

    const length = (Math.abs(maxXToUse) + Math.abs(minXToUse)) + 1// / interval + 1;
    let ticks = Array.from({ length }, (_, i) => minXToUse + i * interval);

    //console.log(length, ticks)

    return (
        <VictoryChart
        theme={VictoryTheme.material}
        domainPadding={25}
        width={width}
        height={height}
        >
            <VictoryAxis
                offsetX={50} crossAxis dependentAxis
                //tickValues={[50, 55, 60, 65, 70, 75, 80, 85, 90]}
                tickFormat={tickFormat}
            />
            <VictoryAxis 
            tickValues={ticks}
            tickFormat={(t) => `${formatNumber(t)}`} />
            <VictoryBar
                    data={chartData}
                    style={{ data: { 
                        fill: ({ datum }) => datum.color,
                        stroke: "black",
                        strokeWidth: 1
                    } }}
                    labelComponent={<VictoryTooltip />}
                />
            {/* <VictoryBar
                data={chartData}
                style={{ data: { 
                    fill: ({ datum }) => datum.color
                } }}
            /> */}
        </VictoryChart>
    )
}

export default React.memo(LTDSQCBarChart)