import React, { useEffect, useState, useRef } from 'react'
import mapboxgl from 'mapbox-gl';

import { mapbox_key } from '../../utility/Config'
import { CompBucketOption, mute_basemap } from '../../utility/Global'
import { addCircleToMap, addLineToMap, createGeoJSONCircle, createGeoJSONLine, LineDirection, removeSourceFromMap } from '../../utility/MapHelper';
import { bucketColor, removeCompMarkers, runCompMarkers } from '../Comps';
import CompsPopup from '../CompsPopup';

mapboxgl.accessToken = mapbox_key

const MapboxMap = ({ className, style, target, markerData, totalDistance = 1, popupPosition = 'down' }) => {
    const mapContainer = useRef()

    const [map, setMap] = useState(null)
    const [mapLoaded, setMapLoaded] = useState(false)

    const [targetCoords, setTargetCoords] = useState()
    //const [marker, setMarker] = useState(null)
    const [popupDetails, setPopupDetails] = useState(null)

    useEffect(() => {
        if (map === null) {
            setTimeout(() => {
                setMap(new mapboxgl.Map({
                    container: mapContainer.current,
                    style: mute_basemap,
                    center: [-73.9646518861252, 40.5892123321491],
                    zoom: 12,
                    minZoom: 10,
                    maxZoom: 14.25,
                    scrollZoom: true,
                    dragPan: true,
                    dragRotate: false,
                    doubleClickZoom: false
                }))

                //setMarker(new mapboxgl.Marker({ color: "blue" }))
            }, 1000);
        }
    }, [mapContainer])

    useEffect(() => {
        if (map && !mapLoaded) {
            map.on('load', () => setMapLoaded(true))
        }
    }, [map])

    useEffect(() => {
        if (mapLoaded) {
            if (target && target.longitude && target.latitude && map) {
                // marker.remove()
                // marker.setLngLat({ lng: target.longitude, lat: target.latitude });
                // marker.addTo(map);
                setTargetCoords([target.longitude, target.latitude])
            }
        }
    }, [target, mapLoaded])

    useEffect(() => {
        if (targetCoords) {
            flyToTarget()
            setCircleRadius(targetCoords)
        }
    }, [targetCoords, totalDistance])

    useEffect(() => {
        if (mapLoaded) {
            removeCompMarkers(map)
            if (markerData) {
                runCompMarkers(markerData, map, { resetView: false, overrideMarkerClass: false }, null, null, handleCompPopup, null)
            } else {
                removeRadiusCircles()
            }
        }
    }, [markerData, mapLoaded])

    const flyToTarget = () => {
        let zoom = 12.25 - ((totalDistance - 1) * .75)

        map.flyTo({
            center: targetCoords,
            zoom: zoom,
            essential: true // this animation is considered essential with respect to prefers-reduced-motion
        });
    }

    const setCircleRadius = (coordinates) => {
        removeRadiusCircles()

        map.addSource("center", createGeoJSONCircle(coordinates, 0.025));
        map.addSource("quarter_radius", createGeoJSONCircle(coordinates, totalDistance * .25));
        map.addSource("half_radius", createGeoJSONCircle(coordinates, totalDistance * .5));
        map.addSource("three_quarter_radius", createGeoJSONCircle(coordinates, totalDistance * .75));
        map.addSource("one_radius", createGeoJSONCircle(coordinates, totalDistance * 1));

        addCircleToMap(map, "one_radius", bucketColor(CompBucketOption.HIGH))
        addCircleToMap(map, "three_quarter_radius", bucketColor(CompBucketOption.MIDDLEHIGH))
        addCircleToMap(map, "half_radius", bucketColor(CompBucketOption.MIDDLELOW))
        addCircleToMap(map, "quarter_radius", bucketColor(CompBucketOption.LOW))
        addCircleToMap(map, "center", 'blue', 1, 'blue')

        map.addSource("north_line", createGeoJSONLine(coordinates, totalDistance));
        map.addSource("south_west_line", createGeoJSONLine(coordinates, totalDistance, LineDirection.SOUTH_WEST));
        map.addSource("south_east_line", createGeoJSONLine(coordinates, totalDistance, LineDirection.SOUTH_EAST));

        addLineToMap(map, "south_east_line")
        addLineToMap(map, "south_west_line")
        addLineToMap(map, "north_line")
    }

    const removeRadiusCircles = () => {
        removeSourceFromMap(map, 'center')
        removeSourceFromMap(map, 'quarter_radius')
        removeSourceFromMap(map, 'half_radius')
        removeSourceFromMap(map, 'three_quarter_radius')
        removeSourceFromMap(map, 'one_radius')

        removeSourceFromMap(map, 'north_line')
        removeSourceFromMap(map, 'south_west_line')
        removeSourceFromMap(map, 'south_east_line')
    }

    const handleCompPopup = (marker, lockPanel = false) => {
        setPopupDetails(marker)
    }

    return (
        <>
            <div ref={el => mapContainer.current = el} className={className} style={style} />
            {popupDetails ? (
                <CompsPopup
                    className={`mapbox-comps-popup-view-container ${popupPosition}`}
                    selectedCompDetails={popupDetails}
                />
            ) : null}
        </>
    )
}

export default React.memo(MapboxMap)