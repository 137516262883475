import React, { useEffect, useState } from 'react'

import { makeGetRequest } from '../../utility/Services';
import { CompTypeOption, Settings, today } from '../../utility/Global';
import { bucketColor, runComps } from '../Comps';
import { Button, Grid, Label, Loader, Segment, Form, Icon } from 'semantic-ui-react';
import { PropertyCondition, ValuationContainer } from '../extras/ValueContainer';
import Lot from '../../Tax-Lot';
import DataTable from 'react-data-table-component';
import { Badge, customStyles } from '../../utility/TableHelper';
import { calculateDifference, csvFileToArray, formatDate, formatNumber, getParcelAddressQueryParams } from '../../utility/Helper';
import LTDSChart, { getTDSChartData } from '../charts/LTDSChart';
import MapboxMap from '../maps/MapboxMap';
import { getChartMovingAverage } from '../charts/ChartHelper';
import { LotInformationPanel } from '../lots/LotInformationPanel';
import { useRef } from 'react';
import GoogleMap from '../maps/GoogleMap';
import { MoneyFormattedInput } from '../extras/InputElements';
import memoize from 'memoize-one';
import BucketCalc from '../../BucketCalc';
import FinalBuckets, { AppraisalCondition, AppraisalToPropertyCondition } from '../../FinalBuckets';
import SearchLocationInput, { getGoogleAddress } from '../extras/SearchLocationInput';
import { parcel_search_url } from '../../utility/Config';
import { min, max } from '../../utility/MathHelper';

const propertyConditionOptions = [
    { key: AppraisalCondition.C1, text: 'C1', value: AppraisalCondition.C1 },
    { key: AppraisalCondition.C2, text: 'C2', value: AppraisalCondition.C2 },
    { key: AppraisalCondition.C3, text: 'C3', value: AppraisalCondition.C3 },
    { key: AppraisalCondition.C4, text: 'C4', value: AppraisalCondition.C4 },
    { key: AppraisalCondition.C5, text: 'C5', value: AppraisalCondition.C5 },
    { key: AppraisalCondition.C6, text: 'C6', value: AppraisalCondition.C6 },
]

const columns = memoize((handleDelete, handleGoToUrbyn, handleGoToAppraisals) => [
    {
        name: 'Address',
        selector: 'address',
        sortable: true,
        grow: 1.5,
        format: row => <div>
            <div><b>{row.address}</b></div>
            <div>{row.city}, {row.state} {row.zip}</div>
        </div>
    },
    {
        name: 'Condition',
        selector: 'condition',
        sortable: true,
        center: true,
        format: row => <Badge text={row.condition} />
    },
    {
        name: 'Date',
        selector: 'date',
        sortable: true,
        format: row => row.date ? formatDate(row.date) : formatDate(new Date())
    },
    {
        name: 'Value',
        selector: 'value',
        sortable: true,
        format: row => (`$${formatNumber(row.value)}`)
    },
    {
        name: 'Poor',
        selector: 'poor',
        sortable: true,
        center: true,
        format: row => <Label className='value-badge' style={(row.poorChosen) ? { color: 'white', backgroundColor: row.poorColor } : null} horizontal>${formatNumber(row.poor)}</Label>
    },
    {
        name: 'Good',
        selector: 'good',
        sortable: true,
        center: true,
        format: row => <Label className='value-badge' style={(row.fairChosen) ? { color: 'white', backgroundColor: row.fairColor } : null} horizontal>${formatNumber(row.fair)}</Label>
    },
    {
        name: 'Excellent',
        selector: 'excellent',
        sortable: true,
        center: true,
        format: row => <Label className='value-badge' style={(row.excellentChosen) ? { color: 'white', backgroundColor: row.excellentColor } : null} horizontal>${formatNumber(row.excellent)}</Label>
    },
    {
        name: 'Confidence',
        selector: 'ltds',
        sortable: true,
        center: true,
        format: row => <Badge
            text={`${formatNumber(row?.confidence)}%`}
            isTrend={true}
            isUpTrend={row?.confidence_trend}
        />
    },
    {
        name: 'Variance',
        selector: 'variance',
        sortable: true,
        center: true,
        format: row => <Badge
            text={`${formatNumber(row?.variance)}%`}
            isTrend={true}
            isUpTrend={row?.variance_trend}
        />
    },
    {
        name: '',
        sortable: false,
        right: true,
        cell: row => <div>
            <Icon circular name='delete' onClick={() => handleDelete(row.id)} />
            <Icon circular name='map marker alternate' onClick={() => handleGoToUrbyn(row.propertyId, row.type)} />
            <Icon circular name='arrow right' onClick={() => handleGoToAppraisals(row.propertyId, row.type)} />
        </div>
    }
])

const problemAppraisals = []
let next_id = 0

const MultiCompAppraisals = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [value, setValue] = useState('')
    const [amount, setAmount] = useState(0)
    const [condition, setCondition] = useState(AppraisalCondition.C3)
    const [appraisalDate, setAppraisalDate] = useState(today())
    const [manualAppraisal, setManualAppraisal] = useState(null)

    const [isCompLoading, setIsCompLoading] = useState(false)
    const [queuedAppraisal, setQueuedAppraisal] = useState(null)
    const [queuedTarget, setQueuedTarget] = useState(null)
    const [results, setResults] = useState([])

    const [appraisals, setAppraisals] = useState(null)

    const inputFileRef = useRef()
    const fileReader = new FileReader();

    useEffect(() => {
        if (appraisals) {
            runNextAppraisal()
        }
    }, [appraisals])

    useEffect(() => {
        if (queuedAppraisal) {
            console.log(queuedAppraisal)
            const { address } = queuedAppraisal
            getGoogleAddress(address, async (written, address, parts) => {
                //console.log(address, parts)
                var search = getParcelAddressQueryParams(written, address, parts)

                let result = await getAddress(search)
                if (result) {
                    setQueuedTarget({
                        ...queuedAppraisal,
                        propertyId: result.propertyId
                    })
                } else {
                    problemAppraisals.push(address)
                    runNextAppraisal()
                }
            })
        }
    }, [queuedAppraisal])

    useEffect(() => {
        if (queuedTarget) {
            onRunComps()
        }
    }, [queuedTarget])

    const handleSearchChange = async (written, address, parts) => {
        //console.log(address, parts)

        if (address.length === 0) return

        setIsLoading(true)

        var search = getParcelAddressQueryParams(written, address, parts)

        let result = await getAddress(search)
        setPropertyToRun(result)
        return {success: true, isPluto: Lot.isPluto(result)}
  };

  const getAddress = async (search) => {
    //console.log(search)
    let result = await makeGetRequest(`${parcel_search_url}?${search}`)

    setIsLoading(false)
    
    if (!result) {
        alert('No Address Found')
        return
    }
        
    return result
  }

    const setPropertyToRun = (result) => {
        setManualAppraisal(result)
        setValue(Lot.fullAddress(result))
    }

    const runNextAppraisal = () => {
        if (appraisals && appraisals.length > 0)
            setQueuedAppraisal(appraisals.pop())
        else {
            if (problemAppraisals.length > 0) {
                alert(`Error finding\n${problemAppraisals.join("\n")}`)
            }
        }
    }

    const isRenovated = (condition) => {
        return AppraisalCondition[condition] === AppraisalCondition.C1
    }

    const isFair = (condition) => {
        return AppraisalCondition[condition] === AppraisalCondition.C4
    }

    const isDistress = (condition) => {
        return AppraisalCondition[condition] === AppraisalCondition.C6
    }

    const onRunComps = () => {
        Settings.extendedCompsRadius = 1
        setIsCompLoading(true)
        runComps(queuedTarget,
            {
                type: CompTypeOption.DEED,
                date: queuedTarget.date,
                ds: queuedTarget.type
            }, (compResults, canceled) => {
                setIsCompLoading(false)
                if (compResults) {
                    const { compsData } = compResults || {}
                    const { buckets, comps, stats } = compsData || {}
                    const { valuationData, chartData } = stats || {}
                    const { finalBuckets } = buckets || {}
                    const { lowBucket, middleBucket, highBucket } = finalBuckets || {}

                    let target = comps.filter(_ => _.propertyId.toString() === queuedTarget.propertyId.toString())[0]

                    const { condition, value } = queuedTarget//getPropertyCondition(queuedTarget.condition)

                    //const appraisalBuckets = FinalBuckets.getPrimaryAppraisalBuckets(buckets.finalBuckets)

                    //const { c6, c5, c4, c3, c2, c1 } = appraisalBuckets

                    //const highBucketToUse = isRenovated(condition) ? c1 : c2 // (highBuckets && highBuckets.length > 0) ? highBuckets[0] : null
                    //const midBucketToUse = isFair(condition) ? c4 : c3
                    //const lowBucketToUse = isDistress(condition) ? c6 : c5 //(lowBuckets && lowBuckets.length > 0) ? lowBuckets[0] : null

                    if (isRenovated(condition)) {
                        BucketCalc.setHighestSelected(highBucket)
                    } else {
                        BucketCalc.setLowestSelected(highBucket)
                    }

                    if (isFair(condition)) {
                        BucketCalc.setLowestSelected(middleBucket)
                    } else {
                        BucketCalc.setHighestSelected(middleBucket)
                    }

                    if (isDistress(condition)) {
                        BucketCalc.setLowestSelected(lowBucket)
                    } else {
                        BucketCalc.setHighestSelected(lowBucket)
                    }

                    const bucketNames = [lowBucket, middleBucket, highBucket].map(_ => _?.bucket)

                    const tdsChart = setupLTDSChart(comps, chartData, bucketNames, condition)

                    const bucketForCondition = FinalBuckets.bucketForCondition(finalBuckets, AppraisalCondition[condition])

                    const values = [BucketCalc.selectedPriceToUse(bucketForCondition), Number(value)]

                    const variance = calculateDifference(max(values), min(values))

                    let result = {
                        id: next_id++,
                        propertyId: target.propertyId,
                        type: target.type,
                        address: target.address,
                        city: target.city,
                        state: target.state,
                        zip: target.zip,
                        value: value,
                        condition: condition,// queuedTarget.condition,
                        conditionCode: AppraisalCondition[condition],
                        date: queuedTarget.date,

                        poor: BucketCalc.selectedPriceToUse(lowBucket),
                        poorColor: bucketColor(lowBucket?.bucket),
                        poorLTDS: lowBucket?.likenessAverage,
                        poorChosen: lowBucket?.bucket === bucketForCondition?.bucket,

                        fair: BucketCalc.selectedPriceToUse(middleBucket),
                        fairColor: bucketColor(middleBucket?.bucket),
                        fairLTDS: middleBucket?.likenessAverage,
                        fairChosen: middleBucket?.bucket === bucketForCondition?.bucket,

                        excellent: BucketCalc.selectedPriceToUse(highBucket),
                        excellentColor: bucketColor(highBucket?.bucket),
                        excellentLTDS: highBucket?.likenessAverage,
                        excellentChosen: highBucket?.bucket === bucketForCondition?.bucket,

                        confidence: bucketForCondition?.likenessAverage,
                        confidence_trend: bucketForCondition?.likenessAverage >= 70,  //LTDS_CONFIDENCE_COLORS[Math.round(bucketForCondition?.likenessAverage / 10)],

                        variance,
                        variance_trend: variance < 10 ,

                        totalDistance: valuationData?.totalDistance,
                        expanded: {
                            target: target,
                            comps: comps,
                            tdsChart,
                            buckets: {
                                highCalc: highBucket,
                                middleCalc: middleBucket,
                                lowCalc: lowBucket,
                            }
                        }
                    }

                    setResults(results => (
                        [
                            ...(results || []),
                            result
                        ]
                    ))


                    setQueuedAppraisal(null)
                    setQueuedTarget(null)
                    runNextAppraisal()
                }
            })
    }

    const setupLTDSChart = (comps, stdInfo, buckets, condition) => {
        let data = comps.map(_ => {
            let color = ''
            if (_.bucket && _.bucket.length > 0) {
                if (_.bucket === buckets[condition - 1]) {
                    color = bucketColor(_.bucket)
                } else {
                    color = bucketColor(_.bucket, false)
                }
                if (color.length === 0)
                    color = '#d4d4d4'
            } else {
                color = '#ececec'
            }
            return getTDSChartData(_, color)
        })

        data.sort((a, b) => a.x > b.x ? 1 : -1)

        let movingAverage = getChartMovingAverage(data, 0, 100, 10)

        return { data: data, stdInfo, movingAverage }
    }

    const onHandleChange = (e) => {
        const file = e.target.files[0]
        if (file) {
            fileReader.onload = function (event) {
                const csvOutput = event.target.result;
                let tmp = csvFileToArray(csvOutput).reverse()
                tmp.forEach(_ => {
                    _.address = `${_.address}, ${_.city}, ${_.state}`.trim()
                    _.type = Lot.typeFromAddress(_.city, _.state)
                    delete _.city
                    delete _.state
                })
                tmp = tmp.filter(_ => _.address !== null && _.address.length > 0)
                console.log(tmp)
                setAppraisals(tmp)
            };

            fileReader.readAsText(file);
        }
    }

    const onHandleSubmit = (e) => {
        inputFileRef.current.click()
    }

    const runManualAppraisal = () => {
        if (manualAppraisal) {
            manualAppraisal.value = amount
            manualAppraisal.date = appraisalDate
            manualAppraisal.condition = propertyConditionOptions.find(_ => _.value === condition)?.text
            //manualAppraisal.type = manualAppraisal.type
            setQueuedTarget(manualAppraisal)
        }
    }

    const onHandleReset = () => {
        setManualAppraisal(null)
        setValue('')

        setAmount(0)
        setCondition(AppraisalCondition.C3)
        setAppraisalDate(today())

        inputFileRef.current.value = null
        problemAppraisals.splice(0, problemAppraisals.length)
        setAppraisals(null)
        setResults([])

        setQueuedAppraisal(null)
        setQueuedTarget(null)

        next_id = 0
    }

    const onHandleGoToUrbyn = (propertyId, dataset) => {
        goTo('', propertyId, dataset)
    }

    const onHandleGoToAppraisals = (propertyId, dataset) => {
        goTo('comp-appraisals', propertyId, dataset)
    }

    const onHandleDelete = (id) => {
        setResults(results => ([
            ...results.filter(_ => _.id !== id)
        ]))
    }

    const goTo = (page, param, dataset) => {
        let sub = Lot.isPluto({type: dataset}) ? 'bbl' : 'pid'
        if (page && page.length > 0)
            window.open('/' + page + '/' + sub + '/' + param, '_blank')
        else
        window.open('/' + sub + '/' + param, '_blank')
    }

    return (
        <div className='resp-container'>
            <Segment basic style={{ minHeight: '100vh', background: '#f8f8f8', padding: '4em 2em 2em' }}>
                <DataTable
                    style={{ borderRadius: '1rem' }}
                    noHeader={true}
                    columns={columns(onHandleDelete, onHandleGoToUrbyn, onHandleGoToAppraisals)}
                    data={results}
                    //defaultSortField='address'
                    fixedHeader
                    persistTableHead
                    customStyles={customStyles}
                    expandableRows
                    expandOnRowClicked
                    //expandableRowsHideExpander
                    expandableRowsComponent={<ExpandableAppraisalContainer />}
                    subHeader
                    subHeaderComponent={
                        <Segment size='mini' basic style={{ width: '100%' }}>
                            <Form size='small'>
                                <Form.Group>
                                    <Form.Field className='comp-inline-field' inline>
                                        <label>Subject Property</label>
                                        <SearchLocationInput 
                                            className="fluid" 
                                            name=''
                                            label=''
                                            icon='home'
                                            value={value}
                                            placeholder='Enter property address'
                                            onChange={handleSearchChange} 
                                            isLoading={isLoading} />
                                    </Form.Field>
                                    <Form.Select
                                        options={propertyConditionOptions}
                                        placeholder='CONDITION'
                                        //value={condition}
                                        onChange={(e, { value }) => setCondition(value)}
                                    />
                                    <Form.Field style={{ width: '300px' }}>
                                        <MoneyFormattedInput placeholder='Value' defaultValue={amount} onChange={(e, value) => setAmount(Number(value))} />
                                    </Form.Field>
                                    <Form.Input
                                        type='date'
                                        style={{ height: '100%' }}
                                        value={appraisalDate}
                                        onChange={(e, { value }) => {
                                            setAppraisalDate(value)
                                        }}
                                    />
                                    <div style={{ marginRight: '1em' }}></div>
                                    <Button.Group size='mini'>
                                        <Button type="button" positive onClick={runManualAppraisal}>Add</Button>
                                        <Button type="button" negative onClick={onHandleReset}>Reset</Button>
                                    </Button.Group>
                                    <div style={{ margin: '0 0.5em' }}></div>
                                    <Button.Group style={{ marginLeft: '4em' }} size='mini'>
                                        <Button>Export</Button>
                                        <Button positive onClick={(e) => {
                                            onHandleSubmit(e);
                                        }}><input
                                                style={{ display: 'none' }}
                                                type={"file"}
                                                accept={".csv"}
                                                onChange={onHandleChange}
                                                ref={inputFileRef}
                                            />Import</Button>
                                    </Button.Group>
                                </Form.Group>
                            </Form>
                        </Segment>
                    }
                />
                {isCompLoading ? (
                    <Segment style={{ background: 'transparent' }} basic textAlign='center'>
                        <Loader active={true} inline={false} />
                    </Segment>
                ) : null}
            </Segment>
        </div>
    )
}

const ExpandableAppraisalContainer = ({ data }) => {
    const { expanded, conditionCode, totalDistance } = data || {}
    const { target, comps, buckets } = expanded

    const [condition, setCondition] = useState(AppraisalToPropertyCondition(conditionCode))
    const [markerData, setMarkerData] = useState()
    const [tdsChart, setTDSChart] = useState(expanded.tdsChart)

    const bucketNames = Object.values(buckets).map(_ => _?.bucket).reverse()

    useEffect(() => {
        setMarkerData(comps.filter(_ => _.bucket && _.bucket.length > 0 && _.bucket === bucketNames[condition - 1]))

        const { data } = tdsChart
        if (data) {
            data.forEach(_ => {
                let color = ''
                if (_.bucket && _.bucket.length > 0) {
                    if (_.bucket === bucketNames[condition - 1]) {
                        color = bucketColor(_.bucket)
                    } else {
                        color = bucketColor(_.bucket, false)
                    }
                    if (color.length === 0)
                        color = '#d4d4d4'
                } else {
                    color = '#ececec'
                }
                _.color = color
            })
            setTDSChart(tdsChart => ({
                ...tdsChart,
                ...data
            }))
        }
    }, [condition])

    return <Segment basic>
        <ValuationContainer valuationData={expanded.buckets} condition={condition}
            headers={['Poor', 'Fair', 'Excellent']}
            onLowClick={() => setCondition(PropertyCondition.Poor)}
            onMiddleClick={() => setCondition(PropertyCondition.Fair)}
            onHighClick={() => setCondition(PropertyCondition.Excellent)}
        />
        <Grid columns='equal' stretched>
            <Grid.Row>
                <Grid.Column>
                    <Segment>
                        {tdsChart && <LTDSChart
                            chartData={tdsChart}
                            cHeight={400}
                            cWidth={400}
                            scatterSize={4}
                        />}
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Segment>
                        <MapboxMap
                            className='appraisals-map-container no-padding-v'
                            style={{ height: '100%' }}
                            target={target}
                            markerData={markerData}
                            totalDistance={totalDistance}
                        />
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Segment>
                        {target && <LotInformationPanel className="fastclose-lot-details" lot={target} />}
                    </Segment>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <GoogleMap
                        //compsPanelVisible={compsVisible}
                        latitude={target?.latitude}
                        longitude={target?.longitude}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Segment>
}

export default MultiCompAppraisals