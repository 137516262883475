import React, { Component } from 'react';
import Datatable from 'react-data-table-component'
import { Segment, Input, Button, Transition, Select, Icon } from 'semantic-ui-react'
import { makeGetRequest } from '../utility/Services';
import { IntelAPIPathURL, BaseURL } from '../utility/Config'
import Spinner from './Loader'
import { formatNumber } from '../utility/Helper';
import moment from 'moment'
import _ from 'lodash'
import { floor } from '../utility/MathHelper';

const detailsUrl = 'https://a836-acris.nyc.gov/DS/DocumentSearch/DocumentDetail?doc_id='
const documentUrl = 'https://a836-acris.nyc.gov/DS/DocumentSearch/DocumentImageView?doc_id='


const options = [
  {key: 1, value: 1, text: 'BORROWER'},
  {key: 2, value: 2, text: 'BORROWER CONTAINS'},
  {key: 3, value: 3, text: 'LENDER'},
  {key: 4, value: 4, text: 'LENDER CONTAINS'}
]

// function convertArrayOfObjectsToCSV(data) {
//     let result;
  
//     const columnDelimiter = ',';
//       const lineDelimiter = '\n';
//       let keys = Object.keys(data[0]);

//       let i = keys.indexOf('description')
//       keys.splice(i, 1)

//       result = '';
//       result += keys.join(columnDelimiter);
//       result += lineDelimiter;
    
//       data.forEach(item => {
//         let ctr = 0;
//         keys.forEach(key => {
//           if (ctr > 0) result += columnDelimiter;
    
//           let val = item[key] ?? ''
  
//           result += `"${val}"`;
          
//           ctr++;
//         });
//         result += lineDelimiter;
//       });
  
//     return result;
//   }
  
//   // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
//   function downloadCSV(data) {
//   if (data === null || data.length === 0) {
//     return
//   }
  
//     const link = document.createElement('a');
//     let csv = convertArrayOfObjectsToCSV([...data]);
//     if (csv === null) return;
  
//     const filename = 'data.csv';
  
//     if (!csv.match(/^data:text\/csv/i)) {
//       csv = `data:text/csv;charset=utf-8,${csv}`;
//     }
  
//     link.setAttribute('href', encodeURI(csv));
//     link.setAttribute('download', filename);
//     link.click();
//   }


const customStyles = {
    subHeader: {
        style: {
            padding: '0'
        }
    },
    headRow: {
      style: {
          height: '48px',
          backgroundColor: 'lightblue'
      }
    },
    headCells: {
      style: {
        color: '#202124',
        fontSize: '11px',
        fontWeight: 'bolder',
        textTransform: 'uppercase'
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: 'rgb(230, 244, 244)',
        borderBottomColor: '#FFFFFF',
        outline: '1px solid #FFFFFF',
      },
      style: {
          fontSize: '11px',
          border: '1px solid grey'
      }
    },
    pagination: {
      style: {
        border: 'none',
      },
    },
  };

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <Input label={<Button style={{minWidth: '48px'}} type="button" onClick={onClear} icon='close'></Button>} 
        labelPosition='right'
        size='small'
        type="text" placeholder="Search..."  value={filterText} onChange={onFilter} />
)

// export const ExportButton = ({data}) => (
//     <Button floated='right' type='button' content='Export' onClick={(e) => {downloadCSV(data)}}/>
// )

export class Intel extends Component {
    
        state = {
            data : [],
            loading: false,
            searchValue: '',
            optionValue: 1,
            filterText: '',
            filteredItems: [],
        }
        columns = [
            {
                name: 'Lender',
                selector: 'lender',
                sortable: true,
                //width: '180px'
                grow: 2,
                //cell: row => row.lenders[0]?.name
                cell: row => <span onClick={this.handleLenderClick.bind(this, row.lender)}>{row.lender}</span>
            },
            {
                name: 'Borrower',
                selector: 'borrower',
                sortable: true,
                //width: '200px'
                grow: 2,
                cell: row => <span onClick={this.handleBorrowerClick.bind(this, row.borrower)}>{row.borrower}</span>
                //cell: row => row.borrowers[0]?.name
            },
            {
              name: 'Borrower Address',
              selector: 'borrowerAddress',
              sortable: true,
              //width: '320px',
              grow: 3
              //cell: row => row.parcels[0].displayAddress
            },
            {
              name: 'Property Address',
              selector: 'propertyAddress',
              sortable: true,
              //width: '320px',
              grow: 3
              //cell: row => row.parcels[0].displayAddress
            },
            {
                name: 'Type',
                selector: 'propertyType',
                sortable: true,
                //cell: row => row.parcels[0].type
                width: '72px'
            },
            {
                name: 'Mortgage',
                selector: 'documentAmount',
                sortable: true,
                width: '110px',
                format: row => row.documentAmount === 0 ? '-' : `$${formatNumber(row.documentAmount)}`
            },
            {
                name: 'Date',
                selector: 'date',
                sortable: true,
                width: '120px',
                //cell: row => (row.date ? moment.utc(row.date).format('L') : '-')
                format: row => row.date ? moment.utc(row.date).format('L') : '-'
            },
            {
                name: 'Days',
                selector: 'days',
                sortable: true,
                cell: row => floor(row.days),
                width: '72px'
            },
            {
                name: 'Data',
                selector: 'data',
                sortable: false,
                width: '75px',
                center: true,
                cell: row => (
                  <>
                  <a href={`${detailsUrl}${row.documentId}`} target='_blank' rel="noopener noreferrer"><Icon name='file alternate outline'/></a>
                  <a href={`${documentUrl}${row.documentId}`} target='_blank' rel="noopener noreferrer"><Icon name='file outline'/></a>
                  </>
                )
            }
          ]
      

      componentDidMount() {
        window.addEventListener("message", (e) => {
          if (e?.origin === BaseURL) {
            if (e.data.isSet === true) {
              const {searchValue, optionValue} = e.data
              this.setState({
                optionValue,
                searchValue
              })
            }
          }
        })
      }

      handleLenderClick = (lender) => {
        this.handleNewTab(3, lender)
      }

      handleBorrowerClick = (borrower) => {
        this.handleNewTab(1, borrower)
      }

      handleNewTab = (option, lookup) => {
        const win = window.open("/intel", "_blank");
        setTimeout(() => {
          win.postMessage({ isSet: true, optionValue: option, searchValue: lookup }, BaseURL)
        }, 750)
      }

    handleClear = () => {
        this.setFilter('')
      }

    handleFilter = (e) => {
        const val = e.target.value
        if (val === '') {
          this.handleClear()
          return
        }
        this.setFilter(val)
      }

    setFilter = (val) => {
        const {data} = this.state

        let tmpData = [...data]

        let filteredItems
        if (val !== '') {
          filteredItems = tmpData.filter(item => (
            item.propertyAddress?.toLowerCase().includes(val.toLowerCase()) ||
            item.borrowerAddress?.toLowerCase().includes(val.toLowerCase()) ||
            item.borrower?.toLowerCase().includes(val.toLowerCase()) ||
            item.lender?.toLowerCase().includes(val.toLowerCase()) ||
            item.propertyType?.toLowerCase().includes(val.toLowerCase()) ||
            item.date?.toLowerCase().includes(val.toLowerCase())
            ))
        } else {
          filteredItems = tmpData
        }

        this.setState({filterText: val, filteredItems})
      }

    customSort = (rows, field, direction) => {
        const handleField = row => {
            if (row[field]) {
                if (typeof row[field] === 'string') {
                    return row[field].toLowerCase();
                }
            }
    
        return row[field];
      };
    
      return _.orderBy(rows, handleField, direction)
    }

    handleQuery = async () => {
      const {searchValue, optionValue} = this.state

      if (searchValue.trim().length === 0) {
        return
      }

      this.setState({loading: true})
      const data = await makeGetRequest(`${IntelAPIPathURL}?look=${searchValue.trim()}&option=${optionValue}`)
      if (data === null) {
        this.setState({loading: false})
      } else {
        this.setState({loading: false, data: data.intelData, filteredItems: data.intelData})
      }
    }

    render () {
        const {filteredItems, filterText, loading, optionValue, searchValue} = this.state
        
        return (
          <div style={{padding: '1em 15em'}}>          
              <Transition
              animation='fly right'
              reactKey='intel-table'
              transitionOnMount={true}
              unmountOnHide={true}
              visible={true}
              >
              <Segment basic>
                <Segment basic size='mini'>
                  <Select style={{width: '200px'}} options={options} value={optionValue} onChange={(e, {value}) => {this.setState({optionValue: value})}} />
                  <Input className='intel-search-input' style={{marginLeft: '2em', width: '25%'}} defaultValue={searchValue} onChange={(e, {value}) => {this.setState({searchValue: value})}} />
                  <Button style={{marginLeft: '1em'}} icon='play' onClick={this.handleQuery}></Button>
                </Segment>
              <Datatable
                title='Property Intel'
                className='intel-table'
                noHeader={true}
                persistTableHead
                columns={this.columns}
                data={filteredItems}
                highlightOnHover
                pointerOnHover
                customStyles={customStyles}
                defaultSortField='days'
                defaultSortAsc={true}
                fixedHeader
                fixedHeaderScrollHeight='65vh'
                dense
                striped
                responsive
                progressPending={loading}
                progressComponent={<Spinner active={loading} inline={true} />}
                //conditionalRowStyles={this.conditionalRowStyles()}
                //sortFunction={this.customSort}
                subHeader
                subHeaderAlign='left'
                subHeaderComponent={(
                    <Segment basic style={{width: '100%'}}>
                    <FilterComponent onFilter={this.handleFilter} onClear={this.handleClear} filterText={filterText} />
                    </Segment>
                )}
                pagination
                paginationPerPage={100}
                paginationRowsPerPageOptions={[100]}
              />
              </Segment>
              </Transition> 
          </div>
      );
    }
  }