import React from 'react';
import { Grid, Header, Segment, Divider, Image} from 'semantic-ui-react';
import Lot from "../Tax-Lot.js";
export const CompsPopup = ({ className = '', selectedCompDetails }) => (
    //if (this.props.selectedCompDetails != null && this.props.selectedCompImage != null && this.props.selectedComp != null) {

          <Segment className={`comps-popup-view-container ${className}`}>
              <Header as='h4'>
                    <span><b>{Lot.fullAddress(selectedCompDetails)}</b></span><br />
                    <span><h5>{selectedCompDetails.propertyId}</h5></span>
                    <span><h5>{selectedCompDetails.neighborhood}</h5></span>
                </Header>
                <Divider />
                <Grid column={2}>
                <Grid.Column width={6}>
                    <Image src={`https://maps.googleapis.com/maps/api/streetview?size=600x450&location=${selectedCompDetails.latitude},${selectedCompDetails.longitude}&fov=70&pitch=0&sensor=false&scale=2&key=${process.env.REACT_APP_GOOGLE_API_KEY}`} height="180" width="180"></Image>
                </Grid.Column>
                <Grid.Column width={10}>
                    <Grid className="compGrid" columns={4}>
                        <Grid.Row className="compRow">
                            <Grid.Column width={4}>
                                <span>Stories:</span>
                            </Grid.Column>
                            <Grid.Column textAlign='right' width={4}>
                                <span><b>{selectedCompDetails.stories}</b></span>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <span>Zoning:</span>
                            </Grid.Column>
                            <Grid.Column textAlign='right' width={4}>
                                <span><b>{selectedCompDetails.zoning}</b></span>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className="compRow">
                        <Grid.Column width={4}>
                                <span>Year Built:</span>
                            </Grid.Column>
                            <Grid.Column textAlign='right' width={4}>
                                <span><b>{selectedCompDetails.yearBuilt}</b></span>
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <span>Units:</span>
                            </Grid.Column>
                            <Grid.Column textAlign='right' width={2}>
                                <span><b>{selectedCompDetails.totalunits}</b></span>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className="compRow">
                            <Grid.Column width={2}>
                                <span>Building:</span>
                            </Grid.Column>
                            <Grid.Column textAlign='right' width={6}>
                                <span><b>{selectedCompDetails.buildingdimensions}</b></span>
                            </Grid.Column>
                            <Grid.Column width={5}>
                                <span>Square Feet:</span>
                            </Grid.Column>
                            <Grid.Column textAlign='right' width={3}>
                                <span><b>{selectedCompDetails.buildingsqft}</b></span>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className="compRow">
                            <Grid.Column width={2}>
                                <span>Lot:</span>
                            </Grid.Column>
                            <Grid.Column textAlign='right' width={6}>
                                <span><b>{selectedCompDetails.lotdimensions}</b></span>
                            </Grid.Column>
                            <Grid.Column width={5}>
                                <span>Square Feet:</span>
                            </Grid.Column>
                            <Grid.Column textAlign='right' width={3}>
                                <span><b>{selectedCompDetails.lotsqft}</b></span>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className="compRow">
                            <Grid.Column width={4}>
                                <span>Sale Price:</span>
                            </Grid.Column>
                            <Grid.Column textAlign='right' width={4}>
                                <span><b>{selectedCompDetails.saleprice}</b></span>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <span>Sale Date:</span>
                            </Grid.Column>
                            <Grid.Column textAlign='right' width={4}>
                                <span><b>{selectedCompDetails.lastsaledate}</b></span>
                            </Grid.Column>
                        </Grid.Row>
                        
                        <Divider />
                        <Grid.Row className="compRow">
                            <Grid.Column width={4}>
                                <span>Seller(s):</span>
                            </Grid.Column>
                            <Grid.Column className='nowrap' width={12}>
                                <span>{selectedCompDetails.sellers}</span>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className="compRow">
                            <Grid.Column width={4}>
                                <span>Buyer(s):</span>
                            </Grid.Column>
                            <Grid.Column className='nowrap' width={12}>
                                <span>{selectedCompDetails.buyers}</span>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
                </Grid>
          </Segment>
      )
    // else return null
//  }
export default CompsPopup;