import React, { useEffect, useState } from 'react'

import { makeGetRequest } from '../../utility/Services';
import { property_api_run_history_appraisals_url } from '../../utility/Config';
import { bucketColor } from '../Comps';
import { Button, Grid, Segment, Form, Search } from 'semantic-ui-react';
import LTDSChart, { getTDSChartData } from '../charts/LTDSChart';
import MapboxMap from '../maps/MapboxMap';
import GoogleMap from '../maps/GoogleMap';
import DataTable from 'react-data-table-component';
import { customStyles } from '../../utility/TableHelper';
import { getChartMovingAverage } from '../charts/ChartHelper';
import memoize from 'memoize-one';
import moment from 'moment';
import Spinner from '../Loader';
import { ValuationContainer } from '../extras/ValueContainer';
import { LotInformationPanel } from '../lots/LotInformationPanel';

const resultRenderer = ({ label }) => <label style={{ fontSize: '12px' }}>{label.toUpperCase()}</label>

const columns = memoize(() => [
    {
        name: 'Date',
        selector: 'timestamp',
        sortable: true,
        format: row => moment.unix(row.timestamp).format("LL h:mma")
    },
])

const HistoryAppraisals = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [searchResults, setSearchResults] = useState([])
    const [value, setValue] = useState('')

    const [selectedBBL, setSelectedBBL] = useState(null)
    const [results, setResults] = useState([])

    const onSearchHistory = async () => {
        const valHistory = await makeGetRequest(property_api_run_history_appraisals_url + "?bbl=" + selectedBBL)
        if (valHistory) {
            valHistory.forEach((_, index) => {
                _.index = index
            })
            setResults(valHistory)
        }
    }

    const handleResultSelect = (e, { result }) => {
        setValue(result?.label)
        setSelectedBBL(result?.propertyId)
    }

    const onHandleReset = () => {
        setValue('')
        setResults([])
        setSelectedBBL(null)
    }

    return (
        <div className='resp-container'>
            <Segment basic style={{ minHeight: '100vh', background: '#f8f8f8', padding: '4em 2em 2em' }}>
                <DataTable
                    noHeader={true}
                    columns={columns()}
                    data={results}
                    //defaultSortField='address'
                    fixedHeader
                    persistTableHead
                    customStyles={customStyles}
                    expandableRows
                    //expandableRowExpanded={(row) => console.log("Row:", row)}
                    expandOnRowClicked
                    //onRowClicked={(row) => setCurrentRow(row)}
                    //onRowExpandToggled={(bool, row) => bool ? setCurrentRow(row) : setCurrentRow(null)}
                    expandableRowsHideExpander
                    expandableRowsComponent={<ExpandableAppraisalContainer />}
                    subHeader
                    subHeaderComponent={
                        <Segment size='mini' basic style={{ width: '100%' }}>
                            <Form size='small'>
                                <Form.Group>
                                    <Form.Field className='comp-inline-field' inline>
                                        <label>Subject Property</label>
                                        <Search
                                            fluid
                                            icon='home'
                                            placeholder='Enter property address'
                                            loading={isLoading}
                                            onResultSelect={handleResultSelect}
                                            onSearchChange={(e, { value }) => setValue(value)}
                                            results={searchResults}
                                            value={value}
                                            resultRenderer={resultRenderer}
                                        />
                                    </Form.Field>
                                    <div style={{ marginRight: '1em' }}></div>
                                    <Button.Group size='mini'>
                                        <Button positive onClick={onSearchHistory}>Search</Button>
                                        <Button negative onClick={onHandleReset}>Reset</Button>
                                    </Button.Group>
                                </Form.Group>
                            </Form>
                        </Segment>
                    }
                />
            </Segment>
        </div>
    )
}

const ExpandableAppraisalContainer = ({ data }) => {
    const { resultHash } = data || {}
    const [valuation, setValuation] = useState(null)
    const [target, setTarget] = useState(null)

    const [markerData, setMarkerData] = useState()
    const [tdsChart, setTDSChart] = useState(null)

    const setupLTDSChart = (comps, stdInfo) => {
        let data = comps.map(_ => {
            let color = ''
            if (_.bucket && _.bucket.length > 0) {
                color = bucketColor(_.bucket)
                if (color.length === 0)
                    color = '#d4d4d4'
            } else {
                color = '#ececec'
            }
            return getTDSChartData(_, color)
        })

        data.sort((a, b) => a.x > b.x ? 1 : -1)

        let movingAverage = getChartMovingAverage(data, 0, 100, 10)

        //console.log(movingAverage)
        return { data: data, stdInfo, movingAverage }
    }

    useEffect(async () => {
        if (!valuation) {
            let results = await fetch("https://ipfs.io/ipfs/" + resultHash).then(res => res.text())
            results = JSON.parse(atob(results))

            const { calc } = results
            if (calc) {
                const { finalBuckets } = calc || {}
                const { lowBucket, middleBucket, highBucket } = finalBuckets || {}

                setValuation({
                    ...results,
                    buckets: {
                        lowCalc: lowBucket,
                        middleCalc: middleBucket,
                        highCalc: highBucket
                    }
                })

                const { comps, stdInfo } = results
                if (comps && comps.length > 0) {
                    setTarget(comps[0])

                    setMarkerData(comps.filter(_ => _.bucket && _.bucket.length > 0))
                    setTDSChart(setupLTDSChart(comps, stdInfo))
                }
            }
        }
    }, [])

    return <Segment basic>
        {valuation ? (
            <>
                <ValuationContainer valuationData={valuation.buckets} />
                <Grid columns='equal' stretched>
                    <Grid.Row>
                        <Grid.Column>
                            <Segment>
                                {tdsChart && <LTDSChart
                                    chartData={tdsChart}
                                    cHeight={400}
                                    cWidth={400}
                                    scatterSize={4}
                                />}
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment>
                                <MapboxMap
                                    className='appraisals-map-container no-padding-v'
                                    style={{ height: '100%' }}
                                    target={target}
                                    markerData={markerData}
                                    totalDistance={valuation?.calc?.totalDistance}
                                />
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment>
                                {target && <LotInformationPanel className="fastclose-lot-details" lot={target} />}
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            {target && <GoogleMap
                                //compsPanelVisible={compsVisible}
                                latitude={target.latitude}
                                longitude={target.longitude}
                            />}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </>
        ) : <Spinner />}
    </Segment>
}


export default HistoryAppraisals