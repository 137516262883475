import moment from 'moment';
import _ from 'lodash'

import { NotAvailable } from './Global'

const MILLION = 1000000
const HUNDRED_THOUSAND = 100000
const THOUSAND = 1000

export function property(key) { return function (x) { return x[key]; } }
export function flatten(a, b) { return a.concat(b); }

export function deepClone(obj) {
  return _.cloneDeep(obj)
}

export const addDataIntoCache = (cacheName, value) => {
  // Converting our response into Actual Response form
  const data = new Response(JSON.stringify(value));

  if ('caches' in window) {
    // Opening given cache and putting our data into it
    caches.open(cacheName).then((cache) => {
      cache.put('/', data);
    });
  }
};

export const getDataFromCache = (cacheName, defaultValue, callback) => {
  if ('caches' in window) {
    // Opening given cache and putting our data into it
    caches.open(cacheName).then(response => {
      response.match('/').then(result => {
        if (!result) callback(defaultValue)
        result && result.json().then(value => {
          callback && callback(value)
        })
      })
    })
  }
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const wordInString = (s, word) => new RegExp('\\b' + word + '\\b', 'i').test(s);

export function colorFromNumber(value) {
  let color = Math.floor(Math.random() * 16777215).toString(16)
  //let color = Math.floor(value*1677721).toString(16)
  return "#" + color
}

export function lightenDarkenColor(col, amt) {
  if (!col || col.length === 0) return col
  var usePound = false;

  if (col[0] === "#") {
      col = col.slice(1);
      usePound = true;
  }

  var num = parseInt(col,16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if  (r < 0) r = 0;

  var b = ((num >> 8) & 0x00FF) + amt;

  if (b > 255) b = 255;
  else if  (b < 0) b = 0;

  var g = (num & 0x0000FF) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);

}

export function cloneArray(array) {
  return JSON.parse(JSON.stringify(array))
}

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export function uniqueBy(array, propertyName) {
  return array.filter((e, i) => array.findIndex(a => a[propertyName] === e[propertyName]) === i); // [...new Map(objArray.map((item) => [item["id"], item])).values()]
}

export function chunkMaxLength(arr, chunkSize, maxLength) {
  return Array.from({length: maxLength}, () => arr.splice(0,chunkSize));
}

export function arrayContainsArray(arr1, arr2) {
  return arr1.some(r=> arr2.indexOf(r) >= 0)
}

export function arrayEquals(a, b) {
  return Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index]);
}

export function arrayEqualsByProperty(a, b, property) {
  a = a.map(_ => _[property]).sort()
  b = b.map(_ => _[property]).sort()
  return Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index]);
}

export function isEmpty(obj) {
  if (!obj) return true;
  for (var key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

export function getSearchAddressParts(written, address, parts) {
  /*console.log(written);
  console.log(address);
  console.log(parts);*/
  var county = parts.find(_ => _.types.includes("administrative_area_level_2"))?.long_name ?? ''
  var city = parts.find(_ => _.types.includes("locality"))?.long_name ?? ''
  if (!city || city.length === 0) city = parts.find(_ => _.types.includes("sublocality_level_1"))?.long_name ?? ''
  var state = parts.find(_ => _.types.includes("administrative_area_level_1"))?.short_name ?? ''

  const zipCodeRegex = /\b\d{5}(?:-\d{4})?\b/;
  const zipCodeMatchA = (written.includes(',') ? written.split(',', 2)[1] : written).match(zipCodeRegex);
  const zipCodeMatchB = (address.includes(',') ? address.split(',', 2)[1] : address).match(zipCodeRegex);

  var zip = zipCodeMatchA ? zipCodeMatchA[0] : zipCodeMatchB ? zipCodeMatchB[0] : parts.find(_ => _.types.includes("postal_code"))?.short_name ?? ''

  let searchAddress = written.split(",")[0].replace("#", "")
  return {
    county,
    city,
    state,
    zip,
    address: searchAddress
  }
}

export function getParcelAddressQueryParams(search, add, parts) {
  let params = getSearchAddressParts(search, add, parts)
  const {county, city, state, zip, address} = params

  return `q=${address}&co=${county}&ci=${city}&s=${state}&z=${zip}`
}

export function getRadius(name) {
  switch (name) {
    case 'quarter':
      return 0.25
    case 'half':
      return 0.5
    case 'three-quarter':
      return 0.75
    case 'mile':
      return 1
    default:
      return 0
  }
}

export function getRadiusText(value) {
  switch (value) {
    case 0.25:
      return 'quarter'
    case 0.5:
      return 'half'
    case 0.75:
      return 'three-quarter'
    case 1:
      return 'mile'
    default:
      return ''
  }
}

export function getSaleDateText(date) {
  let dateText = moment(date).format('YYYY-MM-DD')
  if (dateText === moment().subtract(6, 'months').format('YYYY-MM-DD')) {
    return '6'
  }
  if (dateText === moment().subtract(12, 'months').format('YYYY-MM-DD')) {
    return '12'
  }
  return ''
}

export function getDistance(xA, yA, xB, yB) {
  var xDiff = xA - xB;
  var yDiff = yA - yB;

  return Math.sqrt(xDiff * xDiff + yDiff * yDiff);
}

export function calculateRateChange(a, b) {
  if (a === b) return 0
  if (b === 0) return 100
  return (a - b) / b * 100
}

export function calculatePercentDifference(a, b, abs = true) {
  if (!abs) return 100 * ((a - b) / ((a + b) / 2))
  
  return 100 * Math.abs((a - b) / ((a + b) / 2))
}

export function calculateDifference(low, high, abs = true) {
  if (!abs) return ((high - low) / high) * 100
  //return 100 * abs( (a - b) / ( (a+b)/2 ) )
  return Math.abs((high - low) / high) * 100
}

export function calculatePercentageAppreciation(low, high) {
  if (high === 0) return 0
  return Math.abs((high - low) / low) * 100
}

export function roundToNearest(value, nearest = 1) {
  return Math.round(value / nearest) * nearest
}

export function roundToFloorByNearest(value, nearest = 1) {
  return Math.floor(value / nearest) * nearest
}

export function roundToCeilByNearest(value, nearest = 1) {
  return Math.ceil(value / nearest) * nearest
}

export function getMedian(values) {
  if (values.length > 0) {
    values.sort((a, b) => a - b)

    let half = Math.floor(values.length / 2)
    let median
    if (values.length % 2) {
      median = values[half]
    }
    else {
      median = (values[half - 1] + values[half]) / 2
    }
    return median
  }
  return 0
}

export function formatShortNumber(amount) {
  if (amount < 1000) return amount
  let rounded = (roundToNearest(amount, 1000))
  if (rounded < HUNDRED_THOUSAND) return `${roundToNearest(amount, 10) / 1000}K`
  let isMillion = rounded >= MILLION
  if (isMillion) {
    let formatted = formatNumber(amount / MILLION)
    //let split = formatted.split('.')
    //if (split[split.length-1] === '00')
    //return `${split[0]}M`
    return `${formatted}M`
  } else {
    return `${roundToNearest(amount / THOUSAND, 1)}K`
  }
}

export function formatNumber(amount, minDigit = 0) {
  if (!amount) return 0
  // Create our number formatter.
  var formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2 > minDigit ? 2 : minDigit,
    minimumFractionDigits: (amount % 1) > 0.0 ? 2 : minDigit
  });

  return formatter.format(amount);
};

export function formatDate(date) {
  if (!date) return ''
  const result = moment.utc(date).format('l')
  if (result === 'Invalid date') {
    return NotAvailable
  }
  return result
}

export function formateInputDate(date) {
  if (!date) return ''
  const result = moment.utc(date).format('YYYY-MM-DD')
  if (result === 'Invalid date') {
    return NotAvailable
  }
  return result
}

export function formatDateWithTime(date) {
  const result = moment.utc(date).format('l LT')
  if (result === 'Invalid date') {
    return NotAvailable
  }
  return result
}

export function yearDifference(d1, d2 = new Date()) {
   var ageDifMs = d2 - d1;
   var ageDate = new Date(ageDifMs); // miliseconds from epoch
   return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function monthDifference(d1, d2 = new Date()) {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

export function dayDifference(d1, d2 = new Date()) {
  // To calculate the time difference of two dates
  var Difference_In_Time = d2.getTime() - d1.getTime();
  // To calculate the no. of days between two dates
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  return Difference_In_Days
}

export function calculateZScore(value, mean, std) {
  if (std === 0) return 0
  return roundToNearest((value - mean) / std, .01)
}

export function calculateZValue(zScore, mean, std) {
  return (zScore * std) + mean
}

export function calculatePayment(loanAmount, rate, term = 360) {
  return loanAmount * (((rate / 12) * (Math.pow((1 + (rate / 12)), term)))) / ((Math.pow((1 + (rate / 12)), term)) - 1)
}

export function calculatePmt2Loan(payment, rate, term = 360) {
  return ((payment / (rate / 12)) * (1 - (1 / (Math.pow((1 + (rate / 12)), term)))))
}

export function arrayRemove(arr, value) { return arr.filter(function (ele) { return ele !== value; }); }

export const csvFileToArray = string => {
  const csvHeader = string.slice(0, string.indexOf("\n")).toLowerCase().trim().split(",");
  const csvRows = string.slice(string.indexOf("\n") + 1).split("\n").filter(_ => _.length > 0);

  const array = csvRows.map(i => {
    let hasQuotes = i.match('"') !== null
    i = i.replace(/\r?\n|\r/g, '')
    const values = hasQuotes ? i.match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g) : i.split(",");
    const obj = csvHeader.reduce((object, header, index) => {
      header = header.trim()
      object[header] = values[index].trim();
      return object;
    }, {});
    return obj;
  });

  return array
};

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
function convertArrayOfObjectsToCSV(data, columns = null) {
  let result;

  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  const keys = columns ? Object.keys(columns) : Object.keys(data[0]);

  result = '';
  result += columns ? Object.values(columns).join(columnDelimiter) : keys.join(columnDelimiter);
  result += lineDelimiter;

  data.forEach(item => {
    let ctr = 0;
    keys.forEach(key => {
      if (ctr > 0) result += columnDelimiter;

      if (item[key] instanceof Array) {
        var text = ''
        item[key].forEach(t => {
          text += `${t},`
        })
        result += `"${text}"`
      } else {
        result += `"${item[key]}"`;
      }

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
export function downloadCSV(data, name, columns, sort = null) {
  if (data === null || data.length === 0) {
    return
  }
  if (sort && sort.length > 0) {
    data.sort((a, b) =>
      a?.[sort] < b?.[sort] ? 1 : -1
    );
  }
  const link = document.createElement('a');
  let csv = convertArrayOfObjectsToCSV(data, columns);
  if (csv == null) return;

  const filename = name + '.csv';

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute('href', encodeURI(csv));
  link.setAttribute('download', filename);
  link.click();
}
