import React, { Component } from 'react';
import { Icon, Input, Popup, Dropdown } from 'semantic-ui-react';
import { formatNumber } from '../../utility/Helper';

export class EditableText extends Component {
    state = {
        isEditing : false,
        editedText: this.props.value
    }
    onSaveHandler = this.props.onSaveHandler

    onEdit = () => {
        const {isEditing} = this.state
        this.setState({isEditing: !isEditing, editedText: this.props.value})
    }

    onSave = () => {
        const {isEditing, editedText} = this.state
        this.setState({isEditing: !isEditing})

        this.onSaveHandler(editedText)
    }

    onHandleEdit = (e, {value}) => {
        this.setState({editedText: value})
    }

    render() {
        const {isEditing, editedText} = this.state
        const {value, originalValue} = this.props
        const isDifferent = originalValue != value
        return (
            <>
            {isEditing ? (
                <Input className='editable-text-input' size='mini' defaultValue={value} value={editedText} onChange={this.onHandleEdit} />
            ) : isDifferent ? (<Popup position='right center' size='small' content={`${formatNumber(originalValue)} Default`} trigger={<span style={{cursor: 'help'}}>{formatNumber(value)}</span> } />) : <span>{formatNumber(value)}</span> }
            {isEditing ? (
                <>
                <Icon className='editable-text-input-icon' size='small' onClick={this.onSave} name='save' />
                <Icon className='editable-text-input-icon' size='small' onClick={this.onEdit} name='unlock' />
                </>
            ) : <Icon className={`editable-text-input-icon ${isDifferent ? 'blue' : 'lightgray'}`} size='small' onClick={this.onEdit} name='lock' />}
            </>
        )
    }
}

export class EditableDropdown extends Component {
    state = {
        isEditing : false,
        editedValue: this.props.value
    }
    onSaveHandler = this.props.onSaveHandler

    onEdit = () => {
        const {isEditing} = this.state
        this.setState({isEditing: !isEditing, editedValue: this.props.value})
    }

    onSave = () => {
        const {isEditing, editedValue} = this.state
        this.setState({isEditing: !isEditing})

        this.onSaveHandler(editedValue)
    }

    onHandleEdit = (e, {value}) => {
        this.setState({editedValue: value})
    }

    render() {
        const {isEditing, editedValue} = this.state
        const {value, originalValue, options, valueLookup} = this.props
        const isDifferent = originalValue != value
        const oLookedUpValue = valueLookup ? valueLookup(originalValue) : originalValue
        const lookedUpValue = valueLookup ? valueLookup(value) : value
        return (
            <>
            {isEditing ? (
                <span className='proxcode-dropdown'>
                <Dropdown
                className='editable-dropdown-input mini'
                inline
                placeholder='?'
                selection
                options={options}
                value={editedValue}
                onChange={this.onHandleEdit}
                />
               </span>
                // <Input className='editable-text-input' size='mini' defaultValue={value} value={editedText} onChange={this.onHandleEdit} />
            ) : isDifferent ? (<Popup position='right center' size='small' content={`${oLookedUpValue} Default`} trigger={<span style={{cursor: 'help'}}>{lookedUpValue}</span> } />) : <span>{lookedUpValue}</span> }
            {isEditing ? (
                <>
                <Icon className='editable-text-input-icon' size='small' onClick={this.onSave} name='save' />
                <Icon className='editable-text-input-icon' size='small' onClick={this.onEdit} name='unlock' />
                </>
            ) : <Icon className={`editable-text-input-icon ${isDifferent ? 'blue' : 'lightgray'}`} size='small' onClick={this.onEdit} name='lock' />}
            </>
        )
    }
}