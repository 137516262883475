import React, { useEffect, useState } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom';

import Layout from './components/Layout'
import { Home, HomeWrapper } from './components/Home'
import { Report } from './components/Report'
import { getUser, setUserManager, userAuthorized } from './utility/Auth'
import { Intel } from './components/Intel'
import { Connections } from './components/Connections'
import { Investors } from './components/Investors'
import { Neighborhoods } from './components/Neighborhoods'
import Valuation from './components/pages/Valuation'
import CompAppraisals from './components/pages/CompAppraisals'
import FastCloseLoan from './components/pages/FastCloseLoan'
import MultiCompAppraisals from './components/pages/MultiCompAppraisals';
import HistoryAppraisals from './components/pages/HistoryAppraisals';
import { useAuth } from 'oidc-react';
import Photos from './components/pages/Photos';

const routes = [{
  path: '/',
  component: <Home />,
}, {
  path: '/lot/:boro/:block/:lot',
  component: <HomeWrapper />,
},
{
  path: '/bbl/:bbl',
  component: <HomeWrapper />,
},
{
  path: '/pid/:pid',
  component: <HomeWrapper />,
},
{
  path: '/rid/:rid',
  component: <HomeWrapper />,
},
{
  path: '/portfolio',
  component: <Report />,
},
{
  path: '/neighborhoods',
  component: <Neighborhoods />
},
// {
//   path: '/intel',
//   component: Intel
// },
// {
//   path: '/connections',
//   component: Connections
// },
{
  path: '/investors',
  component: <Investors />
},
{
  path: '/valuation',
  component: <Valuation />
},
{
  path: '/multi-appraisals',
  component: <MultiCompAppraisals />
},
{
  path: '/history-appraisals',
  component: <HistoryAppraisals />
},
{
  path: '/comp-appraisals',
  component: <CompAppraisals />
},
{
  path: '/comp-appraisals/bbl/:bbl',
  component: <CompAppraisals />
},
{
  path: '/comp-appraisals/pid/:pid',
  component: <CompAppraisals />
},
{
  path: '/fastclose',
  component: <FastCloseLoan />
},
{
  path: '/fastclose/bbl/:bbl',
  component: <FastCloseLoan />
},
{
  path: '/fastclose/pid/:pid',
  component: <FastCloseLoan />
},
{
  path: '/photos',
  component: <Photos />
},
{
  path: '/photos/bbl/:bbl',
  component: <Photos />
},
{
  path: '/photos/pid/:pid',
  component: <Photos />
},
];

const NotAuthorized = () => {
  return (<em>Access Denied</em>)
}

function Callback() {
  const navigate = useNavigate();

  useEffect(() => {
    if ('caches' in window) {
      // Opening given cache and putting our data into it
      caches.open('path').then(response => {
        response.match('/').then(result => {
          result.json().then(path => {
            navigate(path)
          })
        })
      })
    }
  }, [])

  return (<></>)
}


function App() {
  const [isAuthorized, setIsAuthorized] = useState(null)
  const [user, setUser] = useState(null)
  const auth = useAuth()

  const routeComponents = routes.map(({ path, component }, key) => <Route exact path={path} element={component} key={key} />);

  useEffect(() => {
    if (auth && !auth.isLoading) {
      async function fetchData() {
        try {
          setUserManager(auth.userManager);
          const user = await getUser(); // Await the promise from getUser
          
          setUser(user);
          // Assuming setIsAuthorized and userAuthorized are correctly defined elsewhere
          if (user && user.profile) {
            setIsAuthorized(userAuthorized(user.profile.role));
          }
        } catch (error) {
          // Handle any errors that might occur during the getUser call or userManager setting
          console.error("Failed to fetch user data or set userManager:", error);
        }
      }
  
      fetchData();
    }
  }, [auth]);

  return (
    <>
      {auth.isLoading ? (
        <em>Loading...</em>
      ) : (
        <Layout user={user}>
          {isAuthorized ? (
            <Routes>
              <Route path="/callback" element={<Callback />} />
              {routeComponents}
            </Routes>
          ) : <NotAuthorized />}
        </Layout>
      )}
    </>
  )
}

export default App;