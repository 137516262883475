import React from 'react';

const MAX_BARS = 6

function SignalBars({ numBars }) {
  // Calculate the heights and spacing based on the number of bars
  const barWidth = 4;
  const barHeight = 6;
  const spacing = 2;
  const totalHeight = MAX_BARS * (barHeight + spacing);
  const totalWidth = numBars * (barWidth + spacing) - spacing;

  // Create an array to store the bars
  const bars = [];

  for (let i = 0; i < numBars; i++) {
    const height = barHeight * (i + 1);
    const barY = totalHeight - height;

    bars.push(
      <rect
        key={i}
        width={barWidth}
        height={height}
        x={i * (barWidth + spacing)}
        y={barY}
        rx="3"
        fill="#21BA45"
      />
    );
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={totalWidth} height={totalHeight} viewBox={`0 0 ${totalWidth} ${totalHeight}`}>
      {bars}
      <rect width={totalWidth} height={totalHeight} fill="none" />
    </svg>
  );
}

export default SignalBars;
