import React, { Component } from 'react';
import Datatable from 'react-data-table-component'
import { Segment, Checkbox, Input, Button, Grid, Transition, Sticky, Divider } from 'semantic-ui-react'
import { makeGetRequest } from '../utility/Services'
import { get_report_url } from '../utility/Config'
import Spinner from './Loader'
import { formatNumber, downloadCSV } from '../utility/Helper'
import DataSubTable from './extras/DataSubTable'
import { customStyles, FilterComponent } from '../utility/TableHelper';
import _ from 'lodash'

import { dobViolationsColumns, dobViolationsOptions, dobComplaintsColumns, dobComplaintsOptions, hpdViolationsColumns, hpdViolationsOptions, hpdVacatesColumns, hpdVacatesOptions, hpdChargesColumns, hpdChargesOptions, ecbHearingsColumns, ecbHearingsOptions, ecbBalanceColumns, ecbBalanceOptions} from './report-components/ReportTableColumns';


function textForVacateStatus(status) {
    return status === 1 ? 'Active' : status === 0 ? 'Closed' : '-'
}


const ChargeSubHeaderItem = ({title, balance}) => (
    <h4 style={{display: 'inline', marginRight: '2em'}}>{title} : <span style={{color:'red'}}>${formatNumber(balance)}</span></h4>
)

export const ExportButton = ({data}) => (
    <Button floated='right' type='button' content='Export' onClick={(e) => {downloadCSV(data)}}/>
)

export class Report extends Component {
    state = {
      data : [],
      loading: true,
      filterText: '',
      filteredItems: [],
      showOwned: true,
      reportTotals: {},
      selectedItem: null,
      mainVisible: true,
      subVisible: false
  }
  columns = [
      {
        name: 'Address',
        selector: 'address',
        sortable: true,
        width: '250px',
        cell: row => row.property.displayAddress
      },
      {
          name: 'DOB Violations',
          selector: 'dobViolations',
          sortable: true,
          center: true,
          cell: row => (row.dobViolations.length === 0 ? '-' : row.dobViolations.length)
      },
      {
          name: 'DOB Complaints',
          selector: 'dobComplaints',
          sortable: true,
          center: true,
          cell: row => (row.dobComplaints.length === 0 ? '-' : row.dobComplaints.length)
      },
      {
          name: 'Work Order',
          selector: 'stopWorkOrders',
          sortable: true,
          center: true,
          cell: row => (row.stopWorkOrders.length === 0 ? '-' : row.stopWorkOrders.length)
      },
      {
          name: 'Vacate Order',
          selector: 'vacateOrderStatus',
          sortable: true,
          center: true,
          cell: row => <span className={`vacate-status-${textForVacateStatus(row.vacateOrderStatus).toLowerCase()}`}>{textForVacateStatus(row.vacateOrderStatus)}</span>
      },
      {
          name: 'HPD Violations',
          selector: 'hpdViolations',
          sortable: true,
          center: true,
          cell: row => (row.hpdViolations.length === 0 ? '-' : row.hpdViolations.length)
      },
      {
          name: 'HPD Charges',
          selector: 'dofBalance',
          sortable: true,
          center: true,
          cell: row => row.dofBalance === 0 ? '-' : `$${formatNumber(row.dofBalance)}`
      },
      {
          name: 'ECB Hearing',
          selector: 'ecbHearing',
          sortable: true,
          center: true,
      },
      {
          name: 'ECB Corrections',
          selector: 'ecbCorrections',
          sortable: true,
          center: true,
      },
      {
          name: 'ECB Default',
          selector: 'ecbDefault',
          sortable: true,
          center: true,
      },
      {
          name: 'ECB Imposed',
          selector: 'ecbImposed',
          sortable: true,
          center: true,
      }
      ]

    async componentDidMount() {
        let data = await makeGetRequest(get_report_url)
        if (data) {
            data = JSON.parse(data)
        }

        this.setState({data, loading: false}, () => {
            this.setFilter('')
        })
    }

    calculateTotals = (data) => {        
        return {
            dobViolations: data.reduce((total, current) => total + current.dobViolations.length, 0),
            dobComplaints: data.reduce((total, current) => total + current.dobComplaints.length, 0),
            stopWorkOrders: data.reduce((total, current) => total + current.stopWorkOrders.length, 0),
            vacateOrders: data.reduce((total, current) => total + (current.vacateOrderStatus > -1 ? 1 : 0), 0),
            hpdViolations: data.reduce((total, current) => total + current.hpdViolations.length, 0),
            dofBalance: data.reduce((total, current) => total + current.dofBalance, 0),
            ecbHearings: 0,
            ecbCorrections: 0,
            ecbDefaults: 0,
            ecbImposed: 0
        }
    }

    handleClear = () => {
        this.setFilter('')
      }

    handleFilter = (e) => {
        const val = e.target.value
        if (val === '') {
          this.handleClear()
          return
        }
        this.setFilter(val)
      }

    handleAllToggle = () => {
        const {showOwned, filterText} = this.state
        this.setState({showOwned: !showOwned}, () => {
          this.setFilter(filterText)
        })
      }

    setFilter = (val) => {
        const {data, showOwned} = this.state

        let tmpData = [...data]

        tmpData = tmpData.filter(item => 
            item.isOwned = showOwned
          )

        let filteredItems
        if (val !== '') {
          filteredItems = tmpData.filter(item => (item.property.displayAddress?.toLowerCase().includes(val.toLowerCase())))
        } else {
          filteredItems = tmpData
        }

        this.setState({filterText: val, filteredItems, reportTotals: this.calculateTotals(filteredItems)})
      }

    customSort = (rows, field, direction) => {
        const handleField = row => {
            if (field === 'address') {
                return row['property']['displayAddress']?.toLowerCase()
            } else if (row[field]) {
                if (typeof row[field] === 'string') {
                    return row[field].toLowerCase();
                }
            }
    
        return row[field];
      };
    
      return _.orderBy(rows, handleField, direction)
    }

    onRowClicked = (data) => {
        if (data) {
            this.setState({selectedItem: data, mainVisible: false})
        }
    }

    renderSubTables = () => {
        const {selectedItem} = this.state
        return (
            <Segment basic>
                <Sticky>
                <Segment basic className='no-padding-h' style={{background:'white'}}>
                <Button basic size='small' className='no-border' floated='left' icon='arrow left' onClick={() => {this.setState({subVisible: false})}} />
                <h2 style={{display: 'inline'}}>{selectedItem?.property.displayAddress}</h2>
                <Divider /> 
                </Segment>
                </Sticky>
                <Grid columns='equal'>
                <Grid.Row>
                    <Grid.Column>
                        <DataSubTable data={selectedItem?.dobViolations} columns={dobViolationsColumns} options={dobViolationsOptions} />
                    </Grid.Column>
                    <Grid.Column>
                        <DataSubTable data={selectedItem?.dobComplaints} columns={dobComplaintsColumns} options={dobComplaintsOptions} />
                    </Grid.Column>
                </Grid.Row>
                </Grid>
            <Grid columns='equal'>
                <Grid.Row>
                    <Grid.Column>
                        <DataSubTable data={selectedItem?.hpdVacateOrders} columns={hpdVacatesColumns} options={hpdVacatesOptions} />                       
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Grid columns='equal'>
                <Grid.Row>
                    <Grid.Column>
                        <DataSubTable data={selectedItem?.hpdViolations} columns={hpdViolationsColumns} options={hpdViolationsOptions} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Grid columns='equal'>
                <Grid.Row>
                    <Grid.Column>
                        <DataSubTable data={selectedItem?.hpdCharges} columns={hpdChargesColumns} options={hpdChargesOptions} 
                        subheader={<Segment basic vertical style={{width: '100%'}}>                        
                        <ChargeSubHeaderItem title='Paid and not transferred to DoF' balance={selectedItem?.paidHpd}/>
                        <ChargeSubHeaderItem title='Paid and transferred to DoF' balance={selectedItem?.transferredHpd}/>
                        <ChargeSubHeaderItem title='DoF Balance' balance={selectedItem?.dofBalance}/>
                        </Segment>} 
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Grid columns='equal'>
                <Grid.Row>
                    <Grid.Column>
                        <DataSubTable data={[...selectedItem?.ecbHearings ?? [], ...selectedItem?.ecbCorrections ?? []]} 
                        columns={ecbHearingsColumns} options={ecbHearingsOptions} />
                    </Grid.Column>
                    <Grid.Column>
                        <DataSubTable data={[...selectedItem?.ecbDefaults ?? [], ...selectedItem?.ecbImposed ?? []]} 
                        columns={ecbBalanceColumns} options={ecbBalanceOptions} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            </Segment>
        )
    }

    render () {
        const {filteredItems, filterText, loading, showOwned, mainVisible, subVisible} = this.state
        
        return (
          <div style={{padding: '1em 15em'}}>
              <Transition 
              animation='fly left'
              reactKey='sub-reports-table'
              transitionOnMount={true}
              unmountOnHide={true}
              visible={subVisible}
              onHide={() => {this.setState({mainVisible: true, selectedItem: null})}}
              >
              {this.renderSubTables()}
              </Transition>
              
              <Transition
              animation='fly right'
              reactKey='reports-table'
              transitionOnMount={true}
              unmountOnHide={true}
              visible={mainVisible}
              onHide={() => {this.setState({subVisible: true})}}
              >
              <Datatable
                title='Buildings Overview'
                className='reports-table'
                noHeader={false}
                persistTableHead
                columns={this.columns}
                data={filteredItems}
                highlightOnHover
                pointerOnHover
                customStyles={customStyles}
                defaultSortField='address'
                fixedHeader
                fixedHeaderScrollHeight='68vh'
                dense
                striped
                progressPending={loading}
                progressComponent={<Spinner active={loading} inline={true} />}
                //conditionalRowStyles={this.conditionalRowStyles()}
                sortFunction={this.customSort}
                onRowClicked={this.onRowClicked}
                subHeader
                subHeaderAlign='left'
                subHeaderComponent={(
                    <Segment basic style={{width: '100%'}}>
                    <FilterComponent onFilter={this.handleFilter} onClear={this.handleClear} filterText={filterText} />
                    <div style={{marginLeft: '2em', display:'none'}}>
                      <em>Prospective</em>
                      <Checkbox style={{marginLeft:'1em', marginRight:'1em', verticalAlign: 'text-bottom'}} toggle checked={showOwned} onChange={this.handleAllToggle} />
                      <em>Owned</em>
                    </div>
                    </Segment>
                )}
                pagination
                paginationPerPage={9999}
                paginationComponent={({
                    rowsPerPage,
                    rowCount,
                    onChangePage,
                    onChangeRowsPerPage,
                    currentPage,
                    reportTotals = this.state.reportTotals,
                }) => (
                <Segment.Group className='reports-table-footer' horizontal>
                    <Segment>Total:</Segment>
                    <Segment>{reportTotals.dobViolations}</Segment>
                    <Segment>{reportTotals.dobComplaints}</Segment>
                    <Segment>{reportTotals.stopWorkOrders}</Segment>
                    <Segment>{reportTotals.vacateOrders}</Segment>
                    <Segment>{reportTotals.hpdViolations}</Segment>
                    <Segment>${formatNumber(reportTotals.dofBalance)}</Segment>
                    <Segment>{reportTotals.ecbHearings}</Segment>
                    <Segment>{reportTotals.ecbCorrections}</Segment>
                    <Segment>{reportTotals.ecbDefaults}</Segment>
                    <Segment>{reportTotals.ecbImposed}</Segment>
                </Segment.Group>
              )}
            />
              </Transition> 
          </div>
      );
    }
  }