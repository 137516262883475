import React from 'react'
import { Grid, List, Placeholder, Label, Segment } from 'semantic-ui-react'
import { formatNumber } from '../../utility/Helper'

const colWidthOne = 8
const colWidthTwo = 8

function getLineLength(index) {
    const lengths = ["long", "medium", "short", "very long", "very short"]
    let lIndex = (index + 1) % lengths.length
    const randomLength = lengths[lIndex];
    return randomLength
  }

  function formattedValue(value) {
    if (typeof value === 'string') return value
    else return formatNumber(value)
  }

export const GridForPopup = ({ title, list, keyName = 'name', titleKey = '', isList = false }) => {
    return (
        <Grid className='pu-grid'>
            <Grid.Row columns={1}>
                <Grid.Column>
                    <List size='small'>
                        {title && title.length > 0 ? (
                            <List.Item>
                                <List.Header>{title}</List.Header>
                            </List.Item>
                        ) : null}
                        {isList ? (
                            <>
                            {list?.map(_ => {
                            //return _.[titleKey] && _.[titleKey].length > 0 ?  : <List.Item></List.Item>
                            return <List.Item className='pu-grid-li'>{_}</List.Item>
                        })}
                            </>
                        ) : (
                            <>
                            {list?.map(_ => {
                            //return _.[titleKey] && _.[titleKey].length > 0 ?  : <List.Item></List.Item>
                            return  <List.Item className='pu-grid-li'>
                            {titleKey.length > 0 && _[titleKey] && _[titleKey].length > 0 ? (
                                <span><b>{_[titleKey]}:</b> </span>
                            ) : ''}
                            <span>{formattedValue(_[keyName])}</span>
                            </List.Item>
                        })}
                            </>
                        )}
                    </List>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}


//<List.Item className='pu-grid-li'>{titleKey.length > 0 && _.[titleKey] && _.[titleKey].length > 0 ? <span><b>{_.[titleKey]}:</b> </span> : ''}<span>{formattedValue(_.[keyName])}</span></List.Item>

export const PlaceholderTableLine = ({ length }) => (
    <Grid.Row>
        <Grid.Column width={colWidthOne}>
            <Placeholder>
                <Placeholder.Line length={getLineLength(length)} />
            </Placeholder>
        </Grid.Column>
        <Grid.Column width={colWidthTwo}>
            <Placeholder>
                <Placeholder.Line length={getLineLength(length)} />
            </Placeholder>
        </Grid.Column>
    </Grid.Row>
)

export const PlaceholderStatSet = ({ title }) => (
    <Segment className='stat-counter' circular>
        <Label className={`color-${title.toLowerCase()}`} size='small' attached='top' content={title} />
        <Placeholder.Line style={{ background: 'transparent' }} length='very short' />
        <Placeholder.Line style={{ background: 'transparent' }} length='very short' />
    </Segment>
)
