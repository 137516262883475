import React, { Component } from 'react';
import Datatable from 'react-data-table-component'
import { Segment, Input, Button, Transition, Select, Checkbox, Sticky, Divider, Grid, Label } from 'semantic-ui-react'
import { makeGetRequest } from '../utility/Services';
import { acris_document_details_url, intel_investor_connections_url, intel_investor_info_url, intel_investor_parcels_url } from '../utility/Config'
import { customStyles, FilterComponent } from '../utility/TableHelper';
import Spinner from './Loader'
import _ from 'lodash'
import DataSubTable from './extras/DataSubTable';
import { legalsColumns, legalsOptions, lotColumns, lotOptions, mastersColumns, mastersOptions, originatorColumns, originatorOptions, partyColumns, partyOptions, subLegalsColumns } from './investor-components/InvestorTableColumn';
import { formatNumber, formatDate } from '../utility/Helper';

import {shortBoroLookup} from '../Tax-Lot'

const BoroughsGroup = ({boroughs}) => (
  <Segment.Group className='connections-label-group' horizontal size='mini'>
    {[1,2,3,4,5].map((index, num) => {
      return <Segment key={num} color={boroughs?.includes(index) ? 'green' : null}>{shortBoroLookup[index]}</Segment>
    })
    }
  </Segment.Group>
)

const UnitsGroup = ({units}) => (
  <Segment.Group className='connections-label-group' horizontal size='mini'>
    {[1,2,3,4].map((index, num) => {
      return <Segment key={num} color={units?.includes(index) ? 'green' : null}>{index}</Segment>
    })
    }
    <Segment color={units?.some(_ => _ < 1 || _ > 4) ? 'green' : null}>Oth</Segment>
  </Segment.Group>
)

const options = [
    {key: 1, value: 1, text: 'INVESTOR'},
  ]

export class Investors extends Component {
    
        state = {
            data : [],
            initialized: false,
            loading: false,
            searchValue: '',
            optionValue: 1,
            contains: false,
            filterText: '',
            filteredItems: [],
            selectedItem: null,
            selectedSubItem: null,
            mainVisible: true,
            subVisible: false
        }
        columns = [
            {
                name: 'Investor',
                selector: 'investorName',
                sortable: true,
                //width: '180px'
                
                //cell: row => row.lenders[0]?.name
                //cell: row => <span onClick={this.handleInvestorClick.bind(this, row.investor)}>{row.investor}</span>
            },
            // {
            //     name: 'Title',
            //     selector: 'title',
            //     sortable: true,
            //     //width: '200px'
            //     //grow: 1,
            //     //cell: row => <span onClick={this.handleBorrowerClick.bind(this, row.borrower)}>{row.borrower}</span>
            //     //cell: row => row.borrowers[0]?.name
            // },
            {
              name: 'Total Entities',
              selector: 'totalEntities',
              sortable: true,
              center: true,
              grow: 0.5
              //cell: row => row.parcels[0].displayAddress
            },
            {
                name: 'Total Properties',
                selector: 'totalProperties',
                sortable: true,
                center: true,
                //cell: row => row.parcels[0].type
                grow: 0.5
            },
            {
              name: 'Currently Owned',
              selector: 'totalOwned',
              sortable: true,
              center: true,
              grow: 0.5,
              format : row => (row.totalOwned > 0 ? row.totalOwned : '-')
              //cell: row => row.parcels[0].type
              //width: '72px'
          },{
              name: 'Total Units',
              selector: 'totalUnits',
              sortable: true,
              center: true,
              grow: 0.5
              //cell: row => row.parcels[0].type
              //width: '72px'
          },
          {
            name: 'Unit Types',
            selector: 'unitTypes',
            sortable: false,
            center: true,
            //cell: row => row.parcels[0].type
            grow: 1.25,
            cell: row => <UnitsGroup units={row?.unitTypes} />
            //width: '72px'
         },
          {
            name: 'Boroughs',
            selector: 'boroughs',
            sortable: false,
            center: true,
            grow: 1.25,
            cell: row => <BoroughsGroup boroughs={row?.boroughs} />
            //width: '72px'
          },
          {
            name: 'Total Acquisition',
            selector: 'totalAcquisition',
            sortable: true,
            center: true,
            format : row => (row.totalAcquisition ? `$${formatNumber((row.totalAcquisition))}` : '-')

            //cell: row => row.parcels[0].type
            //width: '72px'
         },
         {
          name: 'Most Recent',
          selector: 'mostRecentAcquisition',
          sortable: true,
          center: true,
          format : row => (row.mostRecentAcquisition ? `${formatDate((row.mostRecentAcquisition))}` : '-')

          //cell: row => row.parcels[0].type
          //width: '72px'
        },{
          name: 'Total Loan',
          selector: 'totalLoan',
          sortable: true,
          center: true,
          format : row => (row.totalLoan ? `$${formatNumber((row.totalLoan))}` : '-')

          //cell: row => row.parcels[0].type
          //width: '72px'
       },
          ]
      

          componentDidMount() {
           
          }

    

    handleClear = () => {
        this.setFilter('')
      }

    handleFilter = (e) => {
        const val = e.target.value
        if (val === '') {
          this.handleClear()
          return
        }
        this.setFilter(val)
      }

    setFilter = (val) => {
        const {data} = this.state

        let tmpData = [...data]

        let filteredItems
        if (val !== '') {
          filteredItems = tmpData.filter(item => (
            item.investorName?.toLowerCase().includes(val.toLowerCase())
            ))
        } else {
          filteredItems = tmpData
        }

        this.setState({filterText: val, filteredItems})
      }

    customSort = (rows, field, direction) => {
        const handleField = row => {
            if (row[field]) {
                if (typeof row[field] === 'string') {
                    return row[field].toLowerCase();
                }
            }
    
        return row[field];
      };
    
      return _.orderBy(rows, handleField, direction)
    }

    handleQuery = async () => {
        const {searchValue, optionValue, contains} = this.state
  
        if (searchValue.trim().length === 0) {
          return
        }
  
        const _this = this
        this.setState({loading: true}, async () => {
          const data = await makeGetRequest(`${intel_investor_connections_url}?search=${searchValue}&contains=${contains}`)
          if (data === null) {
            _this.setState({loading: false}, () => {
              this.handleClear()
            })
          } else {
            _this.setState({loading: false, data: data, filteredItems: data}, () => {
              this.handleClear()
            })
          }
        })
      }

      onRowClicked = async (data) => {
        if (data) {
            this.setState({selectedItem: data, mainVisible: false})

            if (!data.legals) {
              const result = await makeGetRequest(`${intel_investor_info_url}?id=${data?.investorId}`)

              if (result) {
//                data.legals = result[data.investor]
                data.lots = result
                this.forceUpdate()
              }
            }
        }
    }

    onParcelRowClick = async (row) => {
      if (row) {
          if (!row.parcelInfo) {
            const result = await makeGetRequest(`${intel_investor_parcels_url}?bbl=${row.propertyId}`)

            if (result && result.parcelInfo) {
              row.parcelInfo = result.parcelInfo
            }
          }
          this.setState({selectedSubItem: row})
      }
    }

    onMasterRowClick = (row) => {
      window.open(`${acris_document_details_url}${row.documentId}`, "_blank");
  }

  closeDetails = () => {
    this.setState({subVisible: false, selectedItem: null, selectedSubItem: null})
  }

      renderSubTables = () => {
        const {selectedItem, selectedSubItem} = this.state
        return (
            <Segment basic>
                <Sticky>
                <Segment basic className='no-padding-h' style={{background:'white'}}>
                <Button basic size='small' className='no-border' floated='left' icon='arrow left' onClick={this.closeDetails.bind(this)} />
                <h2 style={{display: 'inline'}}>{selectedItem?.investorName}</h2>
                <Divider /> 
                </Segment>
                </Sticky>
                <Grid>
                <Grid.Row>
                  <DataSubTable data={selectedItem?.lots} fixedScrollHeight='65vh' columns={lotColumns} options={lotOptions} /*onRowClicked={this.onParcelRowClick.bind(this)}*/ />
                </Grid.Row>
                </Grid>
                {/* <Grid>
                <Grid.Row>
                    <Grid.Column width={selectedSubItem ? '2' : '16'}>
                    <DataSubTable data={selectedItem?.legals} fixedScrollHeight='65vh' columns={selectedSubItem ? subLegalsColumns : legalsColumns} options={legalsOptions} onRowClicked={this.onParcelRowClick.bind(this)} />
                    </Grid.Column>
                    {selectedSubItem ? (
                      <Grid.Column width='14'>
                        <DataSubTable data={selectedSubItem?.parcelInfo?.parties} fixedScrollHeight='27vh' columns={partyColumns} options={partyOptions}/>
                        <DataSubTable data={selectedSubItem?.parcelInfo?.masters} fixedScrollHeight='27vh' columns={mastersColumns} options={mastersOptions} onRowClicked={this.onMasterRowClick.bind(this)} />
                      </Grid.Column>
                    ) : null}
                </Grid.Row>
                </Grid> */}
                {/* <Grid columns='equal'>
                <Grid.Row>
                    <Grid.Column>
                      <DataSubTable data={selectedItem?.info?.originators} columns={originatorColumns} options={originatorOptions} />
                    </Grid.Column>
                     <Grid.Column>
                        <DataSubTable data={selectedItem?.info?.masters} columns={mastersColumns} options={mastersOptions} />
                    </Grid.Column>
                    <Grid.Column>
                      <DataSubTable data={selectedItem?.info?.parties} columns={partyColumns} options={partyOptions} />
                    </Grid.Column>
                </Grid.Row>
                </Grid> */}
            </Segment>
        )
    }

    render () {
        const {filteredItems, filterText, loading, optionValue, searchValue, contains, mainVisible, subVisible} = this.state
        
        return (
          <div style={{padding: '1em 15em'}}> 

          <Transition 
              animation='fly left'
              reactKey='sub-investors-table'
              transitionOnMount={true}
              unmountOnHide={true}
              visible={subVisible}
              onHide={() => {this.setState({mainVisible: true, selectedItem: null})}}
              >
              {this.renderSubTables()}
              </Transition>


              <Transition
              animation='fly right'
              reactKey='intel-table'
              transitionOnMount={true}
              unmountOnHide={true}
              visible={mainVisible}
              onHide={() => {this.setState({subVisible: true})}}
              >
              <Segment basic>
                 <Segment basic size='mini'>
                  <Select style={{width: '200px', marginRight: '1em'}} options={options} value={optionValue} onChange={(e, {value}) => {this.setState({optionValue: value})}} />
                  <Checkbox label='Contains?' checked={contains} onChange={(e, {checked}) => {this.setState({contains: checked})}} />
                  <Input className='intel-search-input' style={{marginLeft: '2em', width: '25%'}} defaultValue={searchValue} onChange={(e, {value}) => {this.setState({searchValue: value})}} />
                  <Button style={{marginLeft: '1em'}} icon='play' onClick={this.handleQuery}></Button>
                </Segment>
              <Datatable
                title='Intel Connections'
                className='connections-table'
                noHeader={true}
                persistTableHead
                columns={this.columns}
                data={filteredItems}
                highlightOnHover
                pointerOnHover
                customStyles={customStyles}
                defaultSortField='investorName'
                defaultSortAsc={true}
                fixedHeader
                fixedHeaderScrollHeight='65vh'
                dense
                striped
                responsive
                progressPending={loading}
                progressComponent={<Spinner active={loading} inline={true} />}
                //conditionalRowStyles={this.conditionalRowStyles()}
                //sortFunction={this.customSort}
                onRowClicked={this.onRowClicked}
                subHeader
                subHeaderAlign='left'
                subHeaderComponent={(
                    <Segment basic style={{width: '100%'}}>
                    <FilterComponent onFilter={this.handleFilter} onClear={this.handleClear} filterText={filterText} />
                    </Segment>
                )}
                pagination
                paginationPerPage={100}
                paginationRowsPerPageOptions={[100]}
              />
              </Segment>
              </Transition> 
          </div>
      );
    }
  }