import React from "react"
import { Header, Image, Table } from "semantic-ui-react";
import Lot from "../../Tax-Lot";
import { formatDate, formatNumber } from "../../utility/Helper";
import { mean } from "../../utility/MathHelper";

const comparableTableRows = [
    {
        name: 'Neighborhood',
        key: 'neighborhood'
    },
    {
        name: 'LTDS',
        key: 'ltds',
        format: item => (item.ltds > 0 ? `${formatNumber(item.ltds)}%` : '-')
    },
    {
        name: 'Photo',
        key: 'photo',
        cell: item => <div style={{maxWidth: '100px', overflow: 'hidden'}}>
            <a 
            //href={`http://maps.google.com/maps?z=12&t=m&q=loc:${item.latitude}+${item.longitude}&layer=c`} regular maps
            href={`http://www.google.com/maps?layer=c&cbll=${item.latitude},${item.longitude}`}
            rel="noreferrer"
            target='_blank'>
                <Image 
                centered
                size='small'
                src={`https://maps.googleapis.com/maps/api/streetview?size=1920x450&location=${item.latitude},${item.longitude}&fov=100&pitch=0&sensor=false&scale=2&key=${process.env.REACT_APP_GOOGLE_API_KEY}`} 
                />
            </a>
        </div>
    },
    {
        name: 'Distance',
        key: 'distance',
        format: item => <>{formatNumber(item.distance)} mi</>
    },
    {
        name: 'Date Closed',
        key: 'documentDate',
        format: item => formatDate(item.documentDate)
    },
    {
        name: 'Sale Price',
        key: 'documentAmount',
        format: item => <>${formatNumber(item.documentAmount)} {item.withinTwoYears ? '(F)' : ''}</>
    },
    {
        name: 'Price/Sqft',
        key: 'pricePerSqft',
        format: item => <>${formatNumber(item.pricePerSqft)}</>
    },
    {
        name: 'Seller & Buyer',
        key: 'buyersAndSellers',
        className: 'comparables-parties',
        //cell: item => <span title={item.sellerNames}>{item.sellerNames}</span>
        cell: item => (<div className="deed-party-container"><span className="deed-party" title={item.sellerNames}>{item.sellerNames}</span><span className="deed-party" title={item.buyerNames}>{item.buyerNames}</span></div>)
    },
    // {
    //     name: 'Buyer',
    //     key: 'buyerNames',
    //     className: 'comparables-parties',
    //     cell: item => <span title={item.buyerNames}>{item.buyerNames}</span>
    // },
    {
        name: 'Time Held',
        key: 'heldString'
    },
    {
        name: 'Residential Units',
        key: 'residentialUnits',
        format: item => item.totalUnits > item.residentialUnits ? `${item.residentialUnits}/${item.totalUnits}` : item.residentialUnits
    },
    {
        name: 'Construction',
        key: 'construction',
        format: item => Lot.isPluto(item) ? <>{item.construction} ({item.bldgClass})</> : <>{Lot.lotConstruction(item)}</>
    },
    {
        name: 'Proximity',
        key: 'proxCode',
        format: item => item.proximity
    },
    {
        name: 'Building Sqft',
        key: 'bldgArea',
        format: row => (formatNumber(row.bldgArea))
    },
    {
        name: 'Building Dimensions',
        key: 'bldgsize',
        format: item => Lot.bldgSizeString(item)
    },
    {
        name: 'Building Height',
        key: 'buildingHeight',
        format: item => <>{formatNumber(item.buildingHeight)} ft</>
    },
    {
        name: 'Stories',
        key: 'stories'
    },
    {
        name: 'Basement',
        key: 'hasBasement',
        format: item => item.hasBasement ? 'Yes' : 'No'
    },
    {
        name: 'Extension',
        key: 'hasExtension',
        format: item => item.hasExtension ? 'Yes' : 'No'
    },
    {
        name: 'Garage',
        key: 'hasGarage',
        format: item => item.hasGarage ? 'Yes' : 'No'
    },
    {
        name: 'Year Built',
        key: 'yearBuilt'
    },
    {
        name: 'Zoning',
        key: 'zoning'
    },
    {
        name: 'Lot Sqft',
        key: 'lotArea',
        format: row => (formatNumber(row.lotArea))
    },
    {
        name: 'Lot Dimensions',
        key: 'lotsize',
        format: item => (Lot.lotSizeString(item))
    },
    {
        name: 'Corner',
        key: 'isCorner',
        format: item => item.isCorner ? 'Yes' : 'No'
    },
    {
        name: 'Irregular',
        key: 'irregular',
        format: item => item.isIrregular ? 'Yes' : 'No'
    },
    {
        name: 'Wide Street',
        key: 'isWideStreet',
        format: item => item.isWideStreet ? 'Yes' : 'No'
    },
]

//style={{maxWidth: `${tableItem.width ? tableItem.width : 'inherit'}`, minWidth: `${tableItem.width ? tableItem.width : 'inherit'}`}} 
const ComparableTableRow = ({tableItem, lots}) => (
    <Table.Row>
        <Table.Cell>{tableItem.name}</Table.Cell>
        {lots.map((lot, index) => {
            return <Table.Cell className={tableItem.className} key={index}> 
                {
                    tableItem.cell ? tableItem.cell(lot) :
                    tableItem.format ? tableItem.format(lot) :
                    lot ? lot[tableItem.key] :
                    ''
                }</Table.Cell>
        })}
    </Table.Row>
)

const filteredComparableTableRows = (isPluto, rows) => {
    if (!isPluto) {
        let keysToRemove = ['proxcode', 'irregular', 'isWideStreet', 'bldgsize', 'buildingHeight', 'corner']
        rows = [...rows.filter(_ => !keysToRemove.includes(_.key))]
    }
    return rows
}

const ComparablesTable = ({lots}) => {
    const allTDS = [...lots].splice(1).filter(_ => _.ltds).map(_ => _.ltds)
    const percent = allTDS && allTDS.length > 0 ? mean(allTDS) : 0
    const color = percent >= 70 ? 'green' : 'red'
    const isPluto = lots.length > 0 ? Lot.isPluto(lots[0]) : false
    return (
        <Table className="comparables-table" compact definition basic='very'>
            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell textAlign="center">
                       { percent > 0 && <><Header as='h4' color={color}>{formatNumber(percent)}%</Header> Confidence</> }
                    </Table.HeaderCell>
                    {lots.map((lot, index) => {
                        return <Table.HeaderCell key={index}>{lot.address}</Table.HeaderCell>
                    })}
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {filteredComparableTableRows(isPluto,comparableTableRows).map((item, index) => {
                    return <ComparableTableRow key={index} tableItem={item} lots={lots} />
                })}
            </Table.Body>
        </Table>
    )
}

export default React.memo(ComparablesTable)