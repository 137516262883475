import React, { Component } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';

export class ContainedAccordian extends Component {
    state = {
        active: this.props.active ?? true
    }

    handleClick = (e) => {
        const {active} = this.state
        this.setState({ active: !active })
      }

    render () {
        const {active} = this.state
        const {title, titlePosition} = this.props
        return (
            <Accordion className='no-border no-padding' fluid styled>
            <Accordion.Title style={{textAlign: titlePosition}} active={active} onClick={this.handleClick}>
                {title && title.length > 0 ? (
                    <>{title}<Icon name='dropdown' /></>
                ) : null }
            </Accordion.Title>
            <Accordion.Content active={active} >
                {this.props.children}
            </Accordion.Content>
            </Accordion>
        )
    }
}