import { getUser } from "./Auth"
import { property_api_fred_url, property_api_get_city_data_url, property_api_get_city_sale_data_url } from "./Config"
import { DataSetType } from "./Global"

let loadingData = false

export function getFredPrices(limit = 1) {
  return fetch(`${property_api_fred_url}?limit=${limit}`)
    .then(res => res.json())
    //.then(res => JSON.parse(res).observations)
    .catch(error => error)
}

export function getInterestRates() {
  const options = {
    method: "GET",
    headers: {
      'accept': 'application/json',
      'X-API-KEY': '7iPDSG8LPY46R51TQfH3QhjJq8DpUFM9D0Om1kKd'
    }
  }

  return fetch('https://yfapi.net/v6/finance/quote?region=US&lang=en&symbols=%5ETNX', options)
    .then(res => res.json())
    .then(result => result.quoteResponse.result[0].regularMarketPrice)
    .catch(error => error)
}

export function makeGetRequestWithCallback(url, callback = null, includeToken = true) {
  return makeRequestWithCallback(url, "GET", {}, callback, includeToken)
}

export function makePutRequestWithCallback(url, data, callback = null, includeToken = true) {
  return makeRequestWithCallback(url, "PUT", data, callback, includeToken)
}

async function makeRequestWithCallback(url, method, data = {}, callback = null, includeToken = true) {
  const timeout = 60000 * 10; // 10 minutes timeout
  const options = {
    method: method,
    cache: 'no-cache', // Disable cache
    headers: {}
  };

  if (includeToken) {
    const token = (await getUser()).access_token;
    options.headers['Authorization'] = 'Bearer ' + token; // Add Authorization header
  }

  if (method !== 'GET' && method !== 'DELETE') {
    options.headers['Content-Type'] = 'application/json';
    options.body = JSON.stringify(data); // Add body for non-GET/DELETE methods
  }

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  options.signal = controller.signal;

  fetch(url, options)
    .then((response) => {
      clearTimeout(id); // Clear the timeout once response is received

      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error((error.title ?? error.message) || `HTTP error! Status: ${response.status}`);
        });
      }

      return response.json(); // Parse JSON response
    })
    .then((results) => {
      if (callback) {
        callback(results); // Pass results to the callback
      }
    })
    .catch((error) => {
      if (error.name === 'AbortError') {
        console.error('Request was aborted due to timeout.');
        if (callback) {
          alert('Request was aborted due to timeout.')
          callback(null); // Pass timeout error to callback
        }
      } else {
        console.error(error);
        if (callback) {
          alert(error.message)
          callback(null); // Pass error message to callback
        }
      }
    });
}


export async function makeUploadRequest(url, formData) {
  const token = (await getUser()).access_token

  const options = {
    method: "POST",
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      Authorization: 'Bearer ' + token
    },
    body: formData
  }

  let result = await fetch(url, options)
    .then(response => response.json())
    .then(data => data)
    .catch(error => {
      console.error(error)
    })

  console.log(result)
  return result
}

export async function makeUploadRequestWithCallback(url, formData, callback = null) {
  const token = (await getUser()).access_token

  const options = {
    method: "POST",
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      Authorization: 'Bearer ' + token
    },
    body: formData
  }

  fetch(url, options)
    .then(response => response.json())
    .then(data => {
      console.log(data)

      if (callback) {
        callback(data)
      }
    })
    .catch(error => {
      console.error(error)
    })
}

export async function makePostRequest(url, data = {}) {
  return await makeRequest('POST', url, data)
}

export async function makePutRequest(url, data = {}) {
  return await makeRequest('PUT', url, data)
}

export async function makeGetRequest(url, stopIfLoading = false) {
  if (stopIfLoading && loadingData) {
    return
  }
  return await makeRequest('GET', url)
}

export async function makeDeleteRequest(url) {
  return await makeRequest('DELETE', url)
}

async function makeRequest(method, url, data = {}) {
  const timeout = 60000 * 10; // 10 minutes timeout
  loadingData = true;

  const token = (await getUser()).access_token;

  const options = {
    method: method,
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Connection': 'Keep-Alive',
      'Authorization': 'Bearer ' + token
    }
  };

  if (method !== 'GET' && method !== 'DELETE') {
    options.body = JSON.stringify(data); // Attach body for non-GET/DELETE methods
  }

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  options.signal = controller.signal;

  try {
    const response = await fetch(url, options);
    clearTimeout(id); // Clear the timeout if the fetch completes

    if (!response.ok) {
      // Extract server error message if available
      const error = await response.json();
      throw new Error(error.message || `HTTP error! Status: ${response.status}`);
    }
    var result = await response.json();
    console.log(result)
    return result // Return parsed JSON data
  } catch (error) {
    if (error.name === 'AbortError') {
      alert('Request was aborted due to timeout.');
    } else {
      alert(error)
    }
    return null; // Return null on error
  } finally {
    clearTimeout(id);
    loadingData = false;
  }
}

export async function getCityData(propertyId) {
  const data = await makeGetRequest(`${property_api_get_city_data_url}?propertyId=${propertyId}`)

  const saleData = await makeGetRequest(`${property_api_get_city_sale_data_url}?propertyId=${propertyId}`)

  return {
    data,
    saleData
  }
}