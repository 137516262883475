import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import '../../assets/css/CompAppraisals.css'

import { Form, Segment, Input, Grid, Label, Button, Divider, Loader, Dimmer, Sidebar, Icon } from 'semantic-ui-react';
import { makeGetRequest, makeUploadRequest } from '../../utility/Services';
import { bucketColor, runComps, runIndividualComps } from '../Comps';
import { CompBucketOption, CompTypeOption, today, DataSetType } from '../../utility/Global';
import LTDSChart, { getTDSChartData } from '../charts/LTDSChart';
import { getChartMovingAverage } from '../charts/ChartHelper';
import { formatDate, formateInputDate, formatNumber, getParcelAddressQueryParams, isEmpty } from '../../utility/Helper';
import ComparablesTable from '../tables/Comparables';
import { PropertyCondition, ValuationBucketContainer } from '../extras/ValueContainer';
import MapboxMap from '../maps/MapboxMap';
import SearchLocationInput, { getGoogleAddress } from '../extras/SearchLocationInput';
import Lot from '../../Tax-Lot';
import { parcel_search_url, property_api_extract_addresses_from_pdf_url } from '../../utility/Config';

const propertyConditionOptions = [
    { key: PropertyCondition.All, text: 'Any Condition', value: PropertyCondition.All },
    { key: PropertyCondition.Excellent, text: 'Excellent', value: PropertyCondition.Excellent },
    { key: PropertyCondition.Fair, text: 'Fair', value: PropertyCondition.Fair },
    { key: PropertyCondition.Poor, text: 'Poor', value: PropertyCondition.Poor }
]

const UrbynLotField = ({ index, lot, removeHandler }) => (
    <Form.Field className='comp-inline-field' inline>
        <label>Urbyn {index + 1}</label>
        <Input disabled value={lot ? lot.address : ''} />
        <label className='comp-inline-price'>{lot ? `${formatDate(lot.documentDate)} | $${formatNumber(lot.documentAmount)}\n${lot.withinTwoYears ? '(f) ' : ''}${lot.unitCode ?? lot.totalUnits} Unit | ${formatNumber(lot.ltds)}%` : ''}</label>
        {/* <Button icon='x' onClick={() => removeHandler(index)} /> */}
    </Form.Field>
)

const CompLotField = ({ index, compLot, wrongUnit, handleResultSelect, handleSearchChange }) => {
    const { lot, fetched, value } = compLot || {}
    return (
        <Form.Field className='comp-inline-field' inline>
            <label>Comp {index + 1}</label>
            <SearchLocationInput 
            className={wrongUnit ? 'fluid invalid-unit' : 'fluid'} 
            name=''
            label=''
            icon='home'
            placeholder='Enter property address'
            value={fetched ? Lot.fullAddress(lot) : value}
            onChange={handleSearchChange} 
            isLoading={compLot.isLoading} />
            <label className='comp-inline-price'>{compLot.fetched ? `${formatDate(lot.documentDate)} | $${formatNumber(lot.documentAmount)}\n${lot.unitCode ?? lot.totalUnits} Unit | ${formatNumber(lot.ltds)}%` : ''}</label>
        </Form.Field>
    )
}

const CompAppraisals = () => {
    const [isAutomatedLoading, setIsAutomatedLoading] = useState(false)
    const [startComps, setStartComps] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [value, setValue] = useState('')
    const [gotComps, setGotComps] = useState(false)
    const [condition, setCondition] = useState(PropertyCondition.All)
    const [appraisalDate, setAppraisalDate] = useState(today())

    const [selectedValuation, setSelectedValuation] = useState(null)

    const [target, setTarget] = useState(null)
    const [valuationData, setValuationData] = useState(null)
    const [tdsChart, setTDSChart] = useState(null)
    const [appraisalChartData, setAppraisalChartData] = useState([])

    const [isCompsLoading, setIsCompsLoading] = useState(false)
    const [comps, setComps] = useState([])
    const [urbynComps, setUrbynComps] = useState([])
    const [appraiserComps, setAppraiserComps] = useState([])
    const [appraiserValuation, setAppraiserValuation] = useState(null)

    const [refreshComps, setRefreshComps] = useState(false)

    const [leftSidebarVisible, setLeftSidebarVisible] = useState(false)
    const [rightSidebarVisible, setRightSidebarVisible] = useState(false)

    const [fullPage, setFullPage] = useState(false)
    const [fullPageComps, setFullPageComps] = useState(null)

    const [markerData, setMarkerData] = useState([])
    const { bbl, pid } = useParams();

    const extractCompsFromPDF = async event => {
        const files = event.target.files
        const formData = new FormData()
        formData.append('file', files[0])
        
        setIsAutomatedLoading(true)
        let data = await makeUploadRequest(property_api_extract_addresses_from_pdf_url, formData)

        //setIsAutomatedLoading(false)
        //event.target.files = null
        
        if (data) {
            const {addresses, aDate} = data
            if (addresses.length > 0) {
                // First Address = Subject
                // Remainder = Appraiser Comps
                if (aDate) {
                    setAppraisalDate(formateInputDate(aDate))
                }
                var subject = addresses[0]
                const {streetName, city, state, zip, fullAddress} = subject

                getGoogleAddress(fullAddress, async (written, address, parts) => {
                    let r = await handleSearchChange(written, address, parts)
                    if (r && r.success) {
                        const ac = []

                        let count = 0;
                        for (var index = 1; index < addresses.length; index++) {
                            var comp = addresses[index]
        
                            fetchAppraisalComp(comp, (resultComp) => {
                                count += 1
                                if (resultComp) {
                                    ac.push({ value: resultComp.label, lot: { propertyId: resultComp.propertyId } })
                                }
                                console.log(count, addresses.length)
                                if (count === addresses.length - 1) {
                                    ac.push({ value: '' })
                                    setAppraiserComps(ac)
                    
                                    setIsAutomatedLoading(false)
                                }
                            })
                        }
                    }
                })
            }
        } else {
            setIsAutomatedLoading(false)
        }

        event.target.files = null
      }

    function fetchAppraisalComp(comp, callback) {
        const {fullAddress} = comp
        getGoogleAddress(fullAddress, async (written, address, parts) => {
            var search = getParcelAddressQueryParams(written, address, parts)

            callback && callback(await getAddress(search))
        })   
    }

    useEffect(() => {
        if (!isAutomatedLoading && target) {
            onRunComps()
        }
    }, [isAutomatedLoading])

    useEffect(() => {
        let mounted = true
        if (mounted) {
            if (bbl || pid) {
                if (bbl) {
                    setPropertyToRun({
                        propertyId: bbl,
                        type: DataSetType.PLUTO
                    })
                } else {
                    setPropertyToRun({
                        propertyId: pid,
                        type: DataSetType.FIRST_AMERICAN
                    })
                }
                setStartComps(true)
            }
        }
        return () => mounted = false
    }, [])

    useEffect(() => {
        if (startComps) {
            onRunComps()
            setStartComps(false)
        }
    }, [startComps])

    useEffect(() => {
        if (gotComps && target) {
            runAppraiserComps()
            setGotComps(false)
        }
    }, [gotComps])

    useEffect(() => {
        if (!isEmpty(tdsChart)) {
            const { data } = tdsChart
            const { lowCalc, middleCalc, highCalc } = valuationData
            const buckets = [lowCalc?.bucket, middleCalc?.bucket, highCalc?.bucket]

            var b = buckets[condition - 1]
            
            const filteredData = data.filter(_ => _.bucket && _.bucket.length > 0);
            filteredData.forEach(_ => {
                _.color = '#d4d4d4'
                
                if (condition !== PropertyCondition.All) {
                    const isFinalBucketsValid = _.finalBuckets && _.finalBuckets.length > 0;
                    if (isFinalBucketsValid && _.finalBuckets.includes(b)) {
                        _.color = bucketColor(_.bucket)
                    }
                } else {
                    if (_.bucket) _.color = bucketColor(_.bucket) 
                }
            })

            setMarkerData(null)
            if (condition !== PropertyCondition.All) {
                
                const filteredComps = comps.filter(_ => {
                // Check if finalBuckets is not null or empty, and contains the value of b
                    const isFinalBucketsValid = _.finalBuckets && _.finalBuckets.length > 0;
                    return (isFinalBucketsValid && _.finalBuckets.includes(b));
                })
                setUrbynComps(filteredComps.sort((a, b) => a.ltds < b.ltds ? 1 : -1))
                
                addCompMarkersToMap(filteredComps)
            } else {
                setUrbynComps([])
                addCompMarkersToMap(comps)
            }

            setTDSChart(tdsChart => ({
                ...tdsChart,
                data: filteredData
            }))
        }
        selectBucket()
    }, [condition, valuationData])

    const selectBucket = () => {
        if (!valuationData || isEmpty(valuationData)) return 
        switch(condition) {
            case PropertyCondition.Excellent:
                setSelectedValuation(valuationData.highCalc)
                break
            case PropertyCondition.Fair:
                setSelectedValuation(valuationData.middleCalc)
                break
            case PropertyCondition.Poor:
                setSelectedValuation(valuationData.lowCalc)
                break
            default:
                setSelectedValuation(null)
        }
    }

    const handleAppraiserCompResultSelect = (index, result) => {
        const ac = [...appraiserComps]
        ac[index].isLoading = false
        ac[index].value = result.label
        ac[index].lot = { propertyId: result.propertyId }
        if ((ac.length - 1 < index + 1) && ac.length < 8) {
            ac.push({ value: '' })
        }
        setAppraiserComps(ac)
    }

    const setCompLotValue = async (index, written, address, parts) => {
        console.log(index,written, address, parts)

        if (address.length === 0) return

        const ac = [...appraiserComps]
        ac[index].value = value
        ac[index].isLoading = true
        setAppraiserComps(ac)

        var search = getParcelAddressQueryParams(written, address, parts)

        let result = await getAddress(search)
        
        handleAppraiserCompResultSelect(index, result)
    }

    const setPropertyToRun = (result) => {           
        setRefreshComps(true)
        setTarget(result)
        if (appraiserComps.length === 0) { setAppraiserComps([{value: ''}]) }
    }

    const setupLTDSChart = (comps, stdInfo, buckets) => {
        let data = comps.map(_ => {
            let color = ''
            if (_.bucket && _.bucket.length > 0) {
                if (buckets.includes(_.bucket)) {
                    if (condition !== PropertyCondition.All) {
                        if (_.bucket === buckets[condition - 1])
                            color = bucketColor(_.bucket)
                    } else {
                        color = bucketColor(_.bucket)
                    }
                }
                if (color.length === 0)
                    color = '#d4d4d4'
            } else {
                color = '#ececec'
            }
            return getTDSChartData(_, color)
        })

        data.sort((a, b) => a.x > b.x ? 1 : -1)

        let movingAverage = getChartMovingAverage(data, 0, 100, 10)

        //console.log(movingAverage)
        return { data: data, stdInfo, movingAverage }
    }

    const runAppraiserComps = async () => {
        let abbls = appraiserComps.filter(_ => _.lot).map(_ => _.lot.propertyId)
        if (abbls.length > 0) {
            let result = await runIndividualComps(target, abbls, {
                type: CompTypeOption.DEED,
                date: appraisalDate,
                radius: valuationData?.totalDistance,
                ds: target.type
             })
             if (result) {
                console.log(result)
                const {comps, bucket} = result
                let aComps = comps
                let tmpAppraiserComps = [...appraiserComps]
                let newComps = []
                tmpAppraiserComps.filter(_ => _.lot).forEach(a => {
                    let c = aComps.filter(_ => _.propertyId.toString() === a.lot.propertyId.toString())
                    if (c && c.length > 0) {
                        a.lot = c[0]
                        a.lot.bucket = CompBucketOption.APPRAISER
                        a.fetched = true

                        newComps.push(a.lot)
                    }
                })
                //console.log(tmpAppraiserComps)
                setAppraiserComps(tmpAppraiserComps)
                setAppraiserValuation(bucket)
                addAppraiserCompsToMap()

                let tmpData = []
                newComps.forEach(lot => {
                    tmpData.push(getTDSChartData(lot, bucketColor(lot.bucket), 'square'))
                })
                setAppraisalChartData(tmpData)
            }
        }
    }

    const onRunComps = () => {
        resetValues()
        setIsCompsLoading(true)
        runComps(target,
            {
                type: CompTypeOption.DEED,
                date: appraisalDate,
                ds: target.type,
                version: 10
            }, (compResults, canceled) => {
                setIsCompsLoading(false)
                setRefreshComps(false)
                if (!compResults || !compResults.compsData) return
                if (!canceled) {
                    const { compsData } = compResults || {}
                    const { buckets, comps, stats } = compsData || {}
                    const { valuationData, chartData } = stats || {}
                    const { finalBuckets } = buckets || {}
                    const { lowBucket, middleBucket, highBucket } = finalBuckets || {}
                    
                    let foundTarget = comps.filter(_ => _.propertyId.toString() === target.propertyId.toString())[0]

                    setComps(comps)

                    setValuationData(valuationData => ({
                        ...valuationData,
                        lowCalc: lowBucket,
                        middleCalc: middleBucket,
                        highCalc: highBucket,
                        totalDistance: valuationData?.totalDistance
                    }))

                    setTarget(foundTarget)
                    setValue(Lot.fullAddress(foundTarget))
                    setGotComps(true)

                    const bucketsArr = [lowBucket?.bucket, middleBucket?.bucket, highBucket?.bucket]

                    var b = bucketsArr[condition - 1]
                    
                    const tdsChart = setupLTDSChart(comps, chartData, bucketsArr)
                    if (condition !== PropertyCondition.All) {
                        if (tdsChart) {
                            const { data } = tdsChart
                            if (data) {
                                data.filter(_ => _.bucket && _.bucket.length > 0).forEach(_ => {
                                    _.color = '#d4d4d4'
                                    //if (buckets.includes(_.bucket)) _.color = bucketColor(_.bucket)
                                    const isFinalBucketsValid = _.finalBuckets && _.finalBuckets.length > 0;
                                    if (isFinalBucketsValid && _.finalBuckets.includes(b)) {
                                        _.color = bucketColor(_.bucket)
                                    }
                                })
                            }
                        }
                    }


                    setTDSChart(tdsChart)

                    if (condition !== PropertyCondition.All) {
                        const filteredComps = comps.filter(_ => {
                            // Check if finalBuckets is not null or empty, and contains the value of b
                            const isFinalBucketsValid = _.finalBuckets && _.finalBuckets.length > 0;
                            return (isFinalBucketsValid && _.finalBuckets.includes(b));
                          })
                          
                        setUrbynComps(filteredComps.sort((a, b) => a.ltds < b.ltds ? 1 : -1))
                        addCompMarkersToMap(filteredComps)
                    } else {
                        addCompMarkersToMap(comps)
                    }                    
                }
            })
    }

    const resetValues = () => {
        setTarget(null)
        setValuationData(null)
        setSelectedValuation(null)
        setTDSChart(null)
        setComps([])
        setUrbynComps([])

        setMarkerData([])
        setAppraisalChartData([])
    }

    const onClearComps = () => {
        setValue('')

        setCondition(PropertyCondition.All)
        setAppraisalDate(today())

        resetValues()
        setAppraiserComps([])
    }

    const addAppraiserCompsToMap = (data = null) => {
        if (!data) data = markerData
        let comps = appraiserComps.filter(_ => _.fetched).map(_ => _.lot)
        if (comps.length > 0) {
            let oldBBLs = data.filter(_ => _.bucket === CompBucketOption.APPRAISER).map(_ => _.propertyId)
            setMarkerData([
                ...data,
                ...comps.filter(_ => !oldBBLs.includes(_.propertyId))
            ])
        }
    }

    const addCompMarkersToMap = (comps) => {
        comps = comps.filter(_ => {
            const isFinalBucketsValid = _.finalBuckets && _.finalBuckets.length > 0;
            return isFinalBucketsValid
        });

        setMarkerData(comps)
        addAppraiserCompsToMap(comps)
    }
    
    const setupFullPage = (comps) => {
        setFullPage(true)
        setFullPageComps(comps)
        setTimeout(() => {
            printMe()
        }, 250);
    }

    const printMe = () => {
        window.print()
    }

    const closeFullPage = () => {
        setFullPageComps(null)
        setFullPage(false)
    }

    const handleSearchChange = async (written, address, parts) => {
        console.log(address, parts)

        if (address.length === 0) return

        setIsLoading(true)

        var search = getParcelAddressQueryParams(written, address, parts)

        let result = await getAddress(search)

        setPropertyToRun(result)
        return {success: true, isPluto: Lot.isPluto(result)}
  };

  const getAddress = async (params) => {
    let result = await makeGetRequest(`${parcel_search_url}?${params}`)

    setIsLoading(false)
    
    if (!result) {
        alert('No Address Found')
        return
    }
        
    return result
  }

    if (fullPage) return <div className='resp-container'>
        <Segment basic style={{ padding: '4em 2em 2em' }}>
            <Button size='tiny' className='no-print' icon='x' onClick={closeFullPage} />
            <Button floated='right' size='tiny' className='no-print' icon='file pdf' onClick={printMe} />
            <ComparablesTable lots={fullPageComps} />
        </Segment>
    </div>

    return (
        <div className='resp-container'>
            <Segment basic style={{ minHeight: '100vh', background: '#f8f8f8', padding: '4em 2em 2em' }}>
                <Form size='small'>
                    <Form.Group>
                        <Form.Field className='comp-inline-field' inline>
                            <label>Subject Property</label>
                            <SearchLocationInput 
                                className="fluid" 
                                name=''
                                label=''
                                icon='home'
                                value={value}
                                placeholder='Enter property address'
                                onChange={handleSearchChange} 
                                isLoading={isLoading} />
                        </Form.Field>
                        <Form.Select
                            options={propertyConditionOptions}
                            placeholder='Select Condition'
                            value={condition}
                            onChange={(e, { value }) => setCondition(value)}
                        //disabled={disabled}
                        />
                        <Form.Input
                            type='date'
                            value={appraisalDate}
                            onChange={(e, { value }) => {
                                setRefreshComps(true)
                                setAppraisalDate(value)
                            }}
                        />
                        <Form.Input
                            style={{display: 'none'}}
                            type='file'
                            name="upload"
                            id="upload"
                            onChange={extractCompsFromPDF} 
                        />
                        <label className='ui icon button' htmlFor='upload'>
                            <Icon name='upload' />
                        </label>
                        <Form.Field><Button icon='x' onClick={onClearComps}></Button></Form.Field>
                    </Form.Group>
                </Form>
                <Divider />
                <Button.Group fluid>
                    {valuationData && !refreshComps ? <Button disabled={!target || isEmpty(target)} positive onClick={runAppraiserComps}>Fetch Appraisals</Button> :
                     (<Button disabled={!target || isEmpty(target)} positive onClick={onRunComps}>Run Comps</Button>)}
                </Button.Group>
                <Segment basic className='no-padding-h'>
                    <Grid columns='equal'>
                        <Grid.Row>
                        <Grid.Column>
                                <MapboxMap
                                    className='appraisals-map-container no-padding-v'
                                    target={target}
                                    markerData={markerData}
                                    totalDistance={valuationData?.totalDistance || 1}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                {urbynComps && urbynComps.length > 0 && urbynComps.slice(0, 4).map((lot, index) => <UrbynLotField key={index} index={index} lot={lot}
                                    //removeHandler={(index) => removeFromComps(index)}
                                />)}
                            </Grid.Column>
                            <Grid.Column>
                                {appraiserComps && appraiserComps.length > 0 && (
                                    <Form size='small'>
                                        {appraiserComps.map((lot, index) => <CompLotField
                                            key={index}
                                            index={index}
                                            compLot={lot}
                                            wrongUnit={lot.fetched && ((target?.unitCode === 1 || target?.unitCode === 2) && lot?.lot?.unitCode !== target?.unitCode)}
                                            //handleResultSelect={(e, { result }) => handleAppraiserCompResultSelect(index, e, result)}
                                            //handleSearchChange={(e, { value }) => setCompLotValue(index, value)} 
                                            handleSearchChange={(written, address, parts) => setCompLotValue(index, written, address, parts)}
                                            />)
                                        }
                                    </Form>
                                )}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Grid columns='equal'>
                    <Grid.Row>
                        <Grid.Column>
                            <ValuationBucketContainer 
                                header={selectedValuation ? selectedValuation.bucket : 'Valuation'}
                                calc={selectedValuation}
                            />
                        </Grid.Column> 
                        {appraiserValuation ? (
                            <Grid.Column>
                            <ValuationBucketContainer 
                                header={'Manual Selection'}
                                calc={appraiserValuation}
                            />
                        </Grid.Column>
                        ) : null}
                    </Grid.Row>
                </Grid>
                <Segment style={{ height: '50vh', flex: 'auto' }} stacked>
                    {tdsChart && <LTDSChart
                        chartData={tdsChart}
                        extraData={appraisalChartData}
                        cHeight={400}
                        cWidth={1000}
                        scatterSize={4}
                    />}
                </Segment>
                <Dimmer page active={isCompsLoading || isAutomatedLoading}>
                    <Loader />
                </Dimmer>
                <Sidebar
                    className='comparables-side-panel-container side-panel-container'
                    as={Segment}
                    animation='overlay'
                    direction='right'
                    width='very wide'
                    visible={rightSidebarVisible}
                >
                    <Divider hidden />
                    <Divider hidden />
                    <Label className='panel-closer left' style={{ top: '4.5em' }} basic icon={`long arrow alternate ${rightSidebarVisible ? 'right' : 'left'}`} floating
                        onClick={() => {
                            setRightSidebarVisible(sidebarVisible => !sidebarVisible)
                            setLeftSidebarVisible(false)
                        }}
                    />
                    {target && valuationData &&
                        <Button size='mini' floated='right'
                        onClick={() => {
                            setupFullPage([target, ...urbynComps])
                        }} content='PDF'
                        />
                    }
                    <Grid id="grid-comparables" className='side-panel-grid' style={{ height: '100%', padding: '40px 0', flexGrow: '2' }}>
                        <Grid.Row columns={1} style={{ height: '100%' }}>
                            <Grid.Column stretched style={{ height: '100%' }}>
                                {target && valuationData &&
                                    <ComparablesTable lots={[target, ...urbynComps]} />
                                }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Sidebar>


                <Sidebar
                    className='comparables-side-panel-container side-panel-container'
                    as={Segment}
                    animation='overlay'
                    direction='left'
                    width='very wide'
                    visible={leftSidebarVisible}
                >
                    <Divider hidden />
                    <Divider hidden />
                    <Label className='panel-closer right' style={{ top: '4.5em' }} basic icon={`long arrow alternate ${leftSidebarVisible ? 'left' : 'right'}`} floating
                        onClick={() => {
                            setLeftSidebarVisible(sidebarVisible => !sidebarVisible)
                            setRightSidebarVisible(false)
                        }}
                    />
                    {target && valuationData &&
                        <Button size='mini' floated='left'
                        onClick={() => {
                            setupFullPage([target, ...appraiserComps.filter(_ => _.lot && _.fetched).map(_ => _.lot).sort((a, b) => a.ltds > b.ltds ? -1 : 1)])
                        }} content='PDF'
                        />
                    }
                    <Grid className='side-panel-grid' style={{ height: '100%', padding: '40px 0', flexGrow: '2' }}>
                        <Grid.Row columns={1} style={{ height: '100%' }}>
                            <Grid.Column stretched style={{ height: '100%' }}>
                                {target && valuationData &&
                                    <ComparablesTable lots={[target, ...appraiserComps.filter(_ => _.lot && _.fetched).map(_ => _.lot).sort((a, b) => a.ltds > b.ltds ? -1 : 1)]} />
                                }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Sidebar>

            </Segment>
        </div>
    )
}

export default CompAppraisals