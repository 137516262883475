import React, { Component } from 'react';
import moment from 'moment';

import PerfectScrollbar from 'react-perfect-scrollbar'

import {
    Divider,
    Accordion,
    Input,
    Label,
    Button,
    Segment,
    Header,
    Select,
    Dropdown,
    Checkbox
} from 'semantic-ui-react'

import {boroOptions, cityOptions, neighborhoodOptions, searchFilters, stateOptions} from '../utility/Global'
import { getParcelAddressQueryParams, getRadius } from '../utility/Helper';
import SearchLocationInput from './extras/SearchLocationInput';
import { parcel_search_url } from '../utility/Config';
import { makeGetRequest } from '../utility/Services';

function isPluto(type) {
  return type === 'pluto'
}

function isFA(type) {
  return type === 'fa'
}

const InputRangeFilterGroup = ({label, inputType = 'number', inputName, inputValue, onChange}) => (
    <>
    {label ? (
      <Label className='no-border' basic>{label}</Label>
    ) : null }
    <Segment className='filter-input-group' basic compact vertical style={{margin:'auto'}}>
        <Input name={`${inputName}.min`} size='mini' type={inputType} placeholder='Min' value={inputValue?.min ?? ''} onChange={onChange}/>
        &nbsp;
        <em style={{padding: '0 0.25em'}}>to</em>
        &nbsp;
        <Input name={`${inputName}.max`} size='mini' type={inputType} placeholder='Max' value={inputValue?.max ?? ''} onChange={onChange}/>
    </Segment>
    </>
  )


  function getSaleDate(name) {
    return moment().subtract(parseInt(name), 'months').format('YYYY-MM-DD')
  }


export class CompsFilter extends Component {
    // state = {
    //     radius: 'three-quarter',
    //     saleDate: '12'
    // }
    state = {
      
    }
    handleCompsSearch = this.props.handleCompsSearch
    applyFilters = this.props.applyFilters

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps !== prevState) {
          return ({...nextProps})
        }
      }

      handleRadiusChange = (e, {name} = e.target) => {
        //const { radius } = this.state
        const radius = searchFilters.radiusName
        
        if (radius !== name) {
          searchFilters.radiusName = name
          //this.setState({radius: name})
    
          if (name !== 'other') {
            searchFilters.radius = getRadius(name)
    
            this.handleCompsSearch()
          } else {
            this.forceUpdate()
          }
        }
      }
    
      handleDateChange = (e, {name} = e.target) => {
        //const { saleDate } = this.state
        const saleDate = searchFilters.saleDateName
        
        if (saleDate !== name) {
          searchFilters.saleDateName = name
          //this.setState({saleDate: name})
    
          if (name !== 'other') {
            searchFilters.date.min = getSaleDate(name)
    
            this.handleCompsSearch()
          } else {
            this.forceUpdate()
          }
        }
      }

      handleSqftChange = (e, {name} = e.target) => {
        const sqft = searchFilters.relativeSqft
        
        if (sqft !== name) {
          searchFilters.relativeSqftName = name
    
          if (name !== 'other') {
            searchFilters.relativeSqft = parseInt(name)
    
            this.handleCompsSearch()
          } else {
            this.forceUpdate()
          }
        }
      }

      handleFilterChange = (e, {name, value}) => {
        if (name.includes('.')) {
          var parts = name.split('.')
          searchFilters[parts[0]][parts[1]] = value
        } else {
          searchFilters[name] = value
        }

        this.forceUpdate()
      }    

      render() {
          const radius = searchFilters.radiusName
          const saleDate = searchFilters.saleDateName
          const sqft = searchFilters.relativeSqftName

          return (
              <>
            <div style={{height: '100%'}}>
            <div>
            <section>
            <Label className='no-border' basic>Distance</Label><em>in miles</em>
           <Button.Group onClick={this.handleRadiusChange.bind(this)} basic widths='5' fluid toggle size='mini'>
              <Button name='quarter' active={radius === 'quarter'}>0.25</Button>
              <Button name='half' active={radius === 'half'}>0.5</Button>
              <Button name='three-quarter' active={radius === 'three-quarter'}>0.75</Button>
              <Button name='mile' active={radius === 'mile'}>1</Button>
              <Button name='other' active={radius === 'other'}>Other</Button>
            </Button.Group>

            <Accordion styled className='no-border'>
              <Accordion.Title active={radius === 'other'} index={0} className='hide-me'></Accordion.Title>
              <Accordion.Content active={radius === 'other'} style={{padding:'0.75em 1em 0'}}>
                <Input name='radius' fluid size='mini' placeholder='Custom Radius in Miles' onChange={this.handleFilterChange.bind(this)} /*onKeyDown={(e) => {e.key === 'Enter' ? this.handleCompsSearch() : null}}*//>
              </Accordion.Content>
            </Accordion>

            <Divider />

            <Label className='no-border' basic>Sale Date</Label><em>in months</em>
            <Button.Group onClick={this.handleDateChange.bind(this)} basic widths='5' fluid toggle size='mini'>
              <Button name='6' active={saleDate === '6'}>6</Button>
              <Button name='12' active={saleDate === '12'}>12</Button>
              <Button name='18' active={saleDate === '18'}>18</Button>
              <Button name='24' active={saleDate === '24'}>24</Button>
              <Button name='other' active={saleDate === 'other'}>Other</Button>
            </Button.Group>

            <Accordion styled className='no-border'>
              <Accordion.Title active={saleDate === 'other'} index={0} className='hide-me'></Accordion.Title>
              <Accordion.Content active={saleDate === 'other'} style={{padding:'0.75em 1em 0', marginBottom: '-1em'}}>
              <InputRangeFilterGroup 
                inputType='date'
                inputName='date'
                inputValue={searchFilters.date}
                onChange={this.handleFilterChange.bind(this)}
              />
              </Accordion.Content>
            </Accordion>

            <Divider />

            <Label className='no-border' basic>Sqft Bracket</Label>
            <Button.Group onClick={this.handleSqftChange.bind(this)} basic widths='5' fluid toggle size='mini'>
              <Button name='10' active={sqft === '10'}>10%</Button>
              <Button name='20' active={sqft === '20'}>20%</Button>
              <Button name='30' active={sqft === '30'}>30%</Button>
              <Button name='other' active={sqft === 'other'}>Other</Button>
            </Button.Group>
  
            <Accordion styled className='no-border'>
              <Accordion.Title active={sqft === 'other'} index={0} className='hide-me'></Accordion.Title>
              <Accordion.Content active={sqft === 'other'} style={{padding:'0.75em 1em 0'}}>
                <Input name='relativeSqft' fluid size='mini' placeholder='Custom SQFT' onChange={this.handleFilterChange.bind(this)} /*onKeyDown={(e) => {e.key === 'Enter' ? this.handleCompsSearch() : null}}*//>
              </Accordion.Content>
            </Accordion>

            <Divider />
            </section>

            <PerfectScrollbar className='comps-filter-scrollable'>
            <div>
            <section>

            <InputRangeFilterGroup 
              label='Residential Units'
              inputName='residential'
              inputValue={searchFilters.residential}
              onChange={this.handleFilterChange.bind(this)}
            />

            <InputRangeFilterGroup 
              label='Commercial Units'
              inputName='commercial'
              inputValue={searchFilters.commercial}
              onChange={this.handleFilterChange.bind(this)}
            />

            <InputRangeFilterGroup 
              label='Price Range'
              inputName='salePrice'
              inputValue={searchFilters.salePrice}
              onChange={this.handleFilterChange.bind(this)}
            />
            
            <InputRangeFilterGroup 
              label='Stories'
              inputName='stories'
              inputValue={searchFilters.stories}
              onChange={this.handleFilterChange.bind(this)}
            />

            <InputRangeFilterGroup 
              label='Square Feet'
              inputName='sqft'
              inputValue={searchFilters.sqft}
              onChange={this.handleFilterChange.bind(this)}
            />

            <InputRangeFilterGroup 
              label='Building Width'
              inputName='width'
              inputValue={searchFilters.width}
              onChange={this.handleFilterChange.bind(this)}
            />

            <InputRangeFilterGroup 
              label='Building Depth'
              inputName='depth'
              inputValue={searchFilters.depth}
              onChange={this.handleFilterChange.bind(this)}
            />

            <InputRangeFilterGroup 
              label='Lot Square Feet'
              inputName='lotSqft'
              inputValue={searchFilters.lotSqft}
              onChange={this.handleFilterChange.bind(this)}
            />

            <InputRangeFilterGroup 
              label='Lot Width'
              inputName='lotWidth'
              inputValue={searchFilters.lotWidth}
              onChange={this.handleFilterChange.bind(this)}
            />

            <InputRangeFilterGroup 
              label='Lot Depth'
              inputName='lotDepth'
              inputValue={searchFilters.lotDepth}
              onChange={this.handleFilterChange.bind(this)}
            />
            </section>
            </div>
            </PerfectScrollbar>
            
            </div>
            </div>
            <section style={{position: 'relative', maxHeight: '32px', marginTop: 'auto'}}>
                <div style={{position: 'absolute', bottom: '0', width: '100%', height: '32px'}}><Button positive fluid onClick={this.applyFilters}>Apply Changes</Button></div>
            </section>
            </>
          )
      }
}

export class ProspectingFilterPanel extends Component {
  state = {
    filters: {
      borrowerName: '',
      sellerName: '',
      lenderName: '',
      neighborhood: '',
      radius: 1,
      borocode: 3,
      address: {
        label: '',
        propertyId: ''
      },
      city: '',
      state: '',
      deedDate: {
        min: '',
        max: ''
      },
      deedPrice: {
        min: null,
        max: null,
      },
      mortgageDate: {
        min: '',
        max: ''
      },
      mortgagePrice: {
        min: null,
        max: null,
      },
      isMortgageEntity: false,
      sqft: {
        min: null,
        max: null
      },
      stories: {
        min: null,
        max: null
      },
      width: {
        min: null,
        max: null
      },
      depth: {
        min: null,
        max: null
      },
      residential: {
        min: 1,
        max: 1
      },
      commercial: {
        min: 0,
        max: 0
      },
      lotSqft: {
        min: null,
        max: null
      },
      lotDepth: {
        min: null,
        max: null
      },
      lotWidth: {
        min: null,
        max: null
      }
    },
    dataset: 'pluto',
    prospectingType: 'prospects',
    regionMode: 'region' // 'region' / 'address'
  }
  onProspectSearch = this.props.onProspectSearch

  componentDidMount() {
    const {filters} = this.state
    this.setState({filters: {
      ...filters,
      state: 'NY'
    }})
  }

  handleFilterChange = (e, {name, value, type, checked} = e.target) => {
    const {filters} = this.state
    if (type === 'checkbox') {
      filters[name] = checked
    } else {
      if (name.includes('.')) {
        var parts = name.split('.')
        filters[parts[0]][parts[1]] = value
      } else {
        filters[name] = value
      }
  
      if (name === 'borocode') {
        filters['neighborhood'] = ''
      } 

      if (name === 'state') {
        filters['city'] = ''
      }
    }

    this.setState({filters})
  }   

  handleDeedSection = (e) => {
    const {isDeed} = this.state
    this.setState({isDeed:!isDeed})
  }

  handleMortgageSection = (e) => {
    const {isMortgage} = this.state
    this.setState({isMortgage:!isMortgage})
  }

  isTypeProspects = () => {
    const {prospectingType} = this.state
    return prospectingType === 'prospects'
  }

  isTypeLenders = () => {
    const {prospectingType} = this.state
    return prospectingType === 'lenders'
  }

  isTypePluto = () => {
    const {dataset} = this.state
    return isPluto(dataset)
  }

  isTypeFA = () => {
    const {dataset} = this.state
    return isFA(dataset)
  }

  isTypeAddress = () => {
    const {regionMode} = this.state
    return regionMode === 'address'
  }

  isTypeRegion = () => {
    const {regionMode} = this.state
    return regionMode === 'region'
  }

  handleSearchChange = async (written, address, parts) => {
    console.log(address, parts)

    if (address.length === 0) return

    //setIsLoading(true)

    var search = getParcelAddressQueryParams(written, address, parts)

    let result = await this.getAddress(search)

    console.log(result)
    
    const {filters} = this.state
    filters.address = result
    this.setState({filters})
  };

  getAddress = async (params) => {
    let result = await makeGetRequest(`${parcel_search_url}?${params}`)

    //setIsLoading(false)

    if (!result) {
        alert('No Address Found')
        return
    }
        
    return result
  }

  render() {
    const {dataset, prospectingType, regionMode, filters, isDeed, isMortgage} = this.state
    return (
      <>
              <div style={{height: '100%'}}>
              <div>
              <Header>Advanced Search</Header>
              <Button.Group size='mini'>
                <Button positive={this.isTypeProspects()} onClick={() => {this.setState({prospectingType: 'prospects'})}}>Prospects</Button>
                <Button positive={this.isTypeLenders()} onClick={() => {this.setState({prospectingType: 'lenders'})}}>Lender Prospects</Button>
              </Button.Group>
              <Divider />
              <Button.Group size='mini'>
                <Button positive={this.isTypePluto()} onClick={() => {this.setState({dataset: 'pluto'})}}>Pluto</Button>
                <Button positive={this.isTypeFA()} onClick={() => {this.setState({dataset: 'fa'})}}>FA</Button>
              </Button.Group>
              <Divider />
              <Button.Group size='mini'>
                <Button positive={this.isTypeRegion()} onClick={() => {this.setState({regionMode: 'region'})}}>Region</Button>
                <Button positive={this.isTypeAddress()} onClick={() => {this.setState({regionMode: 'address'})}}>Address</Button>
              </Button.Group>
              <Divider />
              {this.isTypeRegion() ? (
                <>
                {this.isTypePluto() ? (
                <>
                <Label className='no-border' basic>Borough</Label>
                <Select className='mini' name='borocode' fluid placeholder='Borough' value={filters.borocode} onChange={this.handleFilterChange.bind(this)} 
                  options={boroOptions} />

                <Label className='no-border' basic>Neighborhood</Label>
                <Dropdown
                  name='neighborhood'
                  className='mini'
                  placeholder='Select Neighborhood'
                  fluid
                  search
                  selection
                  clearable
                  options={neighborhoodOptions(filters.borocode)}
                  onChange={this.handleFilterChange.bind(this)}
                  value={filters.neighborhood}
                />
                </>
              ) : (
                <>
                <Label className='no-border' basic>State</Label>
                <Select className='mini' name='state' fluid placeholder='State' value={filters.state} onChange={this.handleFilterChange.bind(this)} 
                  options={stateOptions} />

                  {filters.state && (
                    <>
                    <Label className='no-border' basic>City</Label>
                    <Dropdown
                      name='city'
                      className='mini'
                      placeholder='Select City'
                      fluid
                      search
                      selection
                      options={cityOptions(filters.state)}
                      onChange={this.handleFilterChange.bind(this)}
                      value={filters.city}
                      clearable={true}
                    />
                    </>
                  )}
                </>
              )}
                </>
              ) : (
                <>
                <Label className='no-border' basic>Property Address</Label>
                <SearchLocationInput 
                    className="fluid" 
                    name=''
                    label=''
                    icon='home'
                    value={filters?.address?.label}
                    placeholder='Enter property address'
                    onChange={this.handleSearchChange.bind(this)} 
                    //isLoading={isLoading} 
                    />
                <Label className='no-border' style={{marginTop: '1rem'}} basic>Radius</Label>
                <Input name='radius' size='mini' type='number' placeholder='Radius' value={filters.radius} onChange={this.handleFilterChange.bind(this)}/>
                </>
              )}
              
              <Divider />
             {/* <Input name='neighborhood' fluid size='mini' placeholder='Neighborhood' value={filters.neighborhood} onChange={this.handleFilterChange.bind(this)} /*onKeyDown={(e) => {e.key === 'Enter' ? this.handleCompsSearch() : null}}> */}
              <PerfectScrollbar className='prospecting-filter-scrollable'>
              <div>
              <section>
  
              <Accordion styled className='no-border'>
                <Accordion.Title active={isDeed} index={0} onClick={this.handleDeedSection}>Sale</Accordion.Title>
                <Accordion.Content active={isDeed} style={{padding:'0.75em 1em 0', marginBottom: '-1em'}}>
                <Segment basic className='no-padding'>
                <Input name='borrowerName' size='mini' type='text' placeholder='Buyer' value={filters.borrowerName} onChange={this.handleFilterChange.bind(this)}/>
                &nbsp;
                <Input name='sellerName' size='mini' type='text' placeholder='Seller' value={filters.sellerName} onChange={this.handleFilterChange.bind(this)}/>
                </Segment>
                <InputRangeFilterGroup 
                  inputType='date'
                  inputName='deedDate'
                  inputValue={filters.deedDate}
                  onChange={this.handleFilterChange.bind(this)}
                />
                <InputRangeFilterGroup 
                  label='Sale Range'
                  inputName='deedPrice'
                  inputValue={filters.deedPrice}
                  onChange={this.handleFilterChange.bind(this)}
                />
                </Accordion.Content>
              </Accordion>

              <Divider />

              <Accordion style={{marginBottom: '2em'}} styled className='no-border'>
                <Accordion.Title active={isMortgage} index={0} onClick={this.handleMortgageSection}>Loan</Accordion.Title>
                <Accordion.Content active={isMortgage} style={{padding:'0.75em 1em 0', marginBottom: '-1em'}}>
                <Segment basic className='no-padding'>
                <Input name='lenderName' size='mini' type='text' placeholder='Lender' value={filters.lenderName} onChange={this.handleFilterChange.bind(this)}/>
                </Segment>
                <InputRangeFilterGroup 
                  inputType='date'
                  inputName='mortgageDate'
                  inputValue={filters.mortgageDate}
                  onChange={this.handleFilterChange.bind(this)}
                />
                <InputRangeFilterGroup 
                  label='Loan Range'
                  inputName='mortgagePrice'
                  inputValue={filters.mortgagePrice}
                  onChange={this.handleFilterChange.bind(this)}
                />
                {/* <Checkbox name='isMortgageEntity' label='Is Entity' onChange={this.handleFilterChange.bind(this)} checked={filters.isMortgageEntity}/> */}
                </Accordion.Content>
              </Accordion>

            <Divider />

              <InputRangeFilterGroup 
                label='Residential Units'
                inputName='residential'
                inputValue={filters.residential}
                onChange={this.handleFilterChange.bind(this)}
              />
  
              <InputRangeFilterGroup 
                label='Commercial Units'
                inputName='commercial'
                inputValue={filters.commercial}
                onChange={this.handleFilterChange.bind(this)}
              />
              
              <InputRangeFilterGroup 
                label='Stories'
                inputName='stories'
                inputValue={filters.stories}
                onChange={this.handleFilterChange.bind(this)}
              />
  
              <InputRangeFilterGroup 
                label='Square Feet'
                inputName='sqft'
                inputValue={filters.sqft}
                onChange={this.handleFilterChange.bind(this)}
              />
  
                {this.isTypePluto() && (
                  <>
                  <InputRangeFilterGroup 
                    label='Building Width'
                    inputName='width'
                    inputValue={filters.width}
                    onChange={this.handleFilterChange.bind(this)}
                  />
      
                  <InputRangeFilterGroup 
                    label='Building Depth'
                    inputName='depth'
                    inputValue={filters.depth}
                    onChange={this.handleFilterChange.bind(this)}
                  />
                  </>
                )}
  
              <InputRangeFilterGroup 
                label='Lot Square Feet'
                inputName='lotSqft'
                inputValue={filters.lotSqft}
                onChange={this.handleFilterChange.bind(this)}
              />
  
              <InputRangeFilterGroup 
                label='Lot Width'
                inputName='lotWidth'
                inputValue={filters.lotWidth}
                onChange={this.handleFilterChange.bind(this)}
              />
  
              <InputRangeFilterGroup 
                label='Lot Depth'
                inputName='lotDepth'
                inputValue={filters.lotDepth}
                onChange={this.handleFilterChange.bind(this)}
              />
              </section>
              </div>
              </PerfectScrollbar>
              
              </div>
              </div>
              <section style={{position: 'relative', maxHeight: '32px', marginTop: 'auto'}}>
                  <div style={{position: 'absolute', bottom: '0', width: '100%', height: '32px'}}><Button positive fluid onClick={() => {this.onProspectSearch(prospectingType, filters, dataset, regionMode, this)}}>Search</Button></div>
              </section>
              </>
    )
  }
}