import React from 'react';
import { Container } from 'semantic-ui-react';
import NavMenu from './NavMenu';

function Layout({children, user}) {
  return (
    <div>
      <Container fluid>
        <NavMenu isFluid={true} user={user}/>
          {children}
        </Container>
    </div>
  );
}

export default Layout