import React from "react"
import { VictoryPie, VictoryTheme } from 'victory';

const PieChart = ({ chartData }) => {
    return (
        <VictoryPie
            theme={VictoryTheme.material}
            data={chartData}
            style={{
                labels: { fill: "white", fontSize: 10, fontWeight: "bold" },
                data: {
                    fill: ({ datum }) => datum.color,
                    //stroke: "black",
                    //strokeWidth: 1
                }
            }}
            labelRadius={({ innerRadius }) => innerRadius + 25}
            innerRadius={0}
            labelPosition='centroid'
            labelPlacement='vertical' 
        />
    )
}

export default React.memo(PieChart)