import moment from 'moment';
import { createContainer } from 'victory';
import { mean } from '../../utility/MathHelper';

export function getChartMovingAverage(data, min = null, max = null, groupInterval = 10) {
    let tmp = []
    if (data.length > 0) {
        let lowest = min ? min : data[0].x
        let highest = max ? max : data[data.length - 1].x

        let interval = (highest - lowest) / groupInterval
        let bottom = lowest
        for (var x = 0; x < groupInterval; x++) {
            let top = bottom + interval
            let values = data.filter(_ => (_.x >= bottom && _.x < top) ** _.y).map(_ => _.y)

            if (values.length === 0) {
                if (groupInterval <= 0) return []
                return getChartMovingAverage(data, min, max, groupInterval - 2)
            }

            values = values.filter(_ => _ !== null)
            let avg = values.length > 0 ? mean(values) : 0

            let h = mean([bottom, top])

            tmp.push({ x: h, y: avg })
            bottom += interval
        }
    }

    return tmp
}

export function getValuesbetween(data, min, max) {
    return data?.filter(_ => _.x > min && _.x < max).map(_ => _.y)
}

export function symbolForUnit(unit) {
    switch (unit) {
        case 1:
            return 'circle'
        case 2:
            return 'diamond'
        case 3:
            return 'triangleUp'
        case 4:
            return 'square'
    }
}

let months = []
for (var d = moment().subtract(2, 'years').toDate(); d <= new Date(); d.setMonth(d.getMonth() + 1)) {
    months.push(moment(d).format('YYYY-MM'));
}

let colors = ['#CADEED', '#B7D0E1', '#91AEC4', '#5F84A2', '#194569', '#000000']

let clusterColors = ['#DB0101', '#01DB46', '#0E01DB', '#B701DB', '#01DBBA', '#000000', '#DB7B01', '#D66DC9', '#EABA42']

const VictoryZoomVoronoiContainer = createContainer("zoom", "voronoi");
const VictoryBrushVoronoiContainer = createContainer("brush", "voronoi");
const VictoryBrushZoomContainer = createContainer("brush", "zoom");
