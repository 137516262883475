import React, { useEffect, useState } from 'react';

import { Button, Form, Grid, Segment, Divider, Table, Dimmer, Loader, Label, Select, Sidebar } from 'semantic-ui-react'
import { property_api_rents_url, property_api_annual_tax_url, property_api_lot_latest_prices, acris_document_viewer_url, parcel_search_url } from '../../utility/Config';
import { getInterestRates, makeGetRequest, makePostRequest } from '../../utility/Services';
import { PSF_PRICE_LABEL, runComps, bucketColor } from '../Comps';
import { getChartMovingAverage } from '../charts/ChartHelper';
import { calculatePayment, calculatePmt2Loan, roundToNearest, formatNumber, onlyUnique, formatDate, roundToFloorByNearest, getParcelAddressQueryParams } from '../../utility/Helper';
import { CompTypeOption, DataSetType } from '../../utility/Global';
import LTDSChart, { getTDSChartData } from '../charts/LTDSChart';
import ComparablesTable from '../tables/Comparables';

import rent_figures from '../../assets/json/rent_figures.json'
import section_eight_figures from '../../assets/json/section_eight_figures.json'
import { InformationRow, LotInformationPanel } from '../lots/LotInformationPanel';
import GoogleMap from '../maps/GoogleMap';
import MapboxMap from '../maps/MapboxMap';
import { ValueContainer } from '../extras/ValueContainer';
import { FormattedInputType } from '../extras/InputElements';
import { useParams } from 'react-router-dom';
import Lot from '../../Tax-Lot';
import BucketCalc from '../../BucketCalc';
import SearchLocationInput from '../extras/SearchLocationInput';
import { mean, min, max } from '../../utility/MathHelper';

rent_figures.sort((a, b) => a.value < b.value ? 1 : -1)

const LoadState = {
    NONE: 0,
    SALES: 1,
    FINANCING: 2,
    RENTALS: 3,
    LISTINGS: 4,
    FINISHING: 5,
    FINISHED: 6
}

function getRentForRooms(rooms) {
    const rates = section_eight_figures.filter(_ => _.bedrooms === rooms)
    if (rates.length === 0)
        return 0
    return rates[0].value
}

function getRentalRateUnitModifier(unit) {
    switch (unit) {
        case 1:
            return -.2
        case 2:
            return -.1
        case 3:
            return .1
        case 4:
            return .2
        default:
            return 0
    }
}

function getRentalRate(value, unit) {
    let modifier = getRentalRateUnitModifier(unit)
    let rate = 0
    rent_figures.every(element => {
        if (value >= element.value) {
            rate = element.rate
            return false
        }
        return true
    })

    let result = ((value * rate) / 12) * (1 + modifier)
    return isNaN(result) ? 0 : result
}

const PropertyCondition = {
    Poor: 1,
    Fair: 2,
    Excellent: 3
}

const propertyConditionOptions = [
    { key: PropertyCondition.Excellent, text: 'Excellent', value: PropertyCondition.Excellent },
    { key: PropertyCondition.Fair, text: 'Fair', value: PropertyCondition.Fair },
    { key: PropertyCondition.Poor, text: 'Poor', value: PropertyCondition.Poor },
]

const ltvOptions = [
    { key: 50, text: '50%', value: 50 },
    { key: 55, text: '55%', value: 55 },
    { key: 60, text: '60%', value: 60 },
    { key: 65, text: '65%', value: 65 },
    { key: 70, text: '70%', value: 70 },
    { key: 75, text: '75%', value: 75 },
]

const dscrOptions = [
    { key: 1.0, text: '1.0', value: 1.0 },
    { key: 1.05, text: '1.05', value: 1.05 },
    { key: 1.1, text: '1.10', value: 1.1 },
    { key: 1.15, text: '1.15', value: 1.15 },
    { key: 1.2, text: '1.20', value: 1.2 },
    { key: 1.25, text: '1.25', value: 1.25 },
]

const termOptions = [
    { key: 10, text: '10 Yrs', value: 10 },
    { key: 15, text: '15 Yrs', value: 15 },
    { key: 20, text: '20 Yrs', value: 20 },
    { key: 25, text: '25 Yrs', value: 25 },
    { key: 30, text: '30 Yrs', value: 30 },
    { key: 40, text: '40 Yrs', value: 40 },
]

const interestRateOptions = [
    { key: 5.5, text: '5.500%', value: 5.5 },
    { key: 5.75, text: '5.750%', value: 5.75 },
    { key: 6, text: '6.000%', value: 6 },
    { key: 6.25, text: '6.250%', value: 6.25 },
    { key: 6.5, text: '6.500%', value: 6.5 },
    { key: 6.75, text: '6.750%', value: 6.75 },
]

const interestOnlyOptions = [
    { key: true, text: 'Yes', value: true },
    { key: false, text: 'No', value: false },
]

const ValuationValues = ({ title, calc }) => (
    <Segment.Group>
        <ValueContainer top={`${title} Valuation`} headerColor={bucketColor(calc?.bucket)} label='' value={BucketCalc.selectedPriceToUse(calc)} />
        <Segment.Group className='values-container' horizontal style={{ background: 'white' }}>
            <ValueContainer label='Price/Sqft' value={calc?.avgPSF} />
            <ValueContainer label={title === 'Financing' ? 'Loans' : title} value={calc?.bucketCount || 0} type={FormattedInputType.NONE} />
            <ValueContainer label='Confidence' value={calc?.likenessAverage || 0} type={FormattedInputType.PERCENT} />
        </Segment.Group>
        <Segment.Group className='values-container' horizontal style={{ background: 'white' }}>
            <ValueContainer label='Spread' value={calc?.priceVolatilityPercentage || 0} type={FormattedInputType.PERCENT} />
            <ValueContainer label='Direction' value={calc?.directionalDistributionPercentage || 0} type={FormattedInputType.PERCENT} />
            <ValueContainer label='Avg Dist.' value={calc?.averageDistance || 0} type={FormattedInputType.NONE} />
            {title !== 'Rentals' && <ValueContainer label='Sqft Diff.' value={calc?.averageDifferentials || 0} type={FormattedInputType.PERCENT} />}
        </Segment.Group>
    </Segment.Group>
)

const ValueRowWithBadge = ({ style, title, titleStyle, value, valueStyle, score, count, badgeColor, secondBadgeColor }) => {
    let textColor = badgeColor ? 'white' : 'black'
    let sTextColor = secondBadgeColor ? 'white' : secondBadgeColor === null ? 'black' : textColor
    let sBadgeColor = secondBadgeColor || secondBadgeColor === null ? secondBadgeColor : badgeColor
    return (
        <Grid.Row style={style}>
            <Grid.Column width={8}>
                <span style={titleStyle}>{title}</span>
            </Grid.Column>
            <Grid.Column width={4} textAlign='right'>
                <span style={valueStyle}>{value === 'N/A' ? value : `$${formatNumber(value)}`}</span>
            </Grid.Column>
            <Grid.Column width={2} textAlign='right'>
                <Label style={{ minWidth: '65px', backgroundColor: badgeColor, color: textColor }} horizontal>
                    {formatNumber(score)}%
                </Label>
            </Grid.Column>
            <Grid.Column width={2} textAlign='left'>
                <Label style={{ minWidth: '40px', backgroundColor: sBadgeColor, color: sTextColor }} horizontal>
                    {isNaN(count) ? 0 : count}
                </Label>
            </Grid.Column>
        </Grid.Row>
    )
}

const FastCloseLoan = () => {
    const [loadState, setLoadState] = useState(LoadState.NONE)
    const [isLoading, setIsLoading] = useState(false)
    const [value, setValue] = useState('')

    const [target, setTarget] = useState(null)
    const [valuationData, setValuationData] = useState(null)

    const [isOverride, setIsOverride] = useState(false)

    const [ltv, setLtv] = useState(65)
    const [minDSCR, setMinDSCR] = useState(1.25)
    const [term, setTerm] = useState(30)
    const [condition, setCondition] = useState(PropertyCondition.Fair)
    const [interestRate, setInterestRate] = useState(0)
    const [isIO, setIsIO] = useState(false)

    const [calculatedRent, setCalculatedRent] = useState(0)
    const [insurance, setInsurance] = useState(0)
    const [annualTax, setAnnualTax] = useState(0)

    const [ltvValue, setLTVValue] = useState(0)
    const [dscrMaxLoanAmount, setDSCRMaxLoanAmount] = useState(0)

    const [salesTDSChart, setSalesTDSChart] = useState(null)
    const [financingTDSChart, setFinancingTDSChart] = useState(null)
    const [rentalTDSChart, setRentalTDSChart] = useState(null)
    const [listingsTDSChart, setListingsTDSChart] = useState(null)

    const [salesMarkerData, setSalesMarkerData] = useState(null)
    const [salesMarkerComps, setSalesMarkerComps] = useState(null)

    const [financingMarkerData, setFinancingMarkerData] = useState(null)
    const [financingMarkerComps, setFinancingMarkerComps] = useState(null)

    const [rentalMarkerData, setRentalMarkerData] = useState(null)
    const [rentalMarkerComps, setRentalMarkerComps] = useState(null)

    const [listingsMarkerData, setListingsMarkerData] = useState(null)
    const [listingsMarkerComps, setListingsMarkerComps] = useState(null)

    const [rightSidebarVisible, setRightSidebarVisible] = useState(false)

    const insuranceModifier = 1

    const maxLTV = 70

    const { bbl, pid } = useParams();

    useEffect(() => {
        let mounted = true
        if (mounted) {
            getInterestRates().then(rate => {
                if (!isNaN(rate)) {
                    let ir = (Math.ceil((rate + 3.25) * 8)) / 8
                    let alreadyIncludes = interestRateOptions.filter(_ => _.value === ir).length > 0
                    if (!alreadyIncludes) {
                        interestRateOptions.push(
                            { key: ir, text: ir + '0%', value: ir },
                        )
                    }
                    setInterestRate(ir)
                } else {
                    setInterestRate(6)
                }
            })

            if (bbl || pid) {
                if (bbl) {
                    setPropertyToRun({
                        propertyId: bbl,
                        type: DataSetType.PLUTO
                    })
                } else {
                    setPropertyToRun({
                        propertyId: pid,
                        type: DataSetType.FIRST_AMERICAN
                    })
                }
            }
        }
        return () => mounted = false
    }, [])

    useEffect(() => {
        switch (loadState) {
            case LoadState.SALES:
                runComps(target,
                    {
                        type: CompTypeOption.DEED,
                        ds: target.type
                    }, (compsData, canceled) => {
                        if (!canceled) {
                            finishSalesComps(compsData)
                        }
                    })
                break
            case LoadState.FINANCING:
                runComps(target,
                    {
                        type: CompTypeOption.MORTGAGE,
                        ds: target.type
                    },
                    (compsData, canceled) => {
                        if (!canceled) {
                            finishFinancingComps(compsData)
                        }
                    })
                break
            case LoadState.RENTALS:
                runComps(target,
                    {
                        type: CompTypeOption.RENT,
                        ds: target.type,
                        bedrooms: Array.isArray(target.bedrooms) && target.bedrooms.length > 0 ? target.bedrooms[0] : target.bedrooms
                    }, (compsData, canceled) => {
                        if (!canceled) {
                            finishRentalComps(compsData)
                        }
                    })
                break
            case LoadState.LISTINGS:
                runComps(target,
                    {
                        type: CompTypeOption.LISTING,
                        ds: target.type
                    }, (compsData, canceled) => {
                        if (!canceled) {
                            finishListingComps(compsData)
                        }
                    })
                break
            case LoadState.FINISHING:
                {
                    calculateTotalRent()
                    setLoadState(LoadState.FINISHED)
                }
                break
        }
    }, [loadState])

    useEffect(() => {
        if (target && loadState === LoadState.FINISHED) {
            calculateTotalRent()

            addMarkersToMap()
        }
    }, [condition])

    function addMarkersToMap() {
        addSalesMarkersToMap()
        addFinancingMarkersToMap()
        addRentalMarkersToMap()
        addListingsMarkersToMap()
    }

    useEffect(() => {
        if (salesMarkerComps) {
            addSalesMarkersToMap()
        } else {
            setSalesMarkerData(null)
        }
    }, [salesMarkerComps])

    useEffect(() => {
        if (financingMarkerComps) {
            addFinancingMarkersToMap()
        } else {
            setFinancingMarkerData(null)
        }
    }, [financingMarkerComps])

    useEffect(() => {
        if (rentalMarkerComps) {
            addRentalMarkersToMap()
        } else {
            setRentalMarkerData(null)
        }
    }, [rentalMarkerComps])

    useEffect(() => {
        if (listingsMarkerComps) {
            addListingsMarkersToMap()
        } else {
            setListingsMarkerData(null)
        }
    }, [listingsMarkerComps])

    const addSalesMarkersToMap = () => {
        salesMarkerComps && setSalesMarkerData(salesMarkerComps.filter(_ => _.bucket && _.bucket.length > 0 && _.bucket === getSalesCalc()?.bucket))
    }

    const addFinancingMarkersToMap = () => {
        financingMarkerComps && setFinancingMarkerData(financingMarkerComps.filter(_ => _.bucket && _.bucket.length > 0 && _.bucket === getFinancingCalc()?.bucket))
    }

    const addRentalMarkersToMap = () => {
        if (Lot.isPluto(target)) { rentalMarkerComps && setRentalMarkerData(rentalMarkerComps.filter(_ => _.bucket && _.bucket.length > 0 && getRentalCalc()?.buckets?.includes(_.bucket))) }
        else { rentalMarkerComps && setRentalMarkerData(rentalMarkerComps.filter(_ => _.bucket && _.bucket.length > 0 && _.bucket === getRentalCalc()?.bucket)) }
    }

    const addListingsMarkersToMap = () => {
        listingsMarkerComps && setListingsMarkerData(listingsMarkerComps.filter(_ => _.bucket && _.bucket.length > 0 && _.bucket === getListingsCalc()?.bucket))
    }

    useEffect(() => {
        if (target && loadState === LoadState.FINISHED) {
            calculateLoanAmount()
        }
    }, [ltv, minDSCR, interestRate, annualTax, insurance, calculatedRent, isIO, term])

    useEffect(async () => {
        if (target !== null || (target && Object.keys(target).length > 1)) {
            var rentalData = {}
            if (Lot.isPluto(target)) {
                rentalData = await makeGetRequest(`${property_api_rents_url}?bbl=${target.propertyId}`)

                const annualTax = await makeGetRequest(`${property_api_annual_tax_url}?bbl=${target.propertyId}`)

                if (annualTax) {
                    setAnnualTax(annualTax.annualTax)
                }
            } else {
                setAnnualTax(target.annualTax)
            }

            if (rentalData) {
                const totalBedrooms = rentalData.bedrooms != null && rentalData.bedrooms.length > 0 ? rentalData.bedrooms.reduce((a, b) => a + b) : 0
                const totalRent = rentalData.rents != null && rentalData.rents.length > 0 ? rentalData.rents.reduce((a, b) => a + b) : 0

                rentalData.totalBedrooms = totalBedrooms
                rentalData.totalRent = totalRent

                let seTotalRent = 0
                if (rentalData.bedrooms) {
                    rentalData.bedrooms.forEach(rooms => {
                        seTotalRent += getRentForRooms(rooms - 1 >= 0 ? rooms - 1 : rooms)
                    })
                }

                rentalData.seTotalRent = seTotalRent
            }

            setInsurance(Lot.bldgarea(target) * insuranceModifier)

            setValuationData(valuationData => (
                {
                    ...valuationData,
                    rentalData
                }
            ))
        }
    }, [target])

    const getRentalCalcForBr = (bedrooms) => {
        const { rentalCalcs } = valuationData || {}
        if (rentalCalcs) {
            const { lowBucket, middleBucket, highBucket } = rentalCalcs[bedrooms] || {}
            let calc = null
            switch (condition) {
                case PropertyCondition.Excellent:
                    calc = highBucket
                    break
                case PropertyCondition.Fair:
                    calc = middleBucket
                    break
                case PropertyCondition.Poor:
                    calc = lowBucket
                    break
            }

            return calc
        }
    }

    const getRentalCalc = () => {
        const { rentalLow, rentalMiddle, rentalHigh } = valuationData || {}
        let calc = null
        switch (condition) {
            case PropertyCondition.Excellent:
                calc = rentalHigh
                break
            case PropertyCondition.Fair:
                calc = rentalMiddle
                break
            case PropertyCondition.Poor:
                calc = rentalLow
                break
        }

        return calc
    }

    const rentToUse = () => {
        let rent = getCalcPrice(getRentalCalc(), true)
        let rentConfidence = getConfidenceScore(getRentalCalc()) / 100

        let finalRent = roundToFloorByNearest((rent * rentConfidence) + (calculatedRent * (1 - rentConfidence)), 25)
        return finalRent
        // let rent = min([getRentalPrice(), calculatedRent])
        // if (rent === 0) rent = max([getRentalPrice(), calculatedRent])
        // return rent
    }

    const salesPriceToUse = () => getCalcPrice(getSalesCalc()) // (isRent = false) => Lot.isPluto(target) ? getCalcPrice(getSalesCalc()) : getERCalcPrice(isRent)

    const calculateTotalRent = () => {
        const { rentalData } = valuationData || {}
        let salesPrice = salesPriceToUse()

        let units = Lot.isPluto(target) ? target.unitCode : target.unitstotal

        let tmpTotalRent = getRentalRate(salesPrice, units)

        const { seTotalRent } = rentalData || {}
        const rentToUse = seTotalRent >= tmpTotalRent ? seTotalRent : tmpTotalRent

        setCalculatedRent(roundToNearest(rentToUse))
    }

    const calculateLoanAmount = () => {
        let ltvValue = salesPriceToUse() * (ltv / 100)
        let dscrLoanAmount = 0

        const rent = rentToUse()
        const pmt = (rent / minDSCR) - (annualTax / 12) - (insurance / 12)

        if (!isOverride) {
            dscrLoanAmount = findBestDSCRLoanAmount()
        }

        if (dscrLoanAmount === 0) dscrLoanAmount = isIO ? ((pmt * 12) / (interestRate / 100)) : calculatePmt2Loan(pmt, (interestRate / 100), (term * 12))


        setLTVValue(roundToNearest(ltvValue, 1))
        setDSCRMaxLoanAmount(roundToNearest(dscrLoanAmount, 1))
    }

    const findBestDSCRLoanAmount = (dscrValues = [1.1, 1.15, 1.2, 1.25], ltvValues = [55, 60, 65, 70], index = 0) => {
        if (dscrValues.length > 0) {
            let currentDSCR = dscrValues.splice(0, 1)[0]
            if (isIO) currentDSCR += .1
            let dscrLoanAmount = calculateDSCRLoanValue(currentDSCR)

            let prevLTV = index === 0 ? null : ltvValues[index - 1]
            let currentLTV = ltvValues[index]
            let ltv = calculateLTV(dscrLoanAmount)

            if (prevLTV && ltv < prevLTV) {
                return calculateLTVLoanValue(prevLTV)
            }
            else if (ltv < currentLTV) {
                return dscrLoanAmount
            }
            else {
                return findBestDSCRLoanAmount(dscrValues, ltvValues, ++index)
            }
        } else {
            return 0
        }
    }

    const getPayment = () => {
        return isIO ? ((getFinalLoanValue() * (interestRate / 100)) / 12) : calculatePayment(getFinalLoanValue(), (interestRate / 100), (term * 12))
    }

    const getPiti = () => {
        return getPayment() + (annualTax / 12) + (insurance / 12)
    }

    const calculateDSCRLoanValue = (mDSCR) => {
        const rent = rentToUse()
        const pmt = (rent / mDSCR) - (annualTax / 12) - (insurance / 12)
        let amount = isIO ? ((pmt * 12) / (interestRate / 100)) : calculatePmt2Loan(pmt, (interestRate / 100), (term * 12))
        return amount
    }

    const calculateLTVLoanValue = (mLTV) => {
        return salesPriceToUse() * (mLTV / 100)
    }

    const calculateDSCR = (loanAmount) => {
        const rent = rentToUse()
        return isIO ? rent / (((loanAmount * (interestRate / 100)) / 12) + (annualTax / 12) + (insurance / 12)) : (rent / (calculatePayment(loanAmount, (interestRate / 100), (term * 12)) + (annualTax / 12) + (insurance / 12)))
    }

    const calculateLTV = (loanAmount) => {
        if (salesPriceToUse() > 0) {
            return ((loanAmount / salesPriceToUse()) * 100) ?? 0
        }
        return 0
    }

    const getActualDSCR = () => {
        let rent = rentToUse()

        const loanAmount = getFinalLoanValue()

        const dscrPayment = isIO ? ((loanAmount * (interestRate / 100)) / 12) : calculatePayment(loanAmount, (interestRate / 100), (term * 12))
        const dscrPiti = dscrPayment + (annualTax / 12) + (insurance / 12)

        return isNaN(rent / dscrPiti) ? 0 : roundToNearest(rent / dscrPiti, .01)
    }

    const getActualLTV = () => {
        const loanAmount = getFinalLoanValue()

        if (salesPriceToUse() > 0) {
            return ((loanAmount / salesPriceToUse()) * 100) ?? 0
        }
        return 0
    }

    const onClickBack = () => {
        setValue('')
        setTarget(null)
        setValuationData(null)

        setSalesTDSChart(null)
        setFinancingTDSChart(null)
        setRentalTDSChart(null)
        setListingsTDSChart(null)

        setCalculatedRent(0)
        setInsurance(0)
        setAnnualTax(0)
        setLTVValue(0)

        setIsIO(false)
        setIsOverride(false)

        setDSCRMaxLoanAmount(0)

        setSalesMarkerComps(null)
        setFinancingMarkerComps(null)
        setRentalMarkerComps(null)
        setListingsMarkerComps(null)

        setLoadState(LoadState.NONE)
    }

    const handleSearchChange = async (written, address, parts) => {
        //console.log(address, parts)

        if (address.length === 0) return

        setIsLoading(true)

        var search = getParcelAddressQueryParams(written, address, parts)

        let result = await getAddress(search)
        setPropertyToRun(result)
        return {success: true, isPluto: Lot.isPluto(result)}
  };

  const getAddress = async (search) => {
    console.log(search)
    let result = await makeGetRequest(`${parcel_search_url}?${search}`)

    setIsLoading(false)
    
    if (!result) {
        alert('No Address Found')
        return
    }
        
    return result
  }

    const setPropertyToRun = (result) => {
        onClickBack()

        setTarget(result)
        setLoadState(LoadState.SALES)
    }

    const finishSalesComps = (compResults) => {
        const {compsData} = compResults || {}

        if (compsData) {
            const {data, comps, buckets, chartData} = compsData || {}
            let foundTarget = comps.filter(_ => _.propertyId.toString() === target.propertyId.toString())[0]

            const {lowBucket, middleBucket, highBucket} = buckets || {}
            setValuationData(valuationData => ({
                ...valuationData,
                lowCalc: lowBucket,
                middleCalc: middleBucket,
                highCalc: highBucket,
                //highestCalc: calc.highestBucket,
                salesDistance: data?.totalDistance
                //erPrices
            }))

            setSalesTDSChart(setupLTDSChart(comps, chartData))

            let url = property_api_lot_latest_prices            
            var latestPricesResult = makeGetRequest(`${url}?propertyId=${foundTarget.propertyId}`)
            if (latestPricesResult) {
                setTarget({
                    ...foundTarget,
                    latestPrices: latestPricesResult
                })
            } else {
                setTarget(foundTarget)
            }

            setValue(Lot.fullAddress(foundTarget))

            setSalesMarkerComps(comps)

            setLoadState(LoadState.FINANCING)
        }
    }

    const finishFinancingComps = (compResults) => {
        //const { highCalc } = valuationData || {}
        const {compsData} = compResults || {}
        if (compsData) {
            const {data, comps, buckets, chartData} = compsData || {}
            const {lowBucket, middleBucket, highBucket} = buckets || {}
            setValuationData(valuationData => ({
                ...valuationData,
                //financingLowestCalc: calc.lowBucketCalc,
                financingLowCalc: lowBucket,
                financingMiddleCalc: middleBucket,
                financingHighCalc: highBucket,
                financingDistance: data?.totalDistance
            }))

            setFinancingTDSChart(setupLTDSChart(comps, chartData))

            setFinancingMarkerComps(comps)

            setLoadState(LoadState.RENTALS)
        }
    }

    const finishListingComps = (compResults) => {
        const {compsData} = compResults || {}
        if (compsData) {
            const {data, comps, buckets, chartData} = compsData || {}
            setValuationData(valuationData => ({
                ...valuationData,
                listingsLowCalc: buckets?.lowBucket,
                listingsMiddleCalc: buckets?.middleBucket,
                listingsHighCalc: buckets?.highBucket,
                listingsDistance: data?.totalDistance
            }))

            setListingsTDSChart(setupLTDSChart(comps, null, null))

            setListingsMarkerComps(comps)

            setLoadState(LoadState.FINISHING)
        }
    }

    const finishRentalComps = async (compResults) => {
        //const {highCalc} = valuationData || {}
        const {compsData} = compResults || {}
        if (Lot.isPluto(target)) {
            let bedrooms = target.bedrooms.filter(onlyUnique)

            let tempComps = []

            let rentalCalcs = {}

            for (var i = 0; i < bedrooms.length; i++) {
                let _ = bedrooms[i]
                let result = null
                if (i === 0) {
                    result = compsData
                } else {
                    await runComps(target,
                        {
                            type: CompTypeOption.RENT,
                            ds: target.type,
                            bedrooms: _
                        }, (cdata, canceled) => {
                            result = cdata.compsData
                        })
                }

                const {data, comps, buckets, chartData} = result || {}
                const { lowBucket, middleBucket, highBucket } = buckets || {}
                let selectedBuckets = [lowBucket, middleBucket, highBucket].map(_ => _?.bucket)
                comps.filter(_ => _.bucket && _.bucket.length > 0).forEach(lot => {
                    if (selectedBuckets.includes(lot.bucket))
                        tempComps.push({ ...lot })
                })
                rentalCalcs[_] = result
            }

            let lowRental = 0
            let midRental = 0
            let highRental = 0

            let lowRentalPercentage = 0
            let midRentalPercentage = 0
            let highRentalPercentage = 0

            let lowRentalBucket = 0
            let midRentalBucket = 0
            let highRentalBucket = 0

            let lowBuckets = []
            let midBuckets = []
            let highBuckets = []

            let lowSpread = 0
            let midSpread = 0
            let highSpread = 0

            let lowDirection = 0
            let midDirection = 0
            let highDirection = 0

            let lowDistance = 0
            let midDistance = 0
            let highDistance = 0

            let lowDifferentials = 0
            let midDifferentials = 0
            let highDifferentials = 0

            let usedRooms = []

            let noLows = 0
            let noMids = 0
            let noHighs = 0

            target.bedrooms.forEach(_ => {
                let alreadyUsed = usedRooms.includes(_)
                if (!alreadyUsed)
                    usedRooms.push(_)
                let calc = rentalCalcs[_]

                const {data, comps, buckets, chartData} = calc || {}

                let lowC = buckets?.lowBucket
                let middleC = buckets?.middleBucket
                let highC = buckets?.highBucket
                if (lowC) {
                    lowRental += BucketCalc.selectedPriceToUse(lowC)
                    if (!alreadyUsed) {
                        lowRentalPercentage += lowC.likenessAverage
                        lowRentalBucket += lowC.bucketCount
                        if (!lowBuckets.includes(lowC.bucket)) {
                            lowBuckets.push(lowC.bucket)
                        }

                        lowSpread += lowC.priceVolatilityPercentage
                        lowDirection += lowC.directionalDistributionPercentage
                        lowDistance += lowC.averageDistance
                        lowDifferentials += lowC.averageDifferentials
                    }
                } else {
                    noLows++
                }
                if (middleC) {
                    midRental += BucketCalc.selectedPriceToUse(middleC)
                    if (!alreadyUsed) {
                        midRentalPercentage += middleC.likenessAverage
                        midRentalBucket += middleC.bucketCount
                        if (!midBuckets.includes(middleC.bucket)) {
                            midBuckets.push(middleC.bucket)
                        }

                        midSpread += middleC.priceVolatilityPercentage
                        midDirection += middleC.directionalDistributionPercentage
                        midDistance += middleC.averageDistance
                        midDifferentials += middleC.averageDifferentials
                    }
                } else {
                    noMids++
                }
                if (highC) {
                    highRental += BucketCalc.selectedPriceToUse(highC)
                    if (!alreadyUsed) {
                        highRentalPercentage += highC.likenessAverage
                        highRentalBucket += highC.bucketCount
                        if (!highBuckets.includes(highC.bucket)) {
                            highBuckets.push(highC.bucket)
                        }

                        highSpread += highC.priceVolatilityPercentage
                        highDirection += highC.directionalDistributionPercentage
                        highDistance += highC.averageDistance
                        highDifferentials += highC.averageDifferentials
                    }
                } else {
                    noHighs++
                }
            })

            
            let averageSalePriceLow = isNaN(lowRental) ? 0 : (lowRental + ((lowRental * noLows) / (max(bedrooms.length, 1) - noLows)))
            const rentalLow = {
                allPrices: [{ label: PSF_PRICE_LABEL, price: averageSalePriceLow, selected: true }],
                avgPSF: isNaN(lowRental) ? 0 : ((lowRental * 12) / Lot.bldgarea(target)),
                likenessAverage: isNaN(lowRentalPercentage) ? 0 : lowRentalPercentage / max(bedrooms.length, 1),
                bucketCount: isNaN(lowRentalBucket) ? 0 : lowRentalBucket,
                bucket: lowBuckets[0],
                color: bucketColor(lowBuckets[0]),
                buckets: lowBuckets,
                priceVolatilityPercentage: isNaN(lowSpread) ? 0 : lowSpread / max(bedrooms.length, 1),
                directionalDistributionPercentage: lowDirection / max(bedrooms.length, 1),
                averageDistance: isNaN(lowDistance) ? 0 : lowDistance / max(bedrooms.length, 1),
                averageDifferentials: isNaN(lowDifferentials) ? 0 : lowDifferentials / max(bedrooms.length, 1)
            }

            let averageSalePriceMid = isNaN(midRental) ? 0 : (midRental + ((midRental * noMids) / (max(bedrooms.length, 1) - noMids)))
            const rentalMiddle = {
                allPrices: [{ label: PSF_PRICE_LABEL, price: averageSalePriceMid, selected: true }],
                avgPSF: isNaN(midRental) ? 0 : ((midRental * 12) / Lot.bldgarea(target)),
                likenessAverage: isNaN(midRentalPercentage) ? 0 : midRentalPercentage / max(bedrooms.length, 1),
                bucketCount: isNaN(midRentalBucket) ? 0 : midRentalBucket,
                bucket: midBuckets[0],
                color: bucketColor(midBuckets[0]),
                buckets: midBuckets,
                priceVolatilityPercentage: isNaN(midSpread) ? 0 : midSpread / max(bedrooms.length, 1),
                directionalDistributionPercentage: midDirection / max(bedrooms.length, 1),
                averageDistance: isNaN(midDistance) ? 0 : midDistance / max(bedrooms.length, 1),
                averageDifferentials: isNaN(midDifferentials) ? 0 : midDifferentials / max(bedrooms.length, 1)
            }

            let averageSalePriceHigh = isNaN(highRental) ? 0 : (highRental + ((highRental * noHighs) / (max(bedrooms.length, 1) - noHighs)))
            const rentalHigh = {
                allPrices: [{ label: PSF_PRICE_LABEL, price: averageSalePriceHigh, selected: true }],
                avgPSF: isNaN(highRental) ? 0 : ((highRental * 12) / Lot.bldgarea(target)),
                likenessAverage: isNaN(highRentalPercentage) ? 0 : highRentalPercentage / max(bedrooms.length, 1),
                bucketCount: isNaN(highRentalBucket) ? 0 : highRentalBucket,
                bucket: highBuckets[0],
                color: bucketColor(highBuckets[0]),
                buckets: highBuckets,
                priceVolatilityPercentage: isNaN(highSpread) ? 0 : highSpread / max(bedrooms.length, 1),
                directionalDistributionPercentage: highDirection / max(bedrooms.length, 1),
                averageDistance: isNaN(highDistance) ? 0 : highDistance / max(bedrooms.length, 1),
                averageDifferentials: isNaN(highDifferentials) ? 0 : highDifferentials / max(bedrooms.length, 1)
            }

            setValuationData(valuationData => ({
                ...valuationData,
                rentalLow,
                rentalMiddle,
                rentalHigh,
                rentalCalcs
            }))

            setRentalTDSChart(setupLTDSChart(tempComps, null, null))

            setRentalMarkerComps(tempComps)
        } else {
            if (compsData) {
                const { buckets, data, comps } = compsData || {}
                setValuationData(valuationData => ({
                    ...valuationData,
                    rentalLow: buckets?.lowBucket,
                    rentalMiddle: buckets?.middleBucket,
                    rentalHigh: buckets?.highBucket,
                    rentalDistance: data?.totalDistance
                }))

                setRentalTDSChart(setupLTDSChart(comps, null, null))

                setRentalMarkerComps(comps)
            }
        }

        setLoadState(LoadState.LISTINGS)
    }

    const setupLTDSChart = (comps, stdInfo) => {
        let data = comps.map(_ => {
            let color = ''
            if (_.bucket && _.bucket.length > 0) {
                color = bucketColor(_.bucket)
            } else {
                color = '#ececec'
            }
            return getTDSChartData(_, color)
        })

        data.sort((a, b) => a.x > b.x ? 1 : -1)

        let movingAverage = getChartMovingAverage(data, 0, 100, 10)

        //console.log(movingAverage)
        return { data: data, stdInfo, movingAverage }
    }

    const getSalesCalc = () => {
        const { lowCalc, middleCalc, highCalc } = valuationData || {}
        let calc = null
        switch (condition) {
            case PropertyCondition.Excellent:
                calc = highCalc
                break
            case PropertyCondition.Fair:
                calc = middleCalc
                break
            case PropertyCondition.Poor:
                calc = lowCalc
                break
        }
        return calc
    }

    const getListingsCalc = () => {
        const { listingsLowCalc, listingsMiddleCalc, listingsHighCalc } = valuationData || {}
        switch (condition) {
            case PropertyCondition.Excellent:
                return listingsHighCalc
            case PropertyCondition.Fair:
                return listingsMiddleCalc
            case PropertyCondition.Poor:
                return listingsLowCalc
        }
    }

    const getFinancingCalc = () => {
        const { financingLowCalc, financingMiddleCalc, financingHighCalc } = valuationData || {}
        switch (condition) {
            case PropertyCondition.Excellent:
                return financingHighCalc
            case PropertyCondition.Fair:
                return financingMiddleCalc
            case PropertyCondition.Poor:
                return financingLowCalc
        }
    }

    const getFinalLoanValue = () => {
        let avgVal = getAvgLoanValue()
        let minVal = min([avgVal, dscrMaxLoanAmount])

        let tmpLtv = calculateLTV(minVal)
        if (tmpLtv > maxLTV) {
            return calculateLTVLoanValue(maxLTV)
        }
        return roundToNearest(minVal)
    }

    const getAvgLoanValue = () => {
        let value = mean([getCalcPrice(getFinancingCalc()), ltvValue, dscrMaxLoanAmount])
        // if (value > estimatedValue*.75) {
        //     value = estimatedValue*.75
        // }
        return roundToNearest(value)
    }

    const getCalcPrice = (calc, isRent = false) => {
        let val = BucketCalc.selectedPriceToUse(calc)
        if (isNaN(val)) return 0
        if (isRent) return val
        return roundToFloorByNearest(val, 5000) || 0
    }

    const getERCalcPrice = (calc, isRent = false) => {
        let val = calc?.erPrice
        if (isNaN(val)) return 0
        if (isRent) return val
        return roundToFloorByNearest(val, 5000) || 0
    }

    const getConfidenceScore = (calc) => {
        return calc?.likenessAverage ?? 0
    }

    const getBucketCount = (calc) => {
        let count = calc?.bucketCount
        return isNaN(count) ? 0 : count
    }

    const getBadgeColor = (calc) => {
        return calc?.color
    }

    return (
        <div className='resp-container'>
            <Segment basic style={{ minHeight: '100vh', background: '#f8f8f8', padding: '4em 2em 2em' }}>
                <Form size='small'>
                    <Form.Group>
                        <Form.Field className='comp-inline-field' inline>
                            <label>Subject Property</label>
                            <SearchLocationInput 
                                className="fluid" 
                                name=''
                                label=''
                                icon='home'
                                value={value}
                                placeholder='Enter property address'
                                onChange={handleSearchChange} 
                                isLoading={isLoading} />
                        </Form.Field>
                        <Form.Select
                            options={propertyConditionOptions}
                            placeholder='Select Condition'
                            value={condition}
                            onChange={(e, { value }) => {
                                setIsOverride(false)
                                setCondition(value)
                            }}
                        />
                        <Form.Field><Button icon='x' onClick={onClickBack}></Button></Form.Field>
                    </Form.Group>
                </Form>
                <Divider />
                {target && (
                    <>
                        <Grid>
                            <Grid.Column>
                                <GoogleMap
                                    //compsPanelVisible={compsVisible}
                                    latitude={target?.latitude}
                                    longitude={target?.longitude}
                                />
                            </Grid.Column>
                        </Grid>
                        <Divider />
                    </>
                )}
                <Grid columns={2} stretched>
                    <Grid.Row style={{ justifyContent: 'space-evenly' }}>
                        <Grid.Column width={4}>
                            <Segment className='no-padding'>
                                <Table celled compact structured textAlign='right' columns={2}>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell textAlign='left'><b>Max LTV</b></Table.Cell>
                                            <Table.Cell >
                                                <Select
                                                    fluid
                                                    className='no-border'
                                                    options={ltvOptions}
                                                    placeholder='Max LTV'
                                                    value={ltv}
                                                    onChange={(e, { value }) => {
                                                        setIsOverride(true)
                                                        setLtv(value)
                                                    }}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell textAlign='left'><b>Min DSCR</b></Table.Cell>
                                            <Table.Cell >
                                                <Select
                                                    fluid
                                                    className='no-border'
                                                    options={dscrOptions}
                                                    placeholder='Min DSCR'
                                                    value={minDSCR}
                                                    onChange={(e, { value }) => {
                                                        setIsOverride(true)
                                                        setMinDSCR(value)
                                                    }}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell textAlign='left'><b>Term</b></Table.Cell>
                                            <Table.Cell >
                                                <Select
                                                    fluid
                                                    className='no-border'
                                                    options={termOptions}
                                                    placeholder='Select Term'
                                                    value={term}
                                                    onChange={(e, { value }) => setTerm(value)}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell textAlign='left' ><b>Interest Rate</b></Table.Cell>
                                            <Table.Cell >
                                                <Select
                                                    fluid
                                                    className='no-border'
                                                    options={interestRateOptions}
                                                    placeholder='Select Interest Rate'
                                                    value={interestRate}
                                                    onChange={(e, { value }) => setInterestRate(value)}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell textAlign='left'><b>Interest Only</b></Table.Cell>
                                            <Table.Cell >
                                                <Select
                                                    fluid
                                                    className='no-border'
                                                    options={interestOnlyOptions}
                                                    placeholder='Interest Only?'
                                                    value={isIO}
                                                    onChange={(e, { value }) => setIsIO(value)}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell textAlign='left' ><b>{isIO ? 'Interest' : 'Principal & Interest'}</b></Table.Cell>
                                            <Table.Cell >${formatNumber(getPayment())}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell textAlign='left' ><b>Taxes</b></Table.Cell>
                                            <Table.Cell >${formatNumber(annualTax / 12)}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell textAlign='left'><b>Insurance</b></Table.Cell>
                                            <Table.Cell >${formatNumber(insurance / 12)}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell textAlign='left'><b>Total PITI</b></Table.Cell>
                                            <Table.Cell >${formatNumber(getPiti())}</Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column stretched={true} width={8}>
                            <Segment className='no-padding-h'>
                                <Grid className="fastclose-dynamicomp-details" textAlign='left' columns={3}>
                                    <InformationRow element={{
                                        title: 'Most Recent Sale',
                                        value: target && target.latestPrices?.deedAmount ? `$${formatNumber(target.latestPrices.deedAmount)}` : 'N/A',
                                        valueUrl: target && target.latestPrices && target.latestPrices.deedId && acris_document_viewer_url + target.latestPrices.deedId
                                    }}
                                        extra={target && target.latestPrices ? { value: formatDate(target.latestPrices.deedDate) } : true}
                                    />
                                    <InformationRow style={{ marginTop: '.25em' }} element={{
                                        title: 'Most Recent Loan',
                                        value: target && target.latestPrices?.mortgageAmount ? `$${formatNumber(target.latestPrices.mortgageAmount)}` : 'N/A',
                                        valueUrl: target && target.latestPrices && target.latestPrices.mortgageId && acris_document_viewer_url + target.latestPrices.mortgageId
                                    }}
                                        extra={target && target.latestPrices ? { value: formatDate(target.latestPrices.mortgageDate) } : true}
                                    />
                                    <InformationRow style={{ marginTop: '.25em' }} element={{
                                        title: 'Most Recent Rental',
                                        value: target && target.latestPrices?.rentalAmount ? `$${formatNumber(target.latestPrices.rentalAmount)}` : 'N/A',
                                    }}
                                        extra={target && target.latestPrices ? { value: formatDate(target.latestPrices.rentalDate) } : true}
                                    />
                                    <Divider />
                                    <ValueRowWithBadge
                                        title={'Dynamicomp™ Listings'}
                                        value={valuationData && loadState === LoadState.FINISHED && getCalcPrice(getListingsCalc()) === 0 ? 'N/A' : getCalcPrice(getListingsCalc())}
                                        score={getConfidenceScore(getListingsCalc())}
                                        count={getBucketCount(getListingsCalc())}
                                        badgeColor={getBadgeColor(getListingsCalc())}
                                    />
                                    <ValueRowWithBadge
                                        style={{ marginTop: '.25em' }}
                                        title={'Dynamicomp™ Valuation'}
                                        value={getCalcPrice(getSalesCalc())}
                                        score={getConfidenceScore(getSalesCalc())}
                                        count={getBucketCount(getSalesCalc())}
                                        badgeColor={getBadgeColor(getSalesCalc())}
                                    />
                                    {target && Lot.isFA(target) && (
                                        <InformationRow style={{ marginTop: '.25em' }} element={{
                                            title: 'Dynamicomp™ ER Valuation',
                                            //value: `$${formatNumber(getERCalcPrice())}`
                                            value: `$${formatNumber(getERCalcPrice(getSalesCalc()))}`
                                        }}
                                            extra={true}
                                        />
                                    )}
                                    <Divider />
                                    <ValueRowWithBadge
                                        title={'Comparable Rent'}
                                        value={getCalcPrice(getRentalCalc(), true)}
                                        //valueStyle={getRentalPrice() && getRentalBucketCount() > 0 && rentToUse() === getRentalPrice() ? { fontWeight: '600', color: 'green' } : null}
                                        score={getConfidenceScore(getRentalCalc())}
                                        count={getBucketCount(getRentalCalc())}
                                        badgeColor={getBadgeColor(getRentalCalc())}
                                    />
                                    <InformationRow style={{ marginTop: '.25em' }} element={{
                                        title: 'Calculated Rent',
                                        value: `$${formatNumber(calculatedRent)}`
                                    }}
                                        extra={true}
                                    />
                                    <InformationRow style={{ marginTop: '.25em' }} element={{
                                        title: 'Dynamicomp™ Rent',
                                        value: `$${formatNumber(rentToUse())}`
                                    }}
                                        extra={true}
                                    />
                                    <Divider />
                                    <ValueRowWithBadge
                                        title={'Dynamicomp™ Loans'}
                                        value={getCalcPrice(getFinancingCalc())}
                                        score={getConfidenceScore(getFinancingCalc())}
                                        count={getBucketCount(getFinancingCalc())}
                                        badgeColor={getBadgeColor(getFinancingCalc())}
                                    />
                                    <InformationRow style={{ marginTop: '.25em' }} element={{
                                        title: 'Max Loan By LTV',
                                        value: `$${formatNumber(ltvValue)}`
                                    }}
                                        extra={true}
                                    />
                                    {/* <InformationRow style={{ marginTop: '.25em' }} element={{
                                        title: 'Max Loan By DSCR',
                                        value: `$${formatNumber(dscrMaxLoanAmount)}`
                                    }}
                                        extra={true}
                                    /> */}
                                    <ValueRowWithBadge
                                        style={{ marginTop: '.25em' }}
                                        title={'Max Loan By DSCR'}
                                        value={dscrMaxLoanAmount}
                                        score={calculateLTV(dscrMaxLoanAmount)}
                                        count={formatNumber(calculateDSCR(dscrMaxLoanAmount))}
                                    />
                                    <Divider />
                                    <ValueRowWithBadge
                                        title={'FastClose Loan Amount'}
                                        titleStyle={{ fontSize: '1.5em' }}
                                        value={getFinalLoanValue()}
                                        valueStyle={{ fontSize: '1.5em', fontWeight: '600' }}
                                        score={getActualLTV()}
                                        count={formatNumber(getActualDSCR())}
                                        badgeColor={(getActualLTV() === ltv || getActualLTV() === maxLTV) || getFinalLoanValue() === getAvgLoanValue() ? 'green' : null}
                                        secondBadgeColor={getActualDSCR() === minDSCR || getFinalLoanValue() === getAvgLoanValue() ? 'green' : null}
                                    />
                                </Grid>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Segment>
                                {target && <LotInformationPanel className="fastclose-lot-details" lot={target} />}
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider />
                <Grid columns={4}>
                    <Grid.Row style={{ justifyContent: 'space-evenly' }}>
                        <Grid.Column width={4}>
                            <ValuationValues title='Sales' calc={getSalesCalc()} />
                            <MapboxMap
                                className='appraisals-map-container no-padding-v'
                                target={target}
                                markerData={salesMarkerData}
                                totalDistance={valuationData?.salesDistance || 1}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <ValuationValues title='Financing' calc={getFinancingCalc()} />
                            <MapboxMap
                                className='appraisals-map-container no-padding-v'
                                target={target}
                                markerData={financingMarkerData}
                                totalDistance={valuationData?.financingDistance || 1}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <ValuationValues title='Listings' calc={getListingsCalc()} />
                            <MapboxMap
                                className='appraisals-map-container no-padding-v'
                                target={target}
                                markerData={listingsMarkerData}
                                totalDistance={valuationData?.listingsDistance || 1}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <ValuationValues title='Rentals' calc={getRentalCalc()} />
                            <MapboxMap
                                className='appraisals-map-container no-padding-v'
                                target={target}
                                markerData={rentalMarkerData}
                                totalDistance={valuationData?.rentalDistance || 1}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider />
                {salesTDSChart && financingTDSChart && rentalTDSChart && listingsTDSChart && (
                    <Grid columns={4}>
                        <Grid.Row>
                            <Grid.Column>
                                <Segment stacked>
                                    {salesTDSChart && <LTDSChart
                                        chartData={salesTDSChart}
                                        cHeight={300}
                                        cWidth={300}
                                        scatterSize={2}
                                    />}
                                </Segment>
                            </Grid.Column>
                            <Grid.Column>
                                <Segment stacked>
                                    {financingTDSChart && <LTDSChart
                                        chartData={financingTDSChart}
                                        cHeight={300}
                                        cWidth={300}
                                        scatterSize={2}
                                    />}
                                </Segment>
                            </Grid.Column>
                            <Grid.Column>
                                <Segment stacked>
                                    {listingsTDSChart && <LTDSChart
                                        chartData={listingsTDSChart}
                                        cHeight={300}
                                        cWidth={300}
                                        scatterSize={2}
                                    />}
                                </Segment>
                            </Grid.Column>
                            <Grid.Column>
                                <Segment stacked>
                                    {rentalTDSChart && <LTDSChart
                                        chartData={rentalTDSChart}
                                        cHeight={300}
                                        cWidth={300}
                                        scatterSize={2}
                                    />}
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}

                <Dimmer page active={loadState > LoadState.NONE && loadState < LoadState.FINISHED}>
                    <Loader />
                </Dimmer>

                <Sidebar
                    className='comparables-side-panel-container side-panel-container'
                    as={Segment}
                    animation='overlay'
                    direction='right'
                    width='very wide'
                    visible={rightSidebarVisible}
                >
                    <Divider hidden />
                    <Divider hidden />
                    <Label className='panel-closer left' style={{ top: '4.5em' }} basic icon={`long arrow alternate ${rightSidebarVisible ? 'right' : 'left'}`} floating
                        onClick={() => {
                            setRightSidebarVisible(rightSidebarVisible => !rightSidebarVisible)
                        }}
                    />
                    <Grid className='side-panel-grid' style={{ height: '100%', padding: '40px 0', flexGrow: '2' }}>
                        <Grid.Row columns={1} style={{ height: '100%' }}>
                            <Grid.Column stretched style={{ height: '100%' }}>
                                {target && salesMarkerData &&
                                    <ComparablesTable lots={[target, ...salesMarkerData]} />
                                }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Sidebar>

            </Segment>
        </div>
    )
}

export default FastCloseLoan
