import moment from 'moment';
import neighborhoods_in_boroughs from '../assets/json/neighborhoods_in_boroughs.json'
import cities_in_states from '../assets/json/cities_in_states.json'

export const NotAvailable = 'N/A'

export const CLOSING_COST_PERCENTAGE = (1-0.869565217391304)
export const PURCHASE_COST_PERCENTAGE = .05
export const SOFT_COST_PERCENTAGE = .1

export const SALES_COST_PERCENTAGE = .02
export const BROKER_FEES_PERCENTAGE = .05

export const today = () => new Date().toISOString().split('T')[0]

export const MIN_SEARCH_LENGTH = 3

export function similarity(s1, s2) {
  var longer = s1;
  var shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  var longerLength = longer.length;
  if (longerLength === 0) {
    return 1.0;
  }
  return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
}

function editDistance(s1, s2) {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  var costs = [];
  for (var i = 0; i <= s1.length; i++) {
    var lastValue = i;
    for (var j = 0; j <= s2.length; j++) {
      if (i === 0)
        costs[j] = j;
      else {
        if (j > 0) {
          var newValue = costs[j - 1];
          if (s1.charAt(i - 1) !== s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue),
              costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0)
      costs[s2.length] = lastValue;
  }
  return costs[s2.length];
}

export const DataSetType = {
  PLUTO: 'pluto',
  FIRST_AMERICAN: 'fa',
  REAL_ESTATE_API: 're'
}

export const Settings = {
  extendedCompsRadius: 1,
  extendedCompsMonths: 12,
  bldgareaPercent: .1,
  minLikeness: 20,
  minTD: 20,
  minSize: 20
}

export const CompDeselectReason = {
  PSFLow: 0,
  PSFHigh: 1,
  Neighborhood: 2,
  SameParty: 3
}

export const boroOptions = [
  {key:1, value:1, text:'Manhattan'},
  {key:2, value:2, text:'Bronx'},
  {key:3, value:3, text:'Brooklyn'},
  {key:4, value:4, text:'Queens'},
  {key:5, value:5, text:'Staten Island'},
]

export const stateOptions = [
  {key:"AL",value:"AL",text:"AL"},{key:"AK",value:"AK",text:"AK"},{key:"AZ",value:"AZ",text:"AZ"},{key:"AR",value:"AR",text:"AR"},{key:"CA",value:"CA",text:"CA"},{key:"CO",value:"CO",text:"CO"},{key:"CT",value:"CT",text:"CT"},{key:"DE",value:"DE",text:"DE"},{key:"FL",value:"FL",text:"FL"},{key:"GA",value:"GA",text:"GA"},{key:"HI",value:"HI",text:"HI"},{key:"ID",value:"ID",text:"ID"},{key:"IL",value:"IL",text:"IL"},{key:"IN",value:"IN",text:"IN"},{key:"IA",value:"IA",text:"IA"},{key:"KS",value:"KS",text:"KS"},{key:"KY",value:"KY",text:"KY"},{key:"LA",value:"LA",text:"LA"},{key:"ME",value:"ME",text:"ME"},{key:"MD",value:"MD",text:"MD"},{key:"MA",value:"MA",text:"MA"},{key:"MI",value:"MI",text:"MI"},{key:"MN",value:"MN",text:"MN"},{key:"MS",value:"MS",text:"MS"},{key:"MO",value:"MO",text:"MO"},{key:"MT",value:"MT",text:"MT"},{key:"NE",value:"NE",text:"NE"},{key:"NV",value:"NV",text:"NV"},{key:"NH",value:"NH",text:"NH"},{key:"NJ",value:"NJ",text:"NJ"},{key:"NM",value:"NM",text:"NM"},{key:"NY",value:"NY",text:"NY"},{key:"NC",value:"NC",text:"NC"},{key:"ND",value:"ND",text:"ND"},{key:"OH",value:"OH",text:"OH"},{key:"OK",value:"OK",text:"OK"},{key:"OR",value:"OR",text:"OR"},{key:"PA",value:"PA",text:"PA"},{key:"RI",value:"RI",text:"RI"},{key:"SC",value:"SC",text:"SC"},{key:"SD",value:"SD",text:"SD"},{key:"TN",value:"TN",text:"TN"},{key:"TX",value:"TX",text:"TX"},{key:"UT",value:"UT",text:"UT"},{key:"VT",value:"VT",text:"VT"},{key:"VA",value:"VA",text:"VA"},{key:"WA",value:"WA",text:"WA"},{key:"WV",value:"WV",text:"WV"},{key:"WI",value:"WI",text:"WI"},{key:"WY",value:"WY",text:"WY"}
]

export const neighborhoodOptions = (borocode) => {
  let e = neighborhoods_in_boroughs.filter(_ => _.borocode.toString() === borocode.toString())[0]
  return e?.neighborhoods?.map(neighborhood => {
    return {key: neighborhood, value: neighborhood, text: neighborhood}
  })
}

export const cityOptions = (state) => {
  let e = cities_in_states.filter(_ => _.state === state)[0]
  return e?.cities?.map(city => {
    return {key: city, value: city, text: city}
  })
}

export function getMonthName(monthNumber) {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString('en-US', { month: 'short' });
}

export function DeselectReason(reason) {
  switch(reason) {
    case 0:
      return 'PSF Low'
    case 1:
      return 'PSF High'
    case 2:
      return 'Neighborhood'
    case 3:
      return 'Non-Arm'
    default:
      return '-'
  }
}

export const searchFilters = {
  relativeSqftName: '10',
  relativeSqft: 10,  
  radiusName: 'mile',
  saleDateName: '12',
  radius: 1, // mile
  date: {
    min: moment().subtract(1, 'years').format('YYYY-MM-DD'),
    max: ''
  },
  sqft: {
    min: null,
    max: null
  },
  salePrice: {
    min: null,
    max: null
  },
  stories: {
    min: null,
    max: null
  },
  width: {
    min: null,
    max: null
  },
  depth: {
    min: null,
    max: null
  },
  residential: {
    min: null,
    max: null
  },
  commercial: {
    min: null,
    max: null
  },
  lotSqft: {
    min: null,
    max: null
  },
  lotDepth: {
    min: null,
    max: null
  },
  lotWidth: {
    min: null,
    max: null
  }
}

export function setSearchToOther() {
  searchFilters.relativeSqftName = 'other'
  searchFilters.radiusName = 'other'
  searchFilters.saleDateName = 'other'
}

export function resetDefaultSearchFilter() {
  searchFilters.relativeSqftName = '10'
  searchFilters.radiusName = 'mile'
  searchFilters.saleDateName = '12'
  searchFilters.relativeSqft = 10
  searchFilters.radius = 1
  searchFilters.date = {
    min: moment().subtract(1, 'years').format('YYYY-MM-DD'),
    max: ''
  }
}

export function resetUnitFilter() {
  resetDefaultSearchFilter()
  searchFilters.residential = {
    min: null,
    max: null
  }
  searchFilters.commercial = {
    min: null,
    max: null
  }
}

export const LenderProspectingMode = {
  NONE: 'None',
  LENDER_SUMMARY: 'Lender Summary',
  LENDER_TXS: 'Lender Transactions',
  BORROWER_TXS: 'Borrower Transactions',
  BORROWER_SUMMARY: 'Borrower Summary'
}

export const MapMode = {
  SEARCH: 'search',
  COMPS: 'comps',
  COMBINEDCOMPS: 'combined',
  PROSPECTING: 'prospecting'
}

export const CompTypeOption = {
  NONE: 'NONE',
  DEED: 'DEED',
  MORTGAGE: 'MTGE',
  RENT: 'RENT',
  LISTING: 'LISTING',
  NEIGHBORHOOD: 'NEIGHBORHOOD'
}

export const CompBucketOption = {
  LOW: 'Low',
  MIDDLELOW: 'MiddleLow',
  MIDDLETOP: 'MiddleTop',
  MIDDLE: 'Middle',
  MIDDLEBOTTOM: 'MiddleBottom',
  MIDDLEHIGH: 'MiddleHigh',
  HIGH: 'High',
  EXTREME: 'Extreme',
  DISTRESS: 'Distress',
  REMOVED: 'Removed',
  APPRAISER: 'Appraiser',
  SUBJECT: 'Subject'
}

export const ALL_COMP_BUCKETS = [CompBucketOption.REMOVED, CompBucketOption.DISTRESS, CompBucketOption.LOW, CompBucketOption.MIDDLELOW, CompBucketOption.MIDDLE, CompBucketOption.MIDDLEHIGH, CompBucketOption.HIGH, CompBucketOption.EXTREME]
export const EXTENDED_COMP_BUCKETS = [CompBucketOption.DISTRESS, CompBucketOption.LOW, CompBucketOption.MIDDLELOW, CompBucketOption.MIDDLE, CompBucketOption.MIDDLEHIGH, CompBucketOption.HIGH, CompBucketOption.EXTREME]
export const MAIN_COMP_BUCKETS = [CompBucketOption.LOW, CompBucketOption.MIDDLELOW, CompBucketOption.MIDDLE, CompBucketOption.MIDDLEHIGH, CompBucketOption.HIGH]
export const OUTER_COMP_BUCKETS = [CompBucketOption.DISTRESS, CompBucketOption.LOW, CompBucketOption.HIGH, CompBucketOption.EXTREME]

export const DATE_BUCKET_COLORS = ["#21ba45", "#4bb425", "#65ae00", "#7ca600", "#909e00", "#a39400", "#b58800", "#c67b00", "#d76b00", "#e65700", "#f33d00", "#ff0000"]
export const LTDS_CONFIDENCE_COLORS = ["#ff0000", "#ff0000", "#f74004", "#f74004", "#dfde10", "#dfde10", "#a8d714", "#48c81b", "#48c81b", "#21ba45", "#21ba45"]

export const CompType = {
  DEED: 1,
  MORTGAGE: 2
}

export const DistanceBucketColors = {
  1: CompBucketOption.LOW,
  2: CompBucketOption.MIDDLELOW,
  3: CompBucketOption.MIDDLE,
  4: CompBucketOption.MIDDLEHIGH,
  5: CompBucketOption.HIGH
}

export const SectorBucketColors = {
  1: CompBucketOption.LOW,
  2: CompBucketOption.MIDDLE,
  3: CompBucketOption.HIGH
}

export const SignificantBucketColors = {
  1: CompBucketOption.HIGH,
  2: CompBucketOption.MIDDLE,
  3: CompBucketOption.LOW
}

export const LTDSBucketColors = {
  1: CompBucketOption.LOW,
  2: CompBucketOption.MIDDLELOW,
  3: CompBucketOption.MIDDLE,
  4: CompBucketOption.MIDDLEHIGH,
  5: CompBucketOption.HIGH
}

export function isDeedType(type) {
  return type === CompType.DEED
}

export const emailExpression = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g

export const nameExpression = /\b(\w)/g

export const entityExpression = /,?\s*(llc|inc|co|corp|mortgage)\.?$/i

export const newLineExpression = /\r?\n|\r/g

export const leadingZeroExpression = /^0+/

export const property_shark_basemap = 'mapbox://styles/andreid20/cjqnl2uua1i7p2rqs4aq0hftf'
export const streets_basemap = 'mapbox://styles/julius-ercm/ckebi88nw1agq1ao19l2345ux'
export const mute_basemap = 'mapbox://styles/julius-ercm/ckgi7x0gv2bfo1angveo8z47v'
export const satelite_basemap = 'mapbox://styles/julius-ercm/ckebhztr60vph19rm2c2ibuut'
export const blueprint_basemap = 'mapbox://styles/julius-ercm/ckebgq6gd18js19mr9t1gsppn';
export const frank_basemap = 'mapbox://styles/julius-ercm/ckebgzbpz18v719rui2tunfpo'

export function cleanZeroes(str) {
  return str.replace(leadingZeroExpression, '')
}
  export const defaultOptions = [
    {
      key: 'yes',
      text: 'Yes',
      value: true,
    },
    {
      key: 'no',
      text: 'No',
      value: false,
    }
  ]

  export const proximityOptions = [
    {
      key: 'Not Available',
      text: 'Not Available',
      value: 0,
    },
    {
      key: 'Detached',
      text: 'Detached',
      value: 1,
    },
    {
      key: 'Semi-attached',
      text: 'Semi-attached',
      value: 2,
    },
    {
      key: 'Attached',
      text: 'Attached',
      value: 3,
    },
  ]

  export const constructionOptions = [
    {
      key: "Brick",
      text: "Brick",
      value: "Brick"
  },
    {
      key: "C Blck + Brick",
      text: "C Blck + Brick",
      value: "C Blck + Brick"
  },
    {
      key: "Cinder Block",
      text: "Cinder Block",
      value: "Cinder Block"
  },
    {
      key: "Fireprof Steel",
      text: "Fireprof Steel",
      value: "Fireprof Steel"
  },
    {
      key: "Fireproof",
      text: "Fireproof",
      value: "Fireproof"
  },
    {
      key: "Fireproof Unsp",
      text: "Fireproof Unsp",
      value: "Fireproof Unsp"
  },
    {
      key: "Frame",
      text: "Frame",
      value: "Frame"
  },
    {
      key: "Iron",
      text: "Iron",
      value: "Iron"
  },
    {
      key: "Light Metal",
      text: "Light Metal",
      value: "Light Metal"
  },
    {
      key: "Masonry",
      text: "Masonry",
      value: "Masonry"
  },
    {
      key: "N/A",
      text: "N/A",
      value: "N/A"
  },
    {
      key: "None",
      text: "None",
      value: "None"
  },
    {
      key: "Non-Firprof St",
      text: "Non-Firprof St",
      value: "Non-Firprof St"
  },
    {
      key: "Other",
      text: "Other",
      value: "Other"
  },
    {
      key: "Reinfor Conc",
      text: "Reinfor Conc",
      value: "Reinfor Conc"
    },
    {
      key: "Stone",
      text: "Stone",
      value: "Stone"
    },
    {
      key: "Wood",
      text: "Wood",
      value: "Wood"
    }
]

export const PlutoCounties = ['Kings County', 'Bronx County', 'New York County', 'Queens County', 'Richmond County']
export const PlutoCities = ['NEW YORK', 'BRONX', 'BROOKLYN', 'QUEENS', 'STATEN ISLAND']