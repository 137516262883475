export const LineDirection = {
  NORTH: 0,
  SOUTH_WEST: 1,
  SOUTH_EAST: 2
}

export function createGeoJSONLine(center, distance, lineDirection = LineDirection.NORTH) {
  var coords = {
    latitude: center[1],
    longitude: center[0]
  }

  var radiusInMiles = distance
  var km = 1.60934 * radiusInMiles

  var ret = [center];
  var distanceX = km / (111.320 * Math.cos(coords.latitude * Math.PI / 180));
  var distanceY = km / 110.574;

  if (lineDirection === LineDirection.NORTH) ret.push([coords.longitude, coords.latitude + distanceY])
  if (lineDirection === LineDirection.SOUTH_EAST) ret.push([coords.longitude + (distanceX * .8660254037844), coords.latitude + (distanceY * -.5)])
  if (lineDirection === LineDirection.SOUTH_WEST) ret.push([coords.longitude + (distanceX * -.8660254037844), coords.latitude + (distanceY * -.5)])

  return {
    "type": "geojson",
    "data": {
      "type": "FeatureCollection",
      "features": [{
        "type": "Feature",
        "geometry": {
          "type": "LineString",
          "coordinates": ret
        }
      }]
    }
  };
}

// [[-73.9372418, 40.6909819],
// [-73.9372418, 40.701273426283166],
// [-73.9372418, 40.705536316047166],
// [-73.9372418, 40.701273426283166]]

export function addLineToMap(m, id) {
  m.addLayer({
    "id": id,
    "type": "line",
    "source": id,
    'layout': {
      'line-join': 'round',
      'line-cap': 'round'
    },
    'paint': {
      'line-color': '#808080',
      'line-width': 1,
      'line-opacity': 1
    }
  });
}

export function createGeoJSONCircle(center, radiusInMiles, points) {
  if (!points) points = 64;

  var coords = {
    latitude: center[1],
    longitude: center[0]
  };

  var km = 1.60934 * radiusInMiles

  var ret = [];
  var distanceX = km / (111.320 * Math.cos(coords.latitude * Math.PI / 180));
  var distanceY = km / 110.574;

  var theta, x, y;
  for (var i = 0; i < points; i++) {
    theta = (i / points) * (2 * Math.PI);
    x = distanceX * Math.cos(theta);
    y = distanceY * Math.sin(theta);

    ret.push([coords.longitude + x, coords.latitude + y]);
  }
  ret.push(ret[0]);

  return {
    "type": "geojson",
    "data": {
      "type": "FeatureCollection",
      "features": [{
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [ret]
        }
      }]
    }
  };
};

export function addCircleToMap(m, id, color, opacity = .8, fill = 'transparent') {
  m.addLayer({
    "id": id,
    "type": "fill",
    "source": id,
    "layout": {},
    "paint": {
      'fill-color': fill,
      'fill-outline-color': color,
      "fill-opacity": opacity
    }
  });
}

export function removeSourceFromMap(m, id) {
  if (!m) return
  if (m.getLayer(id)) {
    m.removeLayer(id);
  }

  if (m.getSource(id)) {
    m.removeSource(id);
  }
}
