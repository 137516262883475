import React from "react"
import {VictoryChart, VictoryLine, VictoryScatter, VictoryAxis, VictoryTheme, VictoryTooltip, VictoryZoomContainer, VictoryLabel} from 'victory';
import { bucketColor } from "../Comps";
import { CompBucketOption } from "../../utility/Global";
import { formatNumber, formatShortNumber } from "../../utility/Helper";
import { min, max } from "../../utility/MathHelper";


const SummaryLTDSChart = ({chartData, cHeight = 450, cWidth = 450, scatterSize = 3.5}) => {
    let {data, stdInfo} = chartData
    const allY = data.filter(_ => _.y).map(_ => _.y)

    const maxY = allY.length > 0 ? max(allY) : 0
    const minY = allY.length > 0 ? min(allY) : 0
    
    const {low, avg, high, lowAvg, highAvg} = stdInfo || {}

    let lowBucket = {
        color:  bucketColor(CompBucketOption.LOW),
        data: [
        {x : 0, y: low  ?? 0},
        {x : 100, y: low ?? 0}
      ]
    }

    let middleBucket = {
        color:  'grey',
        data: [
        {x : 0, y: avg ?? 0},
        {x : 100, y: avg ?? 0}
      ]
    }

    let highBucket = {
      color:  bucketColor(CompBucketOption.HIGH),
      data: [
        {x : 0, y: high ?? 0},
        {x : 100, y: high ?? 0}
      ]
    }

    let quarterLowBucket = {
        color: bucketColor(CompBucketOption.MIDDLELOW),
        data: [
          {x : 0, y: lowAvg ?? 0},
          {x : 100, y: lowAvg ?? 0}
        ]
      }

    let quarterHighBucket = {
        color: bucketColor(CompBucketOption.MIDDLEHIGH),
        data: [
          {x : 0, y: highAvg ?? 0},
          {x : 100, y: highAvg ?? 0}
        ]
      }


    let bucketLines =  [lowBucket, quarterLowBucket, middleBucket, quarterHighBucket, highBucket]
    //console.log(minYToUse, maxYToUse)

    return (
        <VictoryChart
        width={cWidth}
        height={cHeight}
        theme={VictoryTheme.material}
        containerComponent={<VictoryZoomContainer />}
        domain={{ y: [minY-50000, maxY+50000] }}
        >
        <VictoryAxis
            //width={400}
            //height={400}            
            label='LQC Score'
            domain={[0, 100]}
            theme={VictoryTheme.material}
            //offsetY={200}
            standalone={false}
            style={{
                //tickLabels: {fontSize: 6, padding: 8},
                axisLabel: { padding: 40}
              }}
            tickFormat={(t) => `${t}%`}
        />
        <VictoryAxis dependentAxis
            //width={400}
            //height={400}
            minDomain={0}
            label='Price'
            //domain={[minYToUse, maxYToUse]}
            theme={VictoryTheme.material}
            offsetX={cWidth/2}
            standalone={false}
            style={{
                tickLabels: {padding: ((cWidth/2)-60)},
                axisLabel: {padding: ((cWidth/2)-12.5)},
              }}
            tickCount={10}
            tickFormat={(t) => t < 0 ? '' : `$${formatShortNumber(t)}`}
        />

        {bucketLines.map((_, index) => {
            return <VictoryLine
            key={index}
            animate={{
                duration: 1000,
                onLoad: { duration: 500 }
            }}
            interpolation='linear'
            style={{
                data: { strokeWidth: 1, stroke: _.color },
                //parent: { border: `11px solid #ccc`}
                //labels:{fill: _.color}
            }}
            data={_.data}
            //labelComponent={<VictoryLabel renderInPortal dy={-20}/>}
          />   
        })}

            <VictoryScatter
                labels={({ datum }) => datum.label}
                style={{
                    data: {
                        fill: ({ datum }) => datum.color,
                        //stroke: ({ datum }) => datum.color,
                        //fillOpacity: 0.7,
                        //strokeWidth: 3
                        
                    },
                    labels: { fill: "white", fontSize: 12 }
                }}
                size={scatterSize}
                data={data}
                labelComponent={<VictoryLabel dy={7} />}
            />



    </VictoryChart>
    )
}

export default React.memo(SummaryLTDSChart)