import BucketCalc from "./BucketCalc"
import { getBucketFromScore } from "./components/Comps"
import { PropertyCondition } from "./components/extras/ValueContainer"
import { CompBucketOption, EXTENDED_COMP_BUCKETS } from "./utility/Global"
import { calculatePercentDifference, deepClone } from "./utility/Helper"

export const AppraisalCondition = {
    C1: 1,//PropertyCondition.Excellent,
    C2: 2,//PropertyCondition.Excellent,
    C3: 3,//PropertyCondition.Fair,
    C4: 4,//PropertyCondition.Fair,
    C5: 5,//PropertyCondition.Poor,
    C6: 6//PropertyCondition.Distressed,
}

export const AppraisalToPropertyCondition = (condition) => {
    switch (condition) {
        case AppraisalCondition.C1:
        case AppraisalCondition.C2:
            return PropertyCondition.Excellent
        case AppraisalCondition.C3:
        case AppraisalCondition.C4:
            return PropertyCondition.Fair
        case AppraisalCondition.C5:
        case AppraisalCondition.C6:
            return PropertyCondition.Poor
    }
}

class FinalBuckets {
    static getBestBucket(buckets, analytics) {
        if (buckets) {
            const {lowBucket, middleBucket, highBucket} = buckets
            const {composyteDistanceBucket, composyteLTDSBucket, composyteSectoreBucket} = analytics
            const allBuckets = [lowBucket, middleBucket, highBucket, composyteDistanceBucket, composyteLTDSBucket, composyteSectoreBucket].filter(_ => _)
            allBuckets.sort((a, b) => a?.ltdsQualityClusterScore < b?.ltdsQualityClusterScore ? 1 : -1)
            let bestBucket = allBuckets[0]

            if (bestBucket?.bucket === CompBucketOption.DISTRESS) bestBucket = allBuckets[1]

            if (analytics.composyteDistanceBucket) {
                if (bestBucket.title && bestBucket.title.toLowerCase() === '0.125 miles') {
                    var percentDifference = calculatePercentDifference(bestBucket.ltdsQualityClusterScore, analytics.composyteDistanceBucket.ltdsQualityClusterScore)
                    if (percentDifference < 10) bestBucket = analytics.composyteDistanceBucket
                }
            }

            return bestBucket
        } 
        return null
    }

    static getAveragePrice(buckets, analytics) {
        return buckets && BucketCalc.selectedPriceToUse(this.getBestBucket(buckets, analytics))
    }

    static bucketForCondition(finalBuckets, condition, num = 1) {
        if (!finalBuckets) return null

        let isPrimary = num === 1
        let isSecondary = num === 2
        let isTertiary = num === 3

        const buckets = isPrimary ? this.getPrimaryAppraisalBuckets(finalBuckets) : isSecondary ? this.getSecondaryAppraisalBuckets(finalBuckets) : isTertiary ? this.getTertiaryAppraisalBuckets(finalBuckets) : this.getPrimaryAppraisalBuckets(finalBuckets)
        const {lowBucket, middleBucket, highBucket} = finalBuckets
        let allBuckets = [lowBucket, middleBucket, highBucket]
        let highIsBest = false
        allBuckets = allBuckets.filter(_ => _).sort((a, b) => a?.ltdsQualityClusterScore < b?.ltdsQualityClusterScore ? 1 : -1)
        if (highBucket && highBucket?.bucket === CompBucketOption.MIDDLEHIGH) {
            if (highBucket?.bucket === allBuckets[0]?.bucket) highIsBest = true
        }
        switch (condition) {
            case AppraisalCondition.C1:
                return buckets.c1
            case AppraisalCondition.C2:
                return buckets.c2
            case AppraisalCondition.C3:
                return buckets.c3
            case AppraisalCondition.C4:
                return buckets.c4
            case AppraisalCondition.C5:
                return buckets.c5
            case AppraisalCondition.C6:
                return buckets.c6
        }
    }

    static getPrimaryAppraisalBuckets(buckets) {
        const {lowBucket, middleBucket, highBucket, excludedBuckets} = buckets || {}
        let allBuckets = [lowBucket, middleBucket, highBucket, ...excludedBuckets ?? []].filter(_ => _)

        return this.getAppraisalsBuckets(allBuckets)
    }

    static getSecondaryAppraisalBuckets(buckets) {
        const {clusterBuckets} = buckets || {}

        return this.getAppraisalsBuckets(clusterBuckets)
    }

    static getTertiaryAppraisalBuckets(buckets) {
        const {combinedBuckets} = buckets || {}

        if (!combinedBuckets || combinedBuckets.lengh === 0) return {}

        let tmpCombined = [...combinedBuckets].sort((a,b) => a?.sortedClusterScore < b?.sortedClusterScore ? 1 : -1)

        let enoughBuckets = tmpCombined.length >= 4

        var c1, c2, c3, c4, c5, c6
        let appraisalBuckets = {c1, c2, c3, c4, c5, c6}

        let startingBuckets = tmpCombined.filter(_ => [CompBucketOption.EXTREME, CompBucketOption.HIGH, CompBucketOption.MIDDLEHIGH, CompBucketOption.MIDDLE].includes(_.bucket)).splice(0, 1)
        let startingBucket = startingBuckets && startingBuckets.length > 0 ? startingBuckets[0] : null
        if (!startingBucket) {
            return this.getAppraisalsBuckets(combinedBuckets)
        }
        
        //if (allBuckets) tmpCombined.splice(tmpCombined.length-1)
        let startingIndex = 2

        if (startingBucket.bucket === CompBucketOption.EXTREME) {
            startingIndex = 0
        } else if (startingBucket.bucket === CompBucketOption.HIGH) {
            startingIndex = 1
        }

        if (!enoughBuckets) {
            let a = appraisalBuckets[`c${startingIndex+1}`]

            a = deepClone(startingBucket)
            BucketCalc.setHighestSelected(a)

            let b = appraisalBuckets[`c${startingIndex+2}`]

            b = deepClone(a)
            BucketCalc.setLowestSelected(b)

            appraisalBuckets[`c${startingIndex+1}`] = a
            appraisalBuckets[`c${startingIndex+2}`] = b
        } else {
            let a = appraisalBuckets[`c${startingIndex+1}`]

            a = deepClone(startingBucket)
            BucketCalc.setHighestSelected(a)

            appraisalBuckets[`c${startingIndex+1}`] = a
        }

        //console.log(c1, c2, c3, c4, c5, c6)

        let bucketOrderedList = [...EXTENDED_COMP_BUCKETS];
        bucketOrderedList.reverse();

        let bucketIndex = bucketOrderedList.indexOf(startingBucket?.bucket)

        let previousBucketIndex = startingIndex - 1
        for (var i = bucketIndex - 1; i >= 0; i--) {
            var bucket = bucketOrderedList[i]
            var found = tmpCombined.find(_ => _.bucket === bucket)
            if (found) {
                let a = appraisalBuckets[`c${previousBucketIndex+1}`]
                if (!a) {
                    a = found

                    appraisalBuckets[`c${previousBucketIndex+1}`] = a
                    previousBucketIndex--
                    if (previousBucketIndex < 0) break
                }
            }
        }

        let nextBucketIndex = startingIndex + (enoughBuckets ? 1 : 2)
        for (var i = bucketIndex + 1; i < bucketOrderedList.length; i++) {
            var bucket = bucketOrderedList[i]
            var found = tmpCombined.find(_ => _.bucket === bucket)
            if (found) {
                let a = appraisalBuckets[`c${nextBucketIndex+1}`]
                if (!a) {
                    a = found

                    appraisalBuckets[`c${nextBucketIndex+1}`] = a
                    nextBucketIndex++
                    if (nextBucketIndex > 5) break
                }
            }
        }

        Object.values(appraisalBuckets).forEach((_, index) => {
            if (_) {
                _.label = `C${index+1}`
            }
        })

        let groupedBuckets = {}

        Object.keys(appraisalBuckets).forEach((_, index) => {
            let bucket = appraisalBuckets[_]
            if (bucket) {
                let actualBucket = getBucketFromScore(BucketCalc.selectedZToUse(bucket))
                if (!groupedBuckets[actualBucket]) {
                    groupedBuckets[actualBucket] = []
                }
                groupedBuckets[actualBucket].push(bucket)
            }
        })

        Object.keys(groupedBuckets).forEach((_, index) => {
            let group = groupedBuckets[_]
            if (group.length > 1) {
                group.sort((a,b) => a?.ltdsQualityClusterScore > b?.ltdsQualityClusterScore ? 1 : -1)
                let lowerBucketToRemove = group[0]?.label?.toLowerCase()
                appraisalBuckets[lowerBucketToRemove] = null
            }
        })

        return appraisalBuckets
    }

    static getAppraisalsBuckets(buckets, bypassGreater = false) {
        var c1, c2, c34, c5, c6

        var c1Buckets = buckets.filter(_ => _.bucket === CompBucketOption.EXTREME || _.bucket === CompBucketOption.HIGH)
        if (c1Buckets && c1Buckets.length > 0) {
            c1Buckets.sort((a, b) => a?.ltdsQualityClusterScore < b?.ltdsQualityClusterScore ? 1 : - 1)
            c1 = c1Buckets[0]
        }
        if (!c1) c1 = buckets.find(_ => _.bucket === CompBucketOption.MIDDLEHIGH)
        
        var c2Buckets = buckets.filter(_ => _.bucket === CompBucketOption.HIGH || _.bucket === CompBucketOption.MIDDLEHIGH)
        if (c2Buckets && c2Buckets.length > 0) {
            c2Buckets.sort((a, b) => a?.ltdsQualityClusterScore < b?.ltdsQualityClusterScore ? 1 : - 1)
            c2 = c2Buckets[0]

            if (c2?.bucket !== CompBucketOption.MIDDLEHIGH && c1?.bucket !== CompBucketOption.MIDDLEHIGH) {
                if (c2Buckets.length > 1) {
                    c2 = c2Buckets[1]
                }
            }
        }
        if (!c2) c2 = buckets.find(_ => _.bucket === CompBucketOption.MIDDLE)

        var c34Buckets = (c2?.bucket === CompBucketOption.MIDDLE) ? buckets.filter(_ => _.bucket === CompBucketOption.MIDDLELOW) :
                        buckets.filter(_ => _.bucket === CompBucketOption.MIDDLE)
        if (c34Buckets && c34Buckets.length > 0) {
            c34Buckets.sort((a, b) => a?.ltdsQualityClusterScore < b?.ltdsQualityClusterScore ? 1 : - 1)
            c34 = c34Buckets[0]
        }
        if (!c34) c34 = buckets.find(_ => _.bucket === CompBucketOption.MIDDLELOW)

        var c5Buckets = (c34?.bucket == CompBucketOption.MIDDLELOW) ? buckets.filter(_ => _.bucket === CompBucketOption.LOW)
                        : buckets.filter(_ => _.bucket == CompBucketOption.MIDDLELOW || _.bucket == CompBucketOption.LOW)
        if (c5Buckets && c5Buckets.length > 0) {
            c5Buckets.sort((a, b) => a?.ltdsQualityClusterScore < b?.ltdsQualityClusterScore ? 1 : - 1)
            c5 = c5Buckets[0]
        }
        if (!c5) c5 = buckets.find(_ => _.bucket === CompBucketOption.DISTRESS)

        var c6Buckets = buckets.filter(_ => _.bucket === CompBucketOption.DISTRESS)
        if (c6Buckets && c6Buckets.length > 0) {
            c6 = c6Buckets[0]
        }
        if (!c6) {
            c6Buckets = buckets.filter(_ => _.bucket === CompBucketOption.LOW)
            if (c6Buckets && c6Buckets.length > 0) {
                c6 = c6Buckets[0]
            }
        }
        if (!c6) c6 = c5

        if (!c6 && !c5 && !c34) {
            c34 = c2
            c2 = null
        }

        if (c5 && c6) {
            if (BucketCalc.selectedPriceToUse(c6) > BucketCalc.selectedPriceToUse(c5)) {
                if (bypassGreater) {
                    BucketCalc.swapSelectedPrices(c6)
                } else {
                    c5 = c6
                }
            }
        }
        if (c34 && c5) {
            if (BucketCalc.selectedPriceToUse(c5) > BucketCalc.selectedPriceToUse(c34)) {
                if (bypassGreater) {
                    BucketCalc.swapSelectedPrices(c5)
                } else {
                    c34 = c5
                }
            }
        }
        if (c2 && c34) {
            if (BucketCalc.selectedPriceToUse(c34) > BucketCalc.selectedPriceToUse(c2)) {
                if (bypassGreater) {
                    BucketCalc.swapSelectedPrices(c34)
                } else {
                    c2 = c34
                }
            }
        }
        if (c1 && c2) {
            if (BucketCalc.selectedPriceToUse(c2) > BucketCalc.highestPriceToUse(c1)) {
                if (bypassGreater) {
                    BucketCalc.swapSelectedPrices(c2)
                } else {
                    c1 = c2
                }
            }
        }

        var c3 = deepClone(c34)
        var c4 = deepClone(c3)
        if (c4) delete BucketCalc.selectedPrice(c4).cappedPrice        
       
        if (c1 && c2) {
            if (c1.bucket === c2.bucket) {
                let priceOfC3 = BucketCalc.selectedPriceToUse(c3);
                let secondaryPrice = BucketCalc.secondaryPriceToUse(c2);
                let primaryPrice = BucketCalc.selectedPriceToUse(c1);
                if (primaryPrice > secondaryPrice && secondaryPrice > priceOfC3) {
                    c2 = deepClone(c2)
                    BucketCalc.swapSelectedPrices(c2)
                }
            }
        }

        if (c3 && c4) {
            if (c3.bucket === c4.bucket) {
                let priceOfC5 = BucketCalc.selectedPriceToUse(c5);
                let secondaryPrice = BucketCalc.secondaryPriceToUse(c4);
                let primaryPrice = BucketCalc.selectedPriceToUse(c3);
                if (primaryPrice > secondaryPrice && secondaryPrice > priceOfC5) {
                    c4 = deepClone(c4)
                    BucketCalc.swapSelectedPrices(c4)
                }
            }
        }

        if (c5 && c6) {
            if (c5.bucket === c6.bucket) {
                let secondaryPrice = BucketCalc.secondaryPriceToUse(c6);
                let primaryPrice = BucketCalc.selectedPriceToUse(c5);
                if (primaryPrice > secondaryPrice) {
                    c6 = deepClone(c6)
                    BucketCalc.swapSelectedPrices(c6)
                }
            }
        }

        if (c1) c1.label = 'C1'
        if (c2) c2.label = 'C2'
        if (c3) c3.label = 'C3'
        if (c4) c4.label = 'C4'
        if (c5) c5.label = 'C5'
        if (c6) c6.label = 'C6'

        return {
            c1,c2,c3,c4,c5,c6
        }
    }
}

export default FinalBuckets