const subway_yellow = "rgba(252, 204, 10, 1)" // N
const subway_gray = "rgba(167, 169, 172, 1)" // L
const subway_brown = "rgba(153, 102, 51, 1)" // J
const subway_light_green = "rgba(108, 190, 69, 1)" // G
const subway_orange = "rgba(255, 99, 25, 1)" // B
const subway_blue = "rgba(0, 57, 166, 1)" // A, SI
const subway_purple = "rgba(185, 51, 173, 1)" // 7
const subway_red = "rgba(238, 53, 46, 1)" // 1

const subway_color_lookup = {
  N: subway_yellow,
  L: subway_gray,
  J: subway_brown,
  G: subway_light_green,
  B: subway_orange,
  A: subway_blue,
  SI: subway_blue,
  7: subway_purple,
  1: subway_red
}

export const subway_symbols = `["N", "L", "J", "G", "B", "A", "SI", "7", "1"]`
export const subway_colors = `[${subway_color_lookup["N"]}, ${subway_color_lookup["L"]}, ${subway_color_lookup["J"]}, ${subway_color_lookup["G"]}, ${subway_color_lookup["B"]},
${subway_color_lookup["A"]}, ${subway_color_lookup["SI"]}, ${subway_color_lookup["7"]}, ${subway_color_lookup["1"]}]`