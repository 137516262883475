import React from "react";
import { useMediaQuery } from "react-responsive";
import {
  Container,
  Menu,
  Dropdown,
  Checkbox,
  Modal,
  Divider,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { userSignout, userSignin, getUserDisplayName } from "../utility/Auth";
import { ManageProfileURL } from "../utility/Config";

const MenuTabs = ({ user, userSignin, userSignout }) => (
  <>
    {/* <Menu.Item as={Link} to="/photos">
      Photos
    </Menu.Item> */}
    <Menu.Item as={Link} to="/valuation">
      Valuation
    </Menu.Item>
    <Menu.Item as={Link} to="/fastclose">
      FastClose
    </Menu.Item>
    <Dropdown text="Appraisals" className="link item">
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to="/comp-appraisals">
          Single
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/multi-appraisals">
          Multi
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/history-appraisals">
          History
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    {/* <Menu.Item as={Link} to="/neighborhoods">
      Neighborhoods
    </Menu.Item>
    <Menu.Item as={Link} to="/portfolio">
      My Portfolio
    </Menu.Item>
    <Menu.Item as={Link} to="/investors">
      Investors
    </Menu.Item> */}
    {user ? (
      <Dropdown
        text={getUserDisplayName(user.profile.name)}
        className="link item"
      >
        <Dropdown.Menu>
          <Dropdown.Item as="a" href={ManageProfileURL} target="_blank">
            Profile
          </Dropdown.Item>
          <Dropdown.Item onClick={userSignout}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    ) : (
      <Menu.Item onClick={userSignin}>Login</Menu.Item>
    )}
  </>
);

const NavMenu = ({ isFluid, user }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  return (
    <header className="no-print" style={{ marginBottom: "40px" }}>
      <Menu className="navigation navigation-grey" borderless fixed="top">
        <Container fluid={isFluid}>
          <Menu.Item as={Link} to="/" header>
            {/**<Image size='mini' src='/logo.png' style={{ marginRight: '1.5em' }} />**/}
            Urbyn &nbsp;<span style={{ fontSize: "10px" }}>(vOct.2023)</span>
          </Menu.Item>
          <Menu floated="right" className="no-border transparent">
            {!isMobile ? (
              <>
                <Menu.Item position="right" />
                {/* <span style={{border : '1px solid gray', margin: '4px 0'}} /> */}
                <MenuTabs
                  user={user}
                  userSignin={userSignin}
                  userSignout={userSignout}
                />
              </>
            ) : (
              <>
                <Dropdown item icon="align justify">
                  <Dropdown.Menu>
                    <MenuTabs
                      user={user}
                      userSignin={userSignin}
                      userSignout={userSignout}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </>
            )}
          </Menu>
        </Container>
      </Menu>
    </header>
  );
};

export default NavMenu;
