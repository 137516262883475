import React from 'react';
import { Segment, Label, Grid } from 'semantic-ui-react'
import BucketCalc from '../../BucketCalc';
import { formatNumber } from '../../utility/Helper';
import { PSF_PRICE_LABEL, bucketColor } from '../Comps';
import { FormattedInputType } from './InputElements';

export const PropertyCondition = {
    All: 0,
    Excellent: 3,
    Fair: 2,
    Poor: 1,
    LTDS: 4,
    Distance: 5,
    Sector: 6
}

export const ComposyteTitles = ['LTDS', 'Distance', 'Sector']

export function getPropertyCondition(condition) {
    if (Number.isInteger(condition)) return condition
    switch (condition.toLowerCase()) {
        case 'excellent':
            return PropertyCondition.Excellent
        case 'fair':
            return PropertyCondition.Fair
        case 'poor':
            return PropertyCondition.Poor
        case 'distressed':
            return PropertyCondition.Distressed
    }
}

function getFormattedType(value, type) {
    let formattedValue = formatNumber(value)
    switch (type) {
        case FormattedInputType.MONEY:
            return '$' + formattedValue
        case FormattedInputType.PERCENT:
            return formattedValue + '%'
        default:
            return formattedValue
    }
}

export const ValuationContainer = ({ valuationData, headers=['Low', 'Middle', 'High'], condition = PropertyCondition.All, onLowClick, onMiddleClick, onHighClick }) => {
    const { lowCalc, middleCalc, highCalc } = valuationData || {}
    return (
        <Grid columns='equal'>
            <Grid.Row>
                <Grid.Column>
                    <ValuationBucketContainer header={headers[0]} calc={lowCalc} disabled={condition !== PropertyCondition.All && condition !== PropertyCondition.Poor} onSectionClick={onLowClick} />
                </Grid.Column>
                <Grid.Column>
                    <ValuationBucketContainer header={headers[1]} calc={middleCalc} disabled={condition !== PropertyCondition.All && condition !== PropertyCondition.Fair} onSectionClick={onMiddleClick} />
                </Grid.Column>
                <Grid.Column>
                    <ValuationBucketContainer header={headers[2]} calc={highCalc} disabled={condition !== PropertyCondition.All && condition !== PropertyCondition.Excellent} onSectionClick={onHighClick} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export const ValuationBucketContainer = ({header, calc, disabled, onSectionClick}) => (
    <ValuationValues 
        header={header} 
        calc={calc} 
        disabled={disabled} 
        onSectionClick={onSectionClick}
    />
)

export const ValuationValues = ({ header, calc, disabled, onSectionClick }) => (
    <Segment.Group className={disabled && 'disabled'}>
        <ValueContainer top={header} headerColor={
            calc && calc.bucket
            ? bucketColor(calc.bucket)
            : calc?.color
            } label='' value={BucketCalc.selectedPriceToUse(calc)} onSectionClick={onSectionClick} />
        <Segment.Group className='values-container' horizontal style={{ background: 'white' }}>
            <ValueContainer label='Price/Sqft' value={BucketCalc.psfToUse(calc, PSF_PRICE_LABEL)} />
            <ValueContainer label='Sales' value={calc?.bucketCount || 0} type={FormattedInputType.NONE} />
            <ValueContainer label='Confidence' value={calc?.likenessAverage || 0} type={FormattedInputType.PERCENT} />
        </Segment.Group>
        <Segment.Group className='values-container' horizontal style={{ background: 'white' }}>
            <ValueContainer label='Spread' value={calc?.priceVolatilityPercentage || 0} type={FormattedInputType.PERCENT} />
            <ValueContainer label='Direction' value={calc?.directionalDistributionPercentage || 0} type={FormattedInputType.PERCENT} />
            <ValueContainer label='Avg Dist.' value={calc?.averageDistance || 0} type={FormattedInputType.NONE} />
            <ValueContainer label='Sqft Diff.' value={calc?.averageDifferentials || 0} type={FormattedInputType.PERCENT} />
        </Segment.Group>
    </Segment.Group>
)

export const ValueContainer = ({ color = '', headerColor = '', top = null, label, value, type = FormattedInputType.MONEY, onSectionClick }) => (
    <Segment textAlign='center' className={`valuation-value-container`} style={onSectionClick ? {cursor: 'pointer'} : null} onClick={onSectionClick} >
        {top && <Label style={{backgroundColor: headerColor, color: `${headerColor ? 'white' : ''}`}} attached='top'>{top}</Label>}
        <Label className='valuation-value-label'>{label}</Label>
        <Label size='large' className={`valuation-value-label ${color}`}>{value && getFormattedType(value, type)}</Label>
    </Segment>
)