import React from "react"
import { useState } from "react";
import {VictoryChart, VictoryStack, VictoryBar, VictoryAxis, VictoryTheme, VictoryTooltip, VictoryGroup, VictoryLine, VictoryVoronoiContainer, VictoryScatter} from 'victory';
import { getMonthName } from "../../utility/Global";
import { formatNumber, formatShortNumber } from "../../utility/Helper";

const cartesianInterpolations = [
    "basis",
    "bundle",
    "cardinal",
    "catmullRom",
    "linear",
    "monotoneX",
    "monotoneY",
    "natural",
    "step",
    "stepAfter",
    "stepBefore"
  ];

  const InterpolationSelect = ({ currentValue, values, onChange }) => (
    <select onChange={onChange} value={currentValue} style={{ width: 75 }}>
      {values.map(
        (value) => <option value={value} key={value}>{value}</option>
      )}
    </select>
  );

const HistoryChart = ({historicalData}) => {
    const [interpolation, setInterpolation] = useState('basis')
    const {chartData, bestClusters} = historicalData || {}
    let lineData = chartData.filter(_ => _.data && _.data.length > 0)

    console.log(lineData)
    console.log(bestClusters)

    return (
        <>
        <InterpolationSelect
          currentValue={interpolation}
          values={cartesianInterpolations }
          onChange={(event) => setInterpolation(event.target.value)}
        />
        <VictoryChart
        theme={VictoryTheme.material}
        width={700}
        height={450}
        domainPadding={{ y: 10 }}
        containerComponent={
            <VictoryVoronoiContainer
                voronoiBlacklist={['scatter']}
                labels={({ datum }) => `$${formatShortNumber(datum.y)}\n${formatNumber(datum.lqc)}\n${formatNumber(datum.z)}`}
            />
          }
        //maxDomain={{x: totalDistance}}
        >
            <VictoryAxis dependentAxis tickFormat={(t) => `$${formatShortNumber(t)}`} />
            <VictoryAxis
                tickCount={16}
                //tickValues={}
                tickFormat={((t) => getMonthName(new Date(t).getMonth()+1))}
                //tickFormat={(t) => getMonthName(t.split('/')[1])}
            />
            <VictoryGroup offset={5}>
            {lineData.map((line, index) => {
                return <VictoryLine
                    key={index}
                    data={line.data}
                    style={{ data: { 
                        stroke: line.color,
                        //stroke: "black",
                        //strokeWidth: 1
                    } }}
                    interpolation={interpolation}
                />
                })}
            </VictoryGroup>
            <VictoryScatter name='scatter' size={4} data={bestClusters} 
            style={{ data: { 
                fill: ({ datum }) => datum.color,
                //stroke: "black",
                //strokeWidth: 1
                } }}
               />
        </VictoryChart>
        </>
    )
}

export default React.memo(HistoryChart)