import React, { useState, useEffect, useRef } from "react";
import { Loader } from "semantic-ui-react";
import { classNames } from "../../utility/Helper";

export function getGoogleAddress(search, callback) {
  var sessionToken = new window.google.maps.places.AutocompleteSessionToken();
  const service = new window.google.maps.places.AutocompleteService();

  service.getQueryPredictions({ input: search, sessionToken }, (predictions, status) => {
    //console.log(predictions, status)
    if (status === 'OK') {
      if (predictions.length > 0) {
        var place = predictions[0]
        //console.log(place)
        const {place_id} = place

        var detailRequest = {
          placeId: place_id,
          fields: ["address_components","formatted_address"],
        }
      
        var placeService = new window.google.maps.places.PlacesService(document.createElement('div'));
      
        placeService.getDetails(detailRequest, function(results, status) {
          if (results) {
            const query = results.formatted_address;
            const parts = results.address_components;
            
            if (callback) {
              callback(search, query, parts)
            }
          }
        });
      }
    }
  });
}

function handleScriptLoad(updateQuery, updateParts, onChange, autoCompleteRef, autoComplete) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { componentRestrictions: { country: "us" } }
  );
  autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener("place_changed", () => {
    handlePlaceSelect(updateQuery, updateParts, onChange, autoCompleteRef, autoComplete)
    }
  );
}

async function handlePlaceSelect(updateQuery, updateParts, onChange, autoCompleteRef, autoComplete) {
  const addressObject = autoComplete.getPlace();
  
  const writtenAddress = autoCompleteRef.current.value
  
  const query = addressObject.formatted_address;
  const parts = addressObject.address_components;
  updateQuery(query);
  updateParts(parts)

  onChange(writtenAddress, query, parts)
  //console.log(addressObject);
}

function SearchLocationInput(props) {
  const [query, setQuery] = useState("");
  const [parts, setParts] = useState({});
  const {onChange} = props
  const autoCompleteRef = useRef(null);
  const autoComplete = {}
  const {icon = 'search'} = props

  useEffect(() => {
    handleScriptLoad(setQuery, setParts, onChange, autoCompleteRef, autoComplete)
  }, []);
  
  // useEffect(() => {
  //   onChange(query)
  //   // eslint-disable-next-line
  // }, [query])

  return (
    <div className={
      classNames(props.className, "ui transparent input ui small search info-search")
    }
    style={props.style} 
    >
      <div className="ui right icon input">
      <input
      className="prompt"
      disabled={props.disabled}
        name={props?.name}
        ref={autoCompleteRef}
        onChange={event => setQuery(event.target.value)}
        //onBlur={() => {onChange(query, parts)}}
        placeholder={props?.placeholder}
        defaultValue={props.value}
        size='small'
        transparent
      />
      { !props.isLoading && <i aria-hidden="true" className={`${icon} icon`}></i> }
      </div>
      { props.isLoading && <Loader active inline style={{position: 'absolute', left: '91%', top: '2%'}}/> }
    </div>
  );
}

export default SearchLocationInput;