import React from "react"
import {VictoryChart, VictoryStack, VictoryBar, VictoryAxis, VictoryTheme, VictoryTooltip, VictoryGroup} from 'victory';
import { formatShortNumber } from "../../utility/Helper";

const BarChart = ({chartData, width = 350, height = 350, totalDistance = 1}) => {
    let groupedData = [[],[],[],[]]

    chartData.forEach(data => {
        data.forEach((barData, index) => {
            groupedData[index].push(barData)
        })
    })

    groupedData.forEach(data => {
        data.sort((a, b) => a.x > b.x ? 1 : -1)
    })

    return (
        <VictoryChart
        theme={VictoryTheme.material}
        maxDomain={{x: totalDistance}}
        width={width}
        height={height}
        >
            <VictoryAxis dependentAxis tickFormat={(t) => `$${formatShortNumber(t)}`} />
            <VictoryAxis
                tickValues={[0, totalDistance  * .25, totalDistance * .5, totalDistance * .75, totalDistance]}
                tickFormat={(t) => `${t}mi`}
            />
            <VictoryGroup offset={10}>
            {groupedData.map((data, index) => {
                return <VictoryBar
                    key={index}
                    data={data}
                    style={{ data: { 
                        fill: ({ datum }) => datum.color,
                        //stroke: "black",
                        //strokeWidth: 1
                    } }}
                    labelComponent={<VictoryTooltip />}
                />
                })}
            </VictoryGroup>
            {/* <VictoryBar
                data={chartData}
                style={{ data: { 
                    fill: ({ datum }) => datum.color
                } }}
            /> */}
        </VictoryChart>
    )
}

export default React.memo(BarChart)