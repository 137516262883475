import React, { useEffect } from "react";
import { useContext } from "react";
import { LocationContext } from "../pages/Photos";
import { usePosition } from 'use-position'

function LocationSection() {
    const { userLocation, setUserLocation } = useContext(LocationContext) 

    const {
        latitude,
        longitude,
        speed,
        timestamp,
        accuracy,
        heading,
        error,
      } = usePosition(true, { enableHighAccuracy: true });


    useEffect(() => {
        if (latitude || longitude || error) {
            setUserLocation(
                {   latitude,
                    longitude,
                    speed,
                    timestamp,
                    accuracy,
                    heading,
                    error
                })
        }
    }, [latitude, longitude, speed, timestamp, accuracy, heading, error])

    return (
        <code>
            latitude: {latitude}<br />
            longitude: {longitude}<br />
            speed: {speed}<br />
            timestamp: {timestamp}<br />
            accuracy: {accuracy && `${accuracy} meters`}<br />
            heading: {heading && `${heading} degrees`}<br />
            error: {error}
        </code>
    )
}

export default LocationSection;