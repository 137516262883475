import React, { Component } from 'react';
import Datatable from 'react-data-table-component'
import { Segment, Input, Button, Transition, Select, Checkbox } from 'semantic-ui-react'
import { makeGetRequest } from '../utility/Services';
import { intel_connections_url, BaseURL } from '../utility/Config'
import { customStyles, FilterComponent } from '../utility/TableHelper';
import Spinner from './Loader'
import _ from 'lodash'

const options = [
    {key: 1, value: 1, text: 'INVESTOR'},
    {key: 2, value: 2, text: 'ENTITY'},
    {key: 3, value: 3, text: 'OFFICER'},
    {key: 4, value: 4, text: 'LENDER'},
  ]

// export const ExportButton = ({data}) => (
//     <Button floated='right' type='button' content='Export' onClick={(e) => {downloadCSV(data)}}/>
// )

export class Connections extends Component {
    
        state = {
            data : [],
            initialized: false,
            loading: false,
            searchValue: '',
            optionValue: 1,
            contains: true,
            filterText: '',
            filteredItems: [],
        }
        columns = [
            {
                name: 'Investor',
                selector: 'investor',
                sortable: true,
                //width: '180px'
                grow: 2,
                //cell: row => row.lenders[0]?.name
                cell: row => <span onClick={this.handleInvestorClick.bind(this, row.investor)}>{row.investor}</span>
            },
            // {
            //     name: 'Title',
            //     selector: 'title',
            //     sortable: true,
            //     //width: '200px'
            //     //grow: 1,
            //     //cell: row => <span onClick={this.handleBorrowerClick.bind(this, row.borrower)}>{row.borrower}</span>
            //     //cell: row => row.borrowers[0]?.name
            // },
            {
              name: 'Entity',
              selector: 'entity',
              sortable: true,
              //width: '320px',
              grow: 2,
              cell: row => <span onClick={this.handleBorrowerClick.bind(this, row.entity)}>{row.entity}</span>
              //cell: row => row.parcels[0].displayAddress
            },
            {
              name: 'Entity Address',
              selector: 'entityAddress',
              sortable: true,
              //width: '320px',
              grow: 3
              //cell: row => row.parcels[0].displayAddress
            },
            {
                name: 'Property',
                selector: 'propertyAddress',
                sortable: true,
                //cell: row => row.parcels[0].type
                //width: '72px'
                grow: 2
            },
            {
                name: 'Officer',
                selector: 'officerName',
                sortable: true,
                grow: 1,
                //width: '110px',
                cell: row => row.officer ? <span onClick={this.handleOfficerClick.bind(this, row.officer?.name)}>{row.officer?.name}</span> : null
            },
            {
                name: 'Lender',
                selector: 'lenderName',
                sortable: true,
                grow: 1,
                //width: '110px',
                //format: row => row.documentAmount === 0 ? '-' : `$${formatNumber(row.documentAmount)}`
                cell: row => row.lender ? <span onClick={this.handleLenderClick.bind(this, row.lender?.name)}>{row.lender?.name}</span> : null
            }
          ]
      

          componentDidMount() {
            window.addEventListener("message", (e) => {
              if (e?.origin === BaseURL) {
                if (e.data.isSet === true) {
                  const {searchValue, optionValue} = e.data
                  this.setState({
                    contains: false,
                    initialized: true,
                    optionValue,
                    searchValue
                  }, () => {
                    this.handleQuery()
                  })
                }
              }
            })

            // setTimeout(
            //   () => {
            //     const {initialized} = this.state
            //     if (!initialized) {
            //       this.handleQuery()
            //     }
            //   }, 1000
            // )
          }

          handleInvestorClick = (investor) => {
            this.handleNewTab(1, investor)
          }

          
          handleBorrowerClick = (borrower) => {
            this.handleNewTab(2, borrower)
          }

          handleOfficerClick = (officer) => {
            this.handleNewTab(3, officer)
          }
    
          handleLenderClick = (lender) => {
            this.handleNewTab(4, lender)
          }
    
          handleNewTab = (option, lookup) => {
            const win = window.open("/connections", "_blank");
            setTimeout(() => {
              win.postMessage({ isSet: true, optionValue: option, searchValue: lookup }, BaseURL)
            }, 1000)
          }
    

    handleClear = () => {
        this.setFilter('')
      }

    handleFilter = (e) => {
        const val = e.target.value
        if (val === '') {
          this.handleClear()
          return
        }
        this.setFilter(val)
      }

    setFilter = (val) => {
        const {data} = this.state

        let tmpData = [...data]

        let filteredItems
        if (val !== '') {
          filteredItems = tmpData.filter(item => (
            item.investor?.toLowerCase().includes(val.toLowerCase()) ||
            item.title?.toLowerCase().includes(val.toLowerCase()) ||
            item.propertyAddress?.toLowerCase().includes(val.toLowerCase()) ||
            item.entityAddress?.toLowerCase().includes(val.toLowerCase()) ||
            item.entity?.toLowerCase().includes(val.toLowerCase()) ||
            item.lender?.name?.toLowerCase().includes(val.toLowerCase()) ||
            item.officer?.name?.toLowerCase().includes(val.toLowerCase())
            ))
        } else {
          filteredItems = tmpData
        }

        this.setState({filterText: val, filteredItems})
      }

    customSort = (rows, field, direction) => {
        const handleField = row => {
            if (row[field]) {
                if (typeof row[field] === 'string') {
                    return row[field].toLowerCase();
                }
            }
    
        return row[field];
      };
    
      return _.orderBy(rows, handleField, direction)
    }

    handleQuery = async () => {
        const {searchValue, optionValue, contains} = this.state
  
        if (searchValue.trim().length === 0) {
          return
        }
  
        const _this = this
        this.setState({loading: true}, async () => {
          const data = await makeGetRequest(`${intel_connections_url}?look=${searchValue.trim()}&option=${optionValue}&contains=${contains}`)
          if (data === null) {
            _this.setState({loading: false})
          } else {
            _this.setState({loading: false, data: data, filteredItems: data})
          }
        })
      }

    render () {
        const {filteredItems, filterText, loading, optionValue, searchValue, contains} = this.state
        
        return (
          <div style={{padding: '1em 15em'}}>          
              <Transition
              animation='fly right'
              reactKey='intel-table'
              transitionOnMount={true}
              unmountOnHide={true}
              visible={true}
              >
              <Segment basic>
                 <Segment basic size='mini'>
                  <Select style={{width: '200px', marginRight: '1em'}} options={options} value={optionValue} onChange={(e, {value}) => {this.setState({optionValue: value})}} />
                  <Checkbox label='Contains?' checked={contains} onChange={(e, {checked}) => {this.setState({contains: checked})}} />
                  <Input className='intel-search-input' style={{marginLeft: '2em', width: '25%'}} defaultValue={searchValue} onChange={(e, {value}) => {this.setState({searchValue: value})}} />
                  <Button style={{marginLeft: '1em'}} icon='play' onClick={this.handleQuery}></Button>
                </Segment>
              <Datatable
                title='Intel Connections'
                className='connections-table'
                noHeader={true}
                persistTableHead
                columns={this.columns}
                data={filteredItems}
                highlightOnHover
                pointerOnHover
                customStyles={customStyles}
                defaultSortField='investor'
                defaultSortAsc={true}
                fixedHeader
                fixedHeaderScrollHeight='65vh'
                dense
                striped
                responsive
                progressPending={loading}
                progressComponent={<Spinner active={loading} inline={true} />}
                //conditionalRowStyles={this.conditionalRowStyles()}
                //sortFunction={this.customSort}
                subHeader
                subHeaderAlign='left'
                subHeaderComponent={(
                    <Segment basic style={{width: '100%'}}>
                    <FilterComponent onFilter={this.handleFilter} onClear={this.handleClear} filterText={filterText} />
                    </Segment>
                )}
                pagination
                paginationPerPage={100}
                paginationRowsPerPageOptions={[100]}
              />
              </Segment>
              </Transition> 
          </div>
      );
    }
  }