import React, { Component } from 'react';
import { Container, Segment, Dropdown } from 'semantic-ui-react';
import {calculateRateChange, property, flatten, onlyUnique} from '../utility/Helper'
import {property_api_get_neighborhood_trends_url, property_api_get_neighborhood_sales_url} from '../utility/Config'

import {boroLookup} from '../Tax-Lot'
import { makeGetRequest } from '../utility/Services';
import moment from 'moment';
import Spinner from './Loader';
import SaleChart from './charts/SaleChart';
import RateChangeChart from './charts/RateChangeChart';
import { mean } from '../utility/MathHelper';

const ChartType = {
    Trends: 1,
    Sales: 2
}

const movingAverageInterval = 10

let quarterly = []
for (var d = moment().subtract(5, 'years').toDate(); d <= new Date(); d.setMonth(d.getMonth() + 3)) {
    quarterly.push(moment(d).format('YYYY-MM'));
}

function getTrendsForNeighborhood(trends, neighborhood, borocode, units) {
    return trends.filter(_ => _.neighborhood === neighborhood && _.borocode.toString() === borocode.toString() && _.units.toString() === units.toString())[0]
}

export function getSalesForNeighborhood(sales, neighborhood, borocode) {
    return sales.filter(_ => _.neighborhood === neighborhood && _.borocode.toString() === borocode.toString())[0]
}

function getNeighborhoodOptions(boro) {
    return neighborhoods.filter(_ => _.borocode.toString() === boro.toString()).map(_ => {
        return {
            key: `${_.neighborhood}.${_.borocode}`,
            text: _.neighborhood,
            value: _
        }
    })
}

function getYearOptions(years) {
    return years.map(_ => {
        return {
            key: _,
            text: _,
            value: _
        }
    })
}

let selectedUnits = [1]
let selectedBoro = 3
let selectedNeighborhoods = []
let selectedYears = []

const timeOptions = [
    {
        key: 1,
        text: 'Monthly',
        value: 1
    },
    {
        key: 2,
        text: 'Quarterly',
        value: 2
    }
]
const chartOptions = [
    {
        key: 1,
        text: 'Rate of Change',
        value: 1
    },
    {
        key: 2,
        text: 'Price Per Sqft',
        value: 2
    }
]
const boroughOptions = [1,2,3,4,5].map(_ => {
    return {
        key: _,
        text: boroLookup[_],
        value: _
    }
})
const unitOptions = [1,2,3,4].map(_ => {
    return {
        key: _,
        text: `${_} Units`,
        value: _
    }
})

const chartTypeOptions = [
    {
        key: ChartType.Trends,
        text: 'Trends',
        value: ChartType.Trends
    },
    {
        key: ChartType.Sales,
        text: 'Sales',
        value: ChartType.Sales
    }
]

let trendData = []
let neighborhoods = []
let saleData = []
let years = []

export class Neighborhoods extends Component {
    state = {
        chartType: ChartType.Trends,
        neighborhoodOptions: [],
        yearOptions: [],
        unitOptions: [],
        selectedChartOption: 1,
        selectedTimeOption: 1,
        isLoading: false
    }

   async componentDidMount() {
        const {chartType} = this.state
        await this.getData(chartType)
    }

    async getData(chartType) {
        this.setState({isLoading: true})
        if (chartType === ChartType.Trends) {
            if (trendData.length === 0)
                await this.fetchTrends()
        } else if (chartType === ChartType.Sales) {
            if (saleData.length === 0)
                await this.fetchSales()
        }
        this.setState({isLoading: false})
    }

    fetchTrends = async () => {
        const result = await makeGetRequest(property_api_get_neighborhood_trends_url)
        if (result) {
            const trends = JSON.parse(result.chart)
            trendData = trends.map(_ => _.trend)
        
            this.setNeighborhoods(trendData)
        }
    }

    fetchSales = async () => {
        const result = await makeGetRequest(property_api_get_neighborhood_sales_url)
        if (result) {
            const sales = JSON.parse(result.chart)
            saleData = sales.map(_ => _.sale)

            this.setNeighborhoods(saleData)
            this.setYears(saleData)
        }
    }

    setNeighborhoods (data) {
        if (neighborhoods.length > 0) {
            return
        }
        neighborhoods = data.map(_ => `${_.neighborhood}.${_.borocode}`)
        neighborhoods = neighborhoods.filter(onlyUnique)
        neighborhoods = neighborhoods.map(_ => {
            let n = {}
            let split = _.split('.')
            n.neighborhood = split[0]
            n.borocode = split[1]
            return n
        })

        let neighborhoodOptions = getNeighborhoodOptions(selectedBoro)
        this.setState({neighborhoodOptions})
    }

    setYears(data) {
        if (years.length > 0) {
            return
        }
        
        years = data.map(property("years")).reduce(flatten, []).filter(onlyUnique)        

        years.sort()

        let yearOptions = getYearOptions(years)
        this.setState({yearOptions})
    }

    onOptionChange = (e, {value}) => {
        e.preventDefault()
        this.setState({selectedChartOption: value})
    }

    onTimeChange = (e, {value}) => {
        e.preventDefault()
        this.setState({selectedTimeOption: value})
    }

    onBoroughChange = (e, {value}) => {        
        e.preventDefault()
        selectedNeighborhoods = []
        selectedBoro = value
        let neighborhoodOptions = getNeighborhoodOptions(value)
        this.setState({neighborhoodOptions, chartData: []})
    }

    onUnitChange = (e, {value}) => {
        e.preventDefault()
        selectedUnits = value
        selectedUnits.sort()
        this.updateNeighborhoodChart()
    }

    onNeighborhoodChange = (e, {value}) => {        
        e.preventDefault()
        selectedNeighborhoods = Array.isArray(value) ? value : [value]
        this.updateNeighborhoodChart()
    }

    onYearChange = (e, {value}) => {        
        e.preventDefault()
        selectedYears = value
        selectedYears.sort()
        this.updateNeighborhoodChart()
    }

    onChartTypeChange = async (e, {value}) => {
        e.preventDefault()
        await this.getData(value)

        selectedNeighborhoods = []
        selectedYears = [...years.slice(-3)]
        if (value === ChartType.Trends) {
            selectedUnits = [1]
        } else if (value === ChartType.Sales) {
            selectedUnits = [1,2,3,4]
        }

        this.setState({chartType: value, chartData: []})
    }

    updateNeighborhoodChart = () => {
        const {chartType} = this.state
        let newTrends = []
        let movingAverage = []
        if (chartType === ChartType.Trends) {
            selectedNeighborhoods.forEach(_ => {
                selectedUnits.forEach(__ => {            
                    let t = {...getTrendsForNeighborhood(trendData, _.neighborhood, _.borocode, __)}
                    if (t && t.data) {
                        let rates = []
                        for (var x = 0; x < t.data.length-1; x++) {
                            let a = t.data[x]
                            let b = t.data[x+1]
                            let change = calculateRateChange(b.y, a.y)
                            rates.push({x: b.x, y: change})
                        }
                        let quarterlyData = []
                        for (var x = 0; x < quarterly.length-1; x++) {
                            let startDate = moment(quarterly[x])
                            let endDate = moment(quarterly[x+1])
                            let trendRange = t.data.filter(_ => moment(_.x) >= startDate && moment(_.x) < endDate)
                            
                            let totalAmount = 0
                            let totalDocs = 0
                            for (var y = 0; y < trendRange.length; y++) {
                                let a = trendRange[y].y
                                let b = trendRange[y].total_docs
                                let c = a*b
                                totalAmount += c
                                totalDocs += b
                            }
                            let totalPsf = totalDocs === 0 ? 0 : totalAmount/totalDocs
    
                            quarterlyData.push({x: quarterly[x], y: totalPsf})
                        }
                        let quarterlyChanges = []
                        for (var x = 0; x < quarterlyData.length-1; x++) {
                            let a = quarterlyData[x]
                            let b = quarterlyData[x+1]
                            let change = calculateRateChange(b.y, a.y)
                            quarterlyChanges.push({x: b.x, y: change})
                        }
                        t.rateChanges = rates
                        t.quarterlyData = quarterlyData
                        t.quarterlyChanges = quarterlyChanges
                        t.neighborhood = t.neighborhood + ` (${__})`
                        
                        newTrends.push(t)
                    }
                })
            })
        } else if (chartType === ChartType.Sales) {
            selectedNeighborhoods.forEach(_ => {
                let t = {...getSalesForNeighborhood(saleData, _.neighborhood, _.borocode)}
                    if (t && t.data) {
                        selectedYears.reverse().forEach(year => {  
                            let sale = {}
                            let yearlyData = t.data.filter(_ => _.year === year && selectedUnits.includes(_.units))
                            sale.data = yearlyData.sort((a, b) => (a.y > b.y) ? -1 : 1)
                            sale.neighborhood = t.neighborhood
                            sale.year = year

                            newTrends.push(sale)
                        })     
                    }    
            })
            
            if (newTrends.length > 0) {
                let allData = newTrends.map(property("data")).reduce(flatten, []).sort((a,b) => (a.x > b.x) ? 1 : -1)
            
                if (allData.length > 0) {
                    let lowest = allData[0].x
                    let highest = allData[allData.length-1].x
                    
                    let interval = (highest - lowest)/movingAverageInterval
                    let bottom = lowest
                    for (var x = 0; x < movingAverageInterval; x++) {
                        let top = bottom + interval
                        let values = allData.filter(_ => _.x >= bottom && _.x < top).map(_ => _.y)
                        let avg = mean(values)

                        let h = mean([bottom,top])
                        
                        movingAverage.push({x: h, y: avg})
                        bottom += interval
                    }
                }
            }
        }

        this.setState({chartData: newTrends, movingAverage})
    }
    

    renderTrendCharts() {
        const {neighborhoodOptions, chartData, selectedChartOption, selectedTimeOption} = this.state
        return (
            <>
            <Segment.Group size='mini' horizontal style={{maxHeight: '65px'}}>
                    <Segment style={{maxWidth: '20%'}}>
                    <Dropdown
                        placeholder='Chart'
                        fluid
                        selection
                        defaultValue={selectedChartOption}
                        options={chartOptions}
                        onChange={this.onOptionChange}
                    />
                    </Segment>
                    <Segment style={{maxWidth: '10%'}}>
                    <Dropdown
                        placeholder='Time'
                        fluid
                        selection
                        value={selectedTimeOption}
                        options={timeOptions}
                        onChange={this.onTimeChange}
                    />
                    </Segment>
                    <Segment style={{maxWidth: '10%'}}>
                    <Dropdown
                        placeholder='Borough'
                        fluid
                        selection
                        value={selectedBoro}
                        options={boroughOptions}
                        onChange={this.onBoroughChange}
                    />
                    </Segment>
                    <Segment style={{maxWidth: '20%'}}>
                    <Dropdown
                        placeholder='Units'
                        fluid
                        multiple
                        selection
                        value={selectedUnits}
                        options={unitOptions}
                        onChange={this.onUnitChange}
                    />
                    </Segment>
                    <Segment style={{maxWidth: '40%'}}>
                    <Dropdown
                        disabled={neighborhoodOptions.length === 0}
                        placeholder='Neighborhood'
                        fluid
                        multiple
                        search
                        selection
                        value={selectedNeighborhoods}
                        options={neighborhoodOptions}
                        onChange={this.onNeighborhoodChange}
                    />
                    </Segment>
                </Segment.Group>
                <Segment style={{height:'100%'}} basic>
                    <RateChangeChart chartData={chartData} option={selectedChartOption} timeOption={selectedTimeOption} />
                </Segment>
                </>
        )
    }

    renderSaleCharts() {
        const {neighborhoodOptions, yearOptions, chartData, movingAverage} = this.state
        return (
            <>
            <Segment.Group size='mini' horizontal style={{maxHeight: '65px'}}>
                    <Segment style={{maxWidth: '15%'}}>
                    <Dropdown
                        placeholder='Borough'
                        fluid
                        selection
                        value={selectedBoro}
                        options={boroughOptions}
                        onChange={this.onBoroughChange}
                    />
                    </Segment>
                    <Segment style={{maxWidth: '20%'}}>
                    <Dropdown
                        placeholder='Units'
                        fluid
                        multiple
                        selection
                        value={selectedUnits}
                        options={unitOptions}
                        onChange={this.onUnitChange}
                    />
                    </Segment>
                    <Segment style={{maxWidth: '40%'}}>
                    <Dropdown
                        disabled={neighborhoodOptions.length === 0}
                        placeholder='Neighborhood'
                        fluid
                        selection
                        search
                        value={selectedNeighborhoods[0]}
                        options={neighborhoodOptions}
                        onChange={this.onNeighborhoodChange}
                    />
                    </Segment>
                    <Segment style={{maxWidth: '25%'}}>
                    <Dropdown
                        placeholder='Year'
                        fluid
                        multiple
                        search
                        selection
                        value={selectedYears}
                        options={yearOptions}
                        onChange={this.onYearChange}
                    />
                    </Segment>
                    
                </Segment.Group>
                <Segment style={{height:'100%'}} basic>
                    <SaleChart chartData={chartData} movingAverage={movingAverage} />
                </Segment>
                </>
        )
    }

    render() {
        const {chartType, isLoading} = this.state
        return <div style={{padding: '1em 15em', height: '85vh'}}> 
            {/* <Container> */}
                <Dropdown
                        placeholder='Chart'
                        fluid
                        selection
                        defaultValue={chartType}
                        options={chartTypeOptions}
                        onChange={this.onChartTypeChange}
                />
                {isLoading ? (
                    <Spinner active />
                ) : chartType === ChartType.Trends ? (
                    this.renderTrendCharts()
                ) : chartType === ChartType.Sales ? (
                    this.renderSaleCharts()
                ) : null }
            {/* </Container> */}
        </div>
    }
}