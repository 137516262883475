class BucketCalc {
    static selectPrice(calc, label) {
        return calc && calc.allPrices && calc.allPrices.length > 0 ? calc.allPrices.find(_ => _.label.toLowerCase() === label.toLowerCase()) : null
    }

    static priceToUse(calc, label) {
        return this.selectPrice(calc, label)?.price ?? 0
    }

    static psfToUse(calc, label) {
        return this.selectPrice(calc, label)?.psf ?? 0
    }

    static zToUse(calc, label) {
        return this.selectPrice(calc, label)?.z ?? 0
    }

    static cappedPriceToUse(calc, label) {
        let found = this.selectPrice(calc, label)
        if (found) {
            if (found.cappedPrice) return found.cappedPrice
            return found.price
        } 
        return 0
    }

    static cappedPsfToUse(calc, label) {
        let found = this.selectPrice(calc, label)
        if (found) {
            if (found.cappedPsf) return found.cappedPsf
            return found.psf
        } 
        return 0
    }

    static cappedZToUse(calc, label) {
        let found = this.selectPrice(calc, label)
        if (found) {
            if (found.cappedZ) return found.cappedZ
            return found.z
        } 
        return 0
    }

    static highestPrice(calc) {
        if (!calc || !calc.allPrices) return 0
        let tmpPrices = [...calc.allPrices.filter(_ => _.options)]
        if (tmpPrices.length === 0) return 0
        tmpPrices.sort((a, b) => a.price < b.price ? 1 : -1)
        let found = tmpPrices[0]
        return found
    }

    static highestPriceToUse(calc) {
        let found = this.highestPrice(calc)
        if (found) {
            if (found.cappedPrice) return found.cappedPrice
            return found.price
        }
    }

    static highestPsfToUse(calc) {
        let found = this.highestPrice(calc)
        if (found) {
            if (found.cappedPsf) return found.cappedPsf
            return found.psf
        }
    }

    static highestZToUse(calc) {
        let found = this.highestPrice(calc)
        if (found) {
            if (found.cappedZ) return found.cappedZ
            return found.z
        }
    }

    static lowestPrice(calc) {
        if (!calc || !calc.allPrices) return 0
        let tmpPrices = [...calc.allPrices.filter(_ => _.options)]
        if (tmpPrices.length === 0) return 0
        tmpPrices.sort((a, b) => a.price > b.price ? 1 : -1)
        let found = tmpPrices[0]
        return found
    }

    static lowestPriceToUse(calc) {
        let found = this.lowestPrice(calc)
        if (found) {
            if (found.cappedPrice) return found.cappedPrice
            return found.price
        }
    }

    static lowestPsfToUse(calc) {
        let found = this.lowestPrice(calc)
        if (found) {
            if (found.cappedPsf) return found.cappedPsf
            return found.psf
        }
    }

    static lowestZToUse(calc) {
        let found = this.lowestPrice(calc)
        if (found) {
            if (found.cappedZ) return found.cappedZ
            return found.z
        }
    }

    static selectedPrice(calc) {
        if (!calc || !calc.allPrices) return null
        let found = calc.allPrices.find(_ => _.selected === true && _.options === true)
        return found
    }

    static selectedPriceToUse(calc) {
        if (!calc) return 0
        let found = this.selectedPrice(calc)
        if (found) {
            if (found.cappedPrice) return found.cappedPrice
            return found.price
        }
        return 0
    }

    static selectedPsfToUse(calc) {
        if (!calc) return 0
        let found = this.selectedPrice(calc)
        if (found) {
            if (found.cappedPsf) return found.cappedPsf
            return found.psf
        }
        return 0
    }

    static selectedZToUse(calc) {
        if (!calc) return 0
        let found = this.selectedPrice(calc)
        if (found) {
            if (found.cappedZ) return found.cappedZ
            return found.z
        }
        return 0
    }

    static secondaryZToUse(calc) {
        if (!calc) return 0
        let found = this.secondaryPrice(calc)
        if (found) {
            if (found.cappedZ) return found.cappedZ
            return found.z
        }
        return 0
    }

    static secondaryPrice(calc) {
        if (!calc) return null
        let found = calc.allPrices.find(_ => !_.selected && _.options)
        return found
    }

    static secondaryPriceToUse(calc) {
        if (!calc) return 0
        let found = this.secondaryPrice(calc)
        if (found) {
            if (found.cappedPrice) return found.cappedPrice
            return found.price
        }
        return 0
    }

    static secondaryPsfToUse(calc) {
        if (!calc) return 0
        let found = this.secondaryPrice(calc)
        if (found) {
            if (found.cappedPsf) return found.cappedPsf
            return found.psf
        }
        return 0
    }

    static swapSelectedPrices(calc) {
        let selectedPrice = this.selectedPrice(calc)
        let secondarySelectedPrice = this.secondaryPrice(calc)
        delete selectedPrice.selected
        secondarySelectedPrice.selected = true
    }

    static setHighestSelected(calc) {
        let highestPrice = this.highestPrice(calc)
        let lowestPrice = this.lowestPrice(calc)
        if (highestPrice) {
            highestPrice.selected = true
        }
        if (lowestPrice && lowestPrice.label !== highestPrice.label) {
            delete lowestPrice.selected
        }
    }

    static setLowestSelected(calc) {
        let highestPrice = this.highestPrice(calc)
        let lowestPrice = this.lowestPrice(calc)
        if (lowestPrice) {
            lowestPrice.selected = true
        }
        if (highestPrice && highestPrice.label !== lowestPrice.label) {
            delete highestPrice.selected
        }
    }
}

export default BucketCalc