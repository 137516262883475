import React from 'react'

const subwayOrder = ['1','2','3','4','5','6','6 express','7','7 express','a','c','e','b','d','f','m','g','j','z','l','n','q','r','w','sf','s','sr','sir','h','t']

function isExpress(name) {
    return name.includes('express')
}

function getLineName(name) {
    return isExpress(name) ? name.substring(0, 1) + 'd' : name
}

const getLineMargin = (name) => {
    return isExpress(name) ? {margin: '0 0 -4px 3px'} : {margin: '0 0 -3px 3px'}
}

const getLineWidth = (name) => {
    return isExpress(name) ? '18' : '16'
}

const MTASubwayIcon = ({subwayLine}) => {
    const lines = subwayLine.split('-')

    const linesSorted = lines.map(line => {
        var l = subwayOrder.indexOf(line.toLowerCase())
        return [l, line]
    }).sort((a, b) => {
        return a[0] - b[0]
    }).map(line => {
        return line[1].toLowerCase()
    })

    return linesSorted.map((line, index) => {
        return <span key={index}><img style={getLineMargin(line)} src={`https://unpkg.com/mta-subway-bullets@0.5.0/svg/${getLineName(line)}.svg`} alt={line} width={getLineWidth(line)} /></span>
    })
}

export default MTASubwayIcon

/* <img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/1.svg" alt="1" width="48" />
<img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/2.svg" alt="2" width="48"/>
<img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/3.svg" alt="3" width="48"/>
<img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/4.svg" alt="4" width="48"/>
<img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/5.svg" alt="5" width="48"/>
<img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/6.svg" alt="6" width="48"/>
<img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/6d.svg" alt="6d" width="48"/>
<img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/7.svg" alt="7" width="48"/>
<img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/7d.svg" alt="7d" width="48"/>
<img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/a.svg" alt="a" width="48"/>
<img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/c.svg" alt="c" width="48"/>
<img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/e.svg" alt="e" width="48"/>
<img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/b.svg" alt="b" width="48"/>
<img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/d.svg" alt="d" width="48"/>
<img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/f.svg" alt="f" width="48"/>
<img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/m.svg" alt="m" width="48"/>
<img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/g.svg" alt="g" width="48"/>
<img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/j.svg" alt="j" width="48"/>
<img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/z.svg" alt="z" width="48"/>
<img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/l.svg" alt="l" width="48"/>
<img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/n.svg" alt="n" width="48"/>
<img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/q.svg" alt="q" width="48"/>
<img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/r.svg" alt="r" width="48"/>
<img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/w.svg" alt="w" width="48"/>
<img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/sf.svg" alt="sf" width="48"/>
<img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/s.svg" alt="s" width="48"/>
<img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/sr.svg" alt="sr" width="48"/>
<img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/sir.svg" alt="sir" width="48"/>
<img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/h.svg" alt="h" width="48"/>
<img src="https://unpkg.com/mta-subway-bullets@0.5.0/svg/t.svg" alt="t" width="48"/> */
