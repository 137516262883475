import React from 'react'
import moment from 'moment'
import { formatNumber, formatDate } from '../../utility/Helper'
import { Icon } from 'semantic-ui-react'


export const lotOptions = {
  title: 'Parcels',
  description: null,
  expand: null,
  headerColor: '#ddebf7',
  defaultSortField: 'isOwned',
  defaultSortAsc: false 
}

export const lotColumns  = [
  {
    name: ' ',
    width: '32px',
    selector: 'isOwned',
    sortable: true,
    cell: row => <>{row.isOwned ? (<Icon name='checkmark' color='green'/> ) : null }</>
 },
  {
      name: 'Property Address',
      selector: 'propertyAddress',
      sortable: true,
      wrap: true,
      grow: 1.5
  },
  {
    name: 'Borough',
    selector: 'borough',
    sortable: true,
    center: true,
    width: '60px'
  },
  {
    name: 'Building Class',
    selector: 'bldgClass',
    sortable: true,
    center: true,
    width: '60px'
  },
  {
      name: 'Res Units',
      selector: 'residentialUnits',
      sortable: true,
      center: true,
      width: '60px'
  },
  {
    name: 'Total Units',
    selector: 'unitsTotal',
    sortable: true,
    center: true,
    width: '60px'
},
  {
      name: 'Owner',
      selector: 'entity',
      sortable: true,
      grow: 1.5,
      cell: row => row.entity
  },
  {
      name: 'Owner Address',
      selector: 'entityAddress',
      sortable: true,
      grow: 1.5,
      wrap: true,
      cell: row => row.entityAddress
  },
  {
    name: 'Purchase Price',
    selector: 'acquisitionAmount',
    sortable: true,
    center: true,
    format: row => (row.acquisitionAmount ? `$${formatNumber(row.acquisitionAmount)}` : '-')
  },
  {
      name: 'Purchase Date',
      selector: 'dateAcquired',
      sortable: true,
      center: true,
      format: row => (row.dateAcquired ? formatDate(row.dateAcquired) : '-')
  },
  {
    name: 'Lender',
    selector: 'lender',
    sortable: true,
    grow: 1.5,
    wrap: true
  },
  {
      name: 'Loan Amount',
      selector: 'loanAmount',
      sortable: true,
      center: true,
      format: row => (row.loanAmount ? `$${formatNumber(row.loanAmount)}` : '-')
    },
    {
      name: 'Loan Date',
      selector: 'loanDate',
      sortable: true,
      center: true,
      format: row => (row.loanDate ? formatDate(row.loanDate) : '-')
    },
    // {
    //     name: 'Mortgage Date',
    //     selector: 'latestmortgage_date',
    //     sortable: true,
    //     center: true,
    //     format: row => (row.latestmortgage_date ? moment.utc(row.latestmortgage_date).format('l') : '-')
    // },
    // {
    //     name: 'Officer',
    //     selector: 'latestOfficer.name',
    //     sortable: true,
    //     cell: row => row.latestOfficer?.name
    // },
    // {
    //   name: 'Organization',
    //   selector: 'latestOrganization.name',
    //   sortable: true,
    //   cell: row => row.latestOrganization?.name
    // }
]

export const mastersOptions = {
    title: 'Documents',
    description: null,
    expand: false,
    headerColor: '#ddebf7',
    defaultSortField: 'documentId'
}

export const mastersColumns = [
    {
      name: 'Document',
      selector: 'documentId',
      sortable: true,
    },
    // {
    //     name: 'CRFN',
    //     selector: 'crfn',
    //     sortable: true,
    // },
    {
      name: 'Date',
      selector: 'documentDate',
      sortable: true,
      cell: row => (moment.utc(row.documentDate).format('l'))
    },
    {
        name: 'Amount',
        selector: 'documentAmount',
        center: true,
        cell: row => (row.documentAmount ? `$${formatNumber(row.documentAmount)}` : '-')
     },
    {
      name: 'Type',
      selector: 'documentType',
      sortable: true,
    },
    // {
    //    name: '% Transferred',
    //    selector: 'percentTransferred',
    //    center: true,
    //    width: '50px',
    //    cell: row => (row.percentTransferred ? `${formatNumber(row.percentTransferred)}%` : '-')
    // }
]

export const legalsOptions = {
    title: 'Parcels',
    description: null,
    expand: false,
    // expandOptions: mastersOptions,
    // expandColumns: mastersColumns,
    // expandData: 'masters',
    headerColor: '#ddebf7',
    defaultSortField: 'address'
}

export const subLegalsColumns = [
    {
        name: 'Address',
        selector: 'address',
        sortable: true,
        wrap: true,
    }
]

export const legalsColumns = [
    {
        name: 'Address',
        selector: 'address',
        sortable: true,
        wrap: true,
        grow: 1.5
    },
    {
      name: 'Borough',
      selector: 'borough',
      sortable: true,
      center: true,
      width: '50px'
    },
    {
        name: 'Units',
        selector: 'unitstotal',
        sortable: true,
        center: true,
        width: '50px'
    },
    {
        name: 'Entity',
        selector: 'latestMortgageParty.name',
        sortable: true,
        grow: 1.5,
        cell: row => row.latestMortgageParty?.name
    },
    {
        name: 'Entity Address',
        selector: 'latestMortgageParty.displayAddress',
        sortable: true,
        grow: 1.5,
        wrap: true,
        cell: row => row.latestMortgageParty?.displayAddress
    },
    {
      name: 'Purchased',
      selector: 'latestdeed_amount',
      sortable: true,
      center: true,
      format: row => (row.latestdeed_amount ? `$${formatNumber(row.latestdeed_amount)}` : '-')
    },
    {
        name: 'Deed Date',
        selector: 'latestdeed_date',
        sortable: true,
        center: true,
        format: row => (row.latestdeed_date ? moment.utc(row.latestdeed_date).format('l') : '-')
    },
    {
        name: 'Loan',
        selector: 'latestmortgage_total',
        sortable: true,
        center: true,
        format: row => (row.latestmortgage_total ? `$${formatNumber(row.latestmortgage_total)}` : '-')
      },
      {
        name: 'Balance',
        selector: 'latestmortgage_balance',
        sortable: true,
        center: true,
        format: row => (row.latestmortgage_balance ? `$${formatNumber(row.latestmortgage_balance)}` : '-')
      },
      {
          name: 'Mortgage Date',
          selector: 'latestmortgage_date',
          sortable: true,
          center: true,
          format: row => (row.latestmortgage_date ? moment.utc(row.latestmortgage_date).format('l') : '-')
      },
      {
          name: 'Officer',
          selector: 'latestOfficer.name',
          sortable: true,
          cell: row => row.latestOfficer?.name
      },
      {
        name: 'Organization',
        selector: 'latestOrganization.name',
        sortable: true,
        cell: row => row.latestOrganization?.name
      }
]

export const partyOptions = {
  title: 'Entities',
  description: null,
  expand: false,
  headerColor: '#ddebf7',
  defaultSortField: 'name'
}
export const partyColumns = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true
  },
  {
    name: 'Address',
    selector: 'displayAddress',
    sortable: true,
    grow: 2
  }
]

export const originatorOptions = {
  title: 'Originators',
  description: null,
  expand: false,
  headerColor: '#ddebf7',
  defaultSortField: 'name'
}
export const originatorColumns = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true
  },
  {
    name: 'Id',
    selector: 'originatorId',
    sortable: true,
  },
  {
    name: 'Type',
    selector: 'type',
    sortable: true,
    format: row => row.type === 1 ? 'Organization' : 'Individual'
  }
]