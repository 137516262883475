import React from "react"
import { VictoryChart, VictoryVoronoiContainer, VictoryAxis, VictoryTooltip, VictoryTheme, VictoryScatter } from 'victory';
import {roundToNearest} from '../../utility/Helper'
import { min, max } from "../../utility/MathHelper";

const HorizontalChart = ({ chartData, domain, nearest = 20 }) => {
    var allXs = chartData?.map(_ => _.x)
    var lowestX = min(allXs)
    var highestX = max(allXs)

    var lowX = domain['x'][0]
    var highX = domain['x'][1]

    var newAnchor = null
    if (lowestX < lowX)
        newAnchor = Math.abs(roundToNearest(lowestX, nearest))

    if (highestX > highX) {
        var newHigh = roundToNearest(highestX, nearest)
        if (newAnchor && newAnchor < newHigh) {
            newAnchor = newHigh
        }
    } 

    if (newAnchor) {
        domain['x'][0] = newAnchor * -1
        domain['x'][1] = newAnchor
    }

    return (
        <VictoryChart
            height={60}
            domain={domain}
            theme={VictoryTheme.material}
            containerComponent={
                <VictoryVoronoiContainer
                    labels={({ datum }) => datum.title}
                    labelComponent={
                        <VictoryTooltip  dy={50} constrainToVisibleArea />
                      }
                />
            }
            >
            <VictoryAxis
            style={{ 
                axis: {stroke: "black"}, 
                ticks: {stroke: "black"},
                tickLabels: { fill:"black"},
                grid: { stroke: 'transparent' }
            }}
            tickFormat={(t) => `${t}%`} />
            <VictoryAxis
            dependentAxis
            style={{ 
                axis: {stroke: "transparent"}, 
                ticks: {stroke: "transparent"},
                tickLabels: { fill:"transparent"},
                grid: { stroke: 'transparent' }
            }} />

        <VictoryScatter 
        data={chartData}
        style={{
            labels: { fontSize: 9 },
            data: {
                fill: ({ datum }) => datum.color
                //stroke: ({ datum }) => datum.color,
                //fillOpacity: 0.7,
                //strokeWidth: 3  
            },
        }}
        />

        </VictoryChart>
    )
}

export default React.memo(HorizontalChart)