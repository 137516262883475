import {nameExpression, emailExpression} from './Global'

const passRoles = ["SuperAdmin", "SuperMember", "UrbynAdmin", "UrbynMember"]

const adminRoles = ["SuperAdmin", "UrbynAdmin"]

export const userManagerSettings = {
  autoSignIn: true,
  authority: process.env.REACT_APP_SSO_AUTHORITY_URL,
  clientId: process.env.REACT_APP_SSO_CLIENT_ID,
  clientSecret: process.env.REACT_APP_SSO_CLIENT_SECRET,
  redirectUri: process.env.REACT_APP_SSO_REDIRECT_URI,
  responseType: 'code',
  //scope: 'openid profile roles property-api',
  postLogoutRedirectUri: process.env.REACT_APP_SSO_LOGOUT_URI,
  automaticSilentRenew: true,
  silentRedirectUri: process.env.REACT_APP_SSO_SILENT_RENEW_URI,
  accessTokenExpiringNotificationTime: 100
}

let userManager = null

export function setUserManager(uManager) {
  userManager = uManager
}

export const getUser = () => {
  return userManager && userManager.getUser()
  .then(user => user)
}

export const getUserDisplayName = (name) => {
  return typeof name === 'string' ? name : name[name.length-1]
}

export const getUserShortDisplayName = (name) => {
  var resultName = ''
  var displayName = typeof name === 'string' ? name : name[name.length-1]
  if (displayName.match(emailExpression)) {
    resultName = displayName[0]
  } else {
    var pieces = displayName.match(nameExpression)
    resultName = `${pieces[0]} ${pieces[pieces.length-1]}`
  }

  return resultName.toUpperCase()
}

var isAdmin = false

export const isUserAdmin = () => {
  return isAdmin
}

export const userSignin = () => {
  userManager.signinRedirect()
}

export const userSignout = () => {
  userManager.signoutRedirect()
}

export const userAuthorized = (role) => {
  var result = false
  if (role) {
    if (typeof role === 'string') {
      if (passRoles.includes(role)) {
        result = true
      }
    } else {
      if (passRoles.some(_ => role.indexOf(_) >= 0)) {
        result = true
      }
    }
    isAdmin = userAdmin(role)
  }
  return result
}

export const userAdmin = (role) => {
  var result = false
  if (role) {
    if (typeof role === 'string') {
      if (adminRoles.includes(role)) {
        result = true
      }
    } else {
      if (adminRoles.some(_ => role.indexOf(_) >= 0)) {
        result = true
      }
    }
  }
  return result
}