import moment from 'moment'
import { formatNumber } from '../../utility/Helper'

export const dobViolationsOptions = {
    title: 'DOB Violations',
    description: 'description',
    expand: true,
    headerColor: '#ddebf7'
}

export const dobViolationsColumns = [
    {
      name: 'Violation',
      selector: 'violationNumber',
      sortable: true
    },
    {
      name: 'Issued',
      selector: 'dateIssued',
      sortable: true,
      format: row => (moment.utc(row.dateIssued).format('l'))
    },
    {
      name: 'Type',
      selector: 'violationType',
      sortable: true
    },
    {
       name: 'Penalty',
       selector: 'penalty',
       center: true,
       format: row => (row.penalty ? `$${formatNumber(row.penalty)}` : '-')
    }
]

export const dobComplaintsOptions = {
    title: 'DOB Complaints',
    description: 'regarding',
    expand: true,
    headerColor: '#ddebf7'
}
export const dobComplaintsColumns = [
    {
      name: 'Complaint',
      selector: 'complaintNumber',
      sortable: true
    },
    {
      name: 'Received',
      selector: 'dateReceived',
      sortable: true,
      format: row => (moment.utc(row.dateReceived).format('l'))
    },
    {
      name: 'Category',
      selector: 'categoryDescription',
      sortable: true
    }
]

export const hpdViolationsOptions = {
  title: 'HPD Violations',
  description: 'novDescription',
  expand: true,
  headerColor: '#fff2cc'
}
export const hpdViolationsColumns = [
  {
    name: 'Violation',
    selector: 'violationId',
    sortable: true
  },
  {
    name: 'Unit',
    selector: 'story',
    sortable: true,
    center: true,
  },
  {
    name: 'Class',
    selector: 'class',
    sortable: true,
    center: true
  },
  {
    name: 'Inspection Date',
    selector: 'inspectionDate',
    sortable: true,
    center: true,
    format: row => (moment.utc(row.inspectionDate).format('l'))
  },
  {
    name: 'Approved Date',
    selector: 'approvedDate',
    sortable: true,
    center: true,
    format: row => (moment.utc(row.approvedDate).format('l'))
  },
  {
    name: 'Status',
    selector: 'violationStatus',
    sortable: true,
    center: true
  },
  {
    name: 'Latest Update',
    selector: 'currentStatus',
    sortable: true,
    grow: 2
  }
]

export const hpdVacatesOptions = {
  title: 'HPD Vacate Orders',
  description: null,
  expand: false,
  headerColor: '#fff2cc'
}
export const hpdVacatesColumns = [
  {
    name: 'Vacate Order',
    selector: 'vacateOrderNumber',
    sortable: true
  },
  {
    name: 'Reason',
    selector: 'primaryVacateReason',
    sortable: true,
  },
  {
    name: 'Type',
    selector: 'vacateType',
    sortable: true,
  },
  {
    name: 'Issued',
    selector: 'vacateEffectiveDate',
    sortable: true,
    format: row => (row.vacateEffectiveDate ? moment.utc(row.vacateEffectiveDate).format('l') : '-')
  },
  {
    name: 'Rescinded',
    selector: 'rescindDate',
    sortable: true,
    format: row => (row.rescindDate ? moment.utc(row.rescindDate).format('l') : '-')
  },
  {
    name: '# Units',
    selector: 'numberOfVacatedUnits',
    sortable: true,
    center: true
  }
]

export const hpdChargesOptions = {
  title: 'HPD Charges',
  description: 'description',
  expand: true,
  headerColor: '#fff2cc'
}
export const hpdChargesColumns = [
  {
    name: 'Charge',
    selector: 'chargeNumber',
    sortable: true,
    cell: row => (`${row.chargeType}-${row.chargeNumber}`)
  },
  {
    name: 'Job Type',
    selector: 'workTypeGeneral',
    sortable: true,
    center: true,
    format: row => (row.workTypeGeneral ? row.workTypeGeneral : '-')
  },
  {
    name: 'Invoice Date',
    selector: 'invoiceDate',
    sortable: true,
    center: true,
    format: row => (moment.utc(row.invoiceDate).format('L'))
  },
  {
    name: 'Charge',
    selector: 'chargeAmount',
    sortable: true,
    center: true,
    format: row => (row.chargeAmount ? `$${formatNumber(row.chargeAmount)}` : '-')
  },
  {
    name: 'Transfer to DoF',
    selector: 'dateTransferDoF',
    sortable: true,
    center: true,
    format: row => (row.dateTransferDoF ? moment.utc(row.dateTransferDoF).format('l') : '-')
  }
]

export const ecbHearingsOptions = {
  title: 'ECB Hearings and Corrections',
  description: 'description',
  expand: true,
  headerColor: '#fce4d6'
}
export const ecbHearingsColumns = [
  {
    name: 'Violation',
    selector: 'violationNumber',
    sortable: true
  },
  {
    name: 'Type',
    selector: 'type',
    sortable: true
  },
  {
    name: 'Agency',
    selector: 'issuingAgency',
    sortable: true
  },
  {
    name: 'Respondent',
    selector: 'respondentName',
    sortable: true
  },
  {
    name: 'Hearing Date',
    selector: 'hearingDate',
    sortable: true,
    center: true,
    format: row => (row.hearingDate ? moment.utc(row.hearingDate).format('l') : '-')
  }
]

export const ecbBalanceOptions = {
  title: 'ECB Open Balances',
  description: 'description',
  expand: true,
  headerColor: '#fce4d6'
}
export const ecbBalanceColumns = [
  {
    name: 'Violation',
    selector: 'violationNumber',
    sortable: true
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true
  },
  {
    name: 'Agency',
    selector: 'issuingAgency',
    sortable: true
  },
  {
    name: 'Hearing Date',
    selector: 'hearingDate',
    sortable: true,
    center: true,
    format: row => (row.hearingDate ? moment.utc(row.hearingDate).format('l') : '-')
  },
  {
    name: 'Balance',
    selector: 'balance',
    sortable: true,
    format: row => (row.balance ? `$${formatNumber(row.balance)}` : '-')
  },
]