import React from "react"
import {VictoryChart, VictoryLegend, VictoryLine, VictoryAxis, VictoryTheme, VictoryTooltip, createContainer} from 'victory';
import { formatNumber } from "../../utility/Helper";

const VictoryZoomVoronoiContainer = createContainer("zoom", "voronoi");

var stringToColor = function(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var color = '#';
    for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xFF;
      color += ('00' + value.toString(16)).substr(-2);
    }
    
    return color;
  }

const RateChangeChart = ({chartData, option, timeOption}) => {
    const legendData = chartData?.map(_ => {
        return {
            name: _.neighborhood,
            symbol: {fill : stringToColor(_.neighborhood)} 
        }
    })
    return <VictoryChart
        width={1400}
        height={800}
        //categories={{x: months}}
        // animate={{
        //     duration: 2000,
        //     onLoad: { duration: 500 }
        // }}
        domainPadding={10}
        theme={VictoryTheme.material}
        containerComponent={
            <VictoryZoomVoronoiContainer voronoiDimension="x"
              labels={({ datum }) => option === 1 ? `${formatNumber(datum.y)}%` : `$${formatNumber(datum.y)}`}
              labelComponent={<VictoryTooltip cornerRadius={10} pointerLength={20} />}
              /> }
        >
            <VictoryAxis
                dependentAxis
                label={option === 1 ? 'Rate (%)' : option === 2 ? 'PSF ($)' : ''}
                standalone={false}
            />
            <VictoryAxis
                //label="Date"
                //tickFormat={() => ''}
                //tickValues={[]}
                tickCount={6}
                standalone={false}
            />
            {legendData && legendData.length > 0 ? (
                <VictoryLegend x={75} y={0}
                title="Neighborhoods"
                centerTitle
                orientation="horizontal"
                gutter={20}
                style={{ border: { stroke: "black" }, title: {fontSize: 20 } }}
                data={legendData}
            />
            ) : null}
        {chartData?.map(element => { 
        
        return <VictoryLine
            animate={{
                duration: 1000,
                onLoad: { duration: 500 }
            }}
            interpolation='basis'
            style={{
                data: { strokeWidth: 5, stroke: stringToColor(element?.neighborhood), strokeDasharray: (element?.units-1)*3 },
                //parent: { border: `11px solid #ccc`}
                labels:{fill: stringToColor(element?.neighborhood)}
            }}
            data={
                option === 1 ? 
                    (timeOption === 1 ? element?.rateChanges : element?.quarterlyChanges) :
                option === 2 ?
                    (timeOption === 1 ? element?.data : element?.quarterlyData) :
                []
            }
        />   
        }     
    )}
    </VictoryChart>
}

export default React.memo(RateChangeChart)