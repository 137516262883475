import React from "react";
import { Button, Input } from "semantic-ui-react";

export const customStyles = {
  subHeader: {
    style: {
      padding: "0",
      backgroundColor: "transparent",
    },
  },
  headRow: {
    style: {
      height: "32px",
      maxHeight: "32px",
      minHeight: "32px",
      //backgroundColor: 'lightblue'
    },
  },
  headCells: {
    style: {
      color: "#202124",
      fontSize: "11px",
      fontWeight: "bolder",
      textTransform: "uppercase",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "rgb(230, 244, 244)",
      borderBottomColor: "#FFFFFF",
      outline: "1px solid #FFFFFF",
    },
    style: {
      fontSize: "11px",
      minHeight: "40px !important",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};

export const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <Input
    label={
      <Button
        style={{ minWidth: "48px" }}
        type="button"
        onClick={onClear}
        icon="close"
      ></Button>
    }
    labelPosition="right"
    size="small"
    type="text"
    placeholder="Search..."
    value={filterText}
    onChange={onFilter}
  />
);


export const Badge = ({ text, isTrend, isUpTrend }) => {
  let colorScheme = 'green';

  if (isTrend) {
    colorScheme = isUpTrend ? 'green' : 'red';
  } else
    switch (text) {
      case 'C1':
        colorScheme = 'red';
        break;
      case 'C2':
        colorScheme = 'orange';
        break;
      case 'C3':
        colorScheme = 'yellow';
        break;
      case 'C4':
        colorScheme = 'yellow';
        break;
      case 'C5':
        colorScheme = 'teal';
        break;
      case 'C6':
        colorScheme = 'green';
        break;
      default:
        break;
    }

  return (
    <div className={`badge ${colorScheme}`}>
      {/* {isTrend && (isUpTrend ? <ArrowUp /> : <ArrowDown />)} */}
      {text}
    </div>
  );
};