import React, { useState, useEffect } from 'react';
import { formatNumber } from '../../utility/Helper';

export const FormattedInputType = {
  NONE: 0,
  MONEY: 1,
  PERCENT: 2,
  NUMBER: 3
}

const MoneyFormattedInput = ({ value, onChange, onBlur, defaultValue, disabled, placeholder = null }) => {
  const [innerValue, setInnerValue] = useState(defaultValue);

  useEffect(() => {
    if (!isNaN(defaultValue)) setInnerValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (!isNaN(value)) {
      setInnerValue(value);
    }
  }, [value]);

  const getClearedValue = (value) => {
    if (!value) return 0
    return typeof value === 'number'
      ? value + ''
      : value.replace(/[^\d.]/g, '');
  };

  const getFormated = (value) => {
    value = getClearedValue(value);

    if (!value || value === '') return '';

    //clear more than one dot and use the first occurance
    const [first, ...rest] = value.split('.');
    value = rest.length > 0 ? first + '.' + rest.join('') : first;

    if (isNaN(value)) return '';

    const floatValue = value.indexOf('.') > -1 ? '.' + value.split('.')[1] : '';
    return (
      '$' + formatNumber(parseFloat(value.split('.')[0]).toFixed(2)) + floatValue
    );
  };

  const onChangeHandler = (e) => {
    setInnerValue(e.currentTarget.value);
    onChange && onChange(e, getClearedValue(e.currentTarget.value));
  };

  const onBlurHandler = (e) => {
    setInnerValue(e.currentTarget.value);
    onBlur && onBlur(e, getClearedValue(e.currentTarget.value));
  };

  return (
    <input type='text' onFocus={(e) => e.target.select()} disabled={disabled} value={getFormated(innerValue)} onChange={onChangeHandler} onBlur={onBlurHandler} placeholder={placeholder ? placeholder : '$0'} />
  );
};

const PercentFormattedInput = ({ value, onChange, onBlur, defaultValue, disabled }) => {
  const [innerValue, setInnerValue] = useState(defaultValue);

  useEffect(() => {
    if (!isNaN(defaultValue)) setInnerValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (!isNaN(value)) {
      setInnerValue(value);
    }
  }, [value]);

  const getClearedValue = (value) => {
    if (!value) return 0
    return typeof value === 'number'
      ? value + ''
      : value.replace(/[^\d.]/g, '');
  };

  const getFormated = (value) => {
    value = getClearedValue(value);

    if (!value || value === '') return '';

    //clear more than one dot and use the first occurance
    const [first, ...rest] = value.split('.');
    value = rest.length > 0 ? first + '.' + rest.join('') : first;

    if (isNaN(value)) return '';

    const floatValue = value.indexOf('.') > -1 ? '.' + value.split('.')[1] : '';
    return (
      formatNumber(parseFloat(value.split('.')[0]).toFixed(2)) + floatValue + "%"
    );
  };

  const onChangeHandler = (e) => {
    setInnerValue(e.currentTarget.value);
    onChange && onChange(e, getClearedValue(e.currentTarget.value));
  };

  const onBlurHandler = (e) => {
    setInnerValue(e.currentTarget.value);
    onBlur && onBlur(e, getClearedValue(e.currentTarget.value));
  };

  return (
    <input type='text' onFocus={(e) => e.target.select()} disabled={disabled} value={getFormated(innerValue)} onChange={onChangeHandler} onBlur={onBlurHandler} placeholder='0%' />
  );
};

const NumberFormattedInput = ({ value, onChange, onBlur, defaultValue, disabled }) => {
  const [innerValue, setInnerValue] = useState(defaultValue);

  useEffect(() => {
    if (!isNaN(defaultValue)) setInnerValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (!isNaN(value)) {
      setInnerValue(value);
    }
  }, [value]);

  const getClearedValue = (value) => {
    if (!value) return 0
    return typeof value === 'number'
      ? value + ''
      : value.replace(/[^\d.]/g, '');
  };

  const getFormated = (value) => {
    value = getClearedValue(value);

    if (!value || value === '') return '';

    //clear more than one dot and use the first occurance
    const [first, ...rest] = value.split('.');
    value = rest.length > 0 ? first + '.' + rest.join('') : first;

    if (isNaN(value)) return '';

    const floatValue = value.indexOf('.') > -1 ? '.' + value.split('.')[1] : '';
    return (
      formatNumber(parseFloat(value.split('.')[0]).toFixed(2)) + floatValue
    );
  };

  const onChangeHandler = (e) => {
    setInnerValue(e.currentTarget.value);
    onChange && onChange(e, getClearedValue(e.currentTarget.value));
  };

  const onBlurHandler = (e) => {
    setInnerValue(e.currentTarget.value);
    onBlur && onBlur(e, getClearedValue(e.currentTarget.value));
  };

  return (
    <input type='text' onFocus={(e) => e.target.select()} disabled={disabled} value={getFormated(innerValue)} onChange={onChangeHandler} onBlur={onBlurHandler} placeholder='0' />
  );
};

export {
  MoneyFormattedInput,
  PercentFormattedInput,
  NumberFormattedInput
}