import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import '../../assets/css/CompAppraisals.css'

import { Form, Segment, Button, Divider, Container, Image, Tab } from 'semantic-ui-react';
import { makeGetRequestWithCallback } from '../../utility/Services';
import { DataSetType } from '../../utility/Global';
import SearchLocationInput from '../extras/SearchLocationInput';
import { createContext } from 'react';
import LocationSection from '../extras/LocationSection';

import placeholder_image from '../../assets/images/placeholder.png'
import { parcel_get_url } from '../../utility/Config';



const getImagesFor = (key, source) => {
    //let keySplit = key.split("_")
    //let keyLength = keySplit.length
    //let u = keyLength > 1 ? keySplit[0] : null
    //let b = keyLength > 2 ? keySplit[1] : null
    //let k = keySplit[keyLength-1]
    //console.log(u, b, k)
    let keySources = source[key] //!u ? source[k] : source[u]
    if (!keySources) {
        keySources = []
    }
    console.log(keySources)
    return keySources
}

const FormRadio = ({radioName, question, values, value, onChange }) => {
    return <Form.Group grouped>
        <label>{question}</label>
        <Form.Group inline>
        {values?.map((v, index) => {
            return <Form.Field 
                key={index}
                label={v}
                control='input'
                type='radio'
                name={radioName}
                checked={v === value}
                onChange={() => onChange && onChange(v)}
                />
        })}
        </Form.Group>
    </Form.Group>
}

const FormText = ({style, question, value, placeholder, onChange}) => {
    return <Form.Group style={style}>
        <Form.Input type='number' label={question} placeholder={placeholder} defaultValue={value} onChange={(e) => onChange && onChange(e.target.value)} />
    </Form.Group>
}

const PictureContainer = ({name, title, images = [], onChange}) => {
    const photos = getImagesFor(name, images)
    return <Segment basic>
        <label>{title}</label>
        <Image.Group size='tiny'>
                <input name={name} style={{display: 'none'}} accept="image/*" id={name} type="file" capture="camera" onChange={(e) => onChange(e.target)} />
                {photos?.map((p, index) => (
                    <Image key={index} src={p.image} />
                ))}
                <label htmlFor={name}>
                    <Image style={{cursor: 'pointer'}} src={placeholder_image} />
                </label>
        </Image.Group>
    </Segment>
}

const dummyParcel = {propertyId: '91671755', label: "20 ADELE RD, CEDARHURST, NY 11516"}

const defaultSurvey = {
    units: 3,
    unitBedrooms: {1:1,2:1,3:2},
    unitBathrooms: {1:1,2:1,3:1}
}

//             const form = new FormData();
//             form.append('input_format', 'pdf');
//             form.append('output_format', 'html');
//             //form.append('file', new File([''], newUrl));
//             form.append('file', file)

//             fetch('https://api.pdfcrowd.com/convert/20.10/', {
//                 method: 'POST',
//                 headers: {
//                     'Authorization': 'Basic ' + btoa('demo:ce544b6ea52a5621fb9d55f8b542d14d')
//                 },
//                 body: form
//             }).then(result => console.log(result));

export const LocationContext = createContext({
    userLocation: {},
    setUserLocation: () => {}
});

const Photos = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [hasLocation, setHasLocation] = useState(false)
    const [searchAddress, setSearchAddress] = useState(dummyParcel.label)
    const [pageNumber, setPageNumber] = useState(1)
    const [target, setTarget] = useState(dummyParcel)

    const [survey, setSurvey] = useState(defaultSurvey)
    const [photos, setPhotos] = useState({})
    const [activeTab, setActiveTab] = useState(0)

    const [userLocation, setUserLocation] = useState(null)

    const { bbl, pid } = useParams();

    useEffect(() => {        
        if (bbl || pid) {
            if (bbl) {
                setPropertyToRun({
                    propertyId: bbl,
                    type: DataSetType.PLUTO
                })
            } else {
                setPropertyToRun({
                    propertyId: pid,
                    type: DataSetType.FIRST_AMERICAN
                })
            }
        }
    }, [])

    const setPropertyToRun = (result) => {        
        setSearchAddress(result.label)
        setTarget({ propertyId: result.propertyId, type: result.type })
    }

    const resetValues = () => {
        setTarget(null)
        setSearchAddress('')
        setSurvey(defaultSurvey)
    }

    const handleSearchChange = (written, address, parts) => {
        console.log(address, parts)

        if (address.length === 0) return

        setIsLoading(true)

        var county = parts.find(_ => _.types.includes("administrative_area_level_2"))?.long_name
        //var c = parts.find(_ => _.types.includes("locality"))?.long_name
        var state = parts.find(_ => _.types.includes("administrative_area_level_1"))?.short_name
        
        let searchAddress = address.split(",")[0].replace("-", "")
        makeGetRequestWithCallback(`${parcel_get_url}?q=${searchAddress}&c=${county}&s=${state}`,
        (result) => {
            setIsLoading(false)
            if (!result) {
                alert('No Address Found')
                return
            }

            setPropertyToRun(result)
            }
        )
    };

    useEffect(() => {
        console.log(survey)
    }, [survey])

    useEffect(() => {
        if (userLocation) {
            console.log(userLocation)
            setHasLocation(!userLocation.error)
        }
    }, [userLocation])

    useEffect(() => {
        console.log(photos)
    }, [photos])

    const handleCapture = (target) => {
        if (target.files) {
          if (target.files.length !== 0) {
            const key = target.name
            const file = target.files[0];
            const newUrl = URL.createObjectURL(file);
            let keySources = getImagesFor(key, photos)
            keySources.push({
                image:newUrl,
                userLocation
            })
            setPhotos(photos => (
                {...photos,
                    [key]: keySources
                }
            ))
          }
        }
      };


    const PageOne = ({}) => <Segment basic className='no-padding-h'>
    <Form>
    {/* <FormRadio 
         question='Condition?'
         values={[1,2,3]}
         value={survey?.condition}
         radioName='condition'
         onChange={(v) => setSurvey(survey => (
            {
                ...survey,
                condition: v
            }
         ))}
         /> */}
     <FormRadio 
         question='How many units?'
         values={[1,2,3,4]}
         value={survey?.units}
         radioName='units'
         onChange={(v) => setSurvey(survey => (
            {
                ...survey,
                units: v
            }
         ))}
         />
     {[...Array(survey?.units).keys()].map((unit, index) => (
        <Form.Group key={`${unit}-${index}`} inline style={{marginBottom: '4rem'}}>
        <FormText
            style={{marginRight: '2rem'}}
            key={`${unit}-${index+1}`}
            question={`Unit ${unit+1} Bedrooms`}
            value={survey?.unitBedrooms[unit+1]}
            placeholder='1'
            onChange={(v) => setSurvey(survey => (
                {
                    ...survey,
                    unitBedrooms: {
                        ...survey.unitBedrooms,
                        [unit+1]: Number(v)
                    }
                }
            ))}
        />
        <FormText
            key={`${unit}-${index+2}`}
            question={`Unit ${unit+1} Bathrooms`}
            value={survey?.unitBathrooms[unit+1]}
            placeholder='1'
            onChange={(v) => setSurvey(survey => (
                {
                    ...survey,
                    unitBathrooms: {
                        ...survey.unitBathrooms,
                        [unit+1]: Number(v)
                    }
                }
            ))}
        />
        </Form.Group>
     ))}
    </Form>                
    </Segment>


    // Per Unit ->
    //     Kitchen, Living Room, Dining Room, # Bedrooms, # Bathrooms
    // Basement, Utilities, Exterior Front, Exterior Rear, Street View 1, Street View 2
    const PageTwo = ({}) => <Segment basic className='no-padding-h'>
        <Tab
        //renderActiveOnly={false}
        menu={{ pointing: true }}
        activeIndex={activeTab}
        onTabChange={(e, data) => setActiveTab(data.activeIndex)}
        panes={
            [...[...Array(survey?.units).keys()].map((unit, index) => {
                    return {
                        key: index,
                        menuItem: `Unit ${unit+1}`,
                        render: () => (
                            <Tab.Pane
                                //active={activeTab === index}
                                >
                                <PictureContainer name={`${unit+1}_kitchen`} title='Kitchen' images={photos} onChange={handleCapture} />
                                <PictureContainer name={`${unit+1}_living-room`} title='Living Room' images={photos} onChange={handleCapture} />
                                <PictureContainer name={`${unit+1}_dining-room`} title='Dining Room' images={photos} onChange={handleCapture} />
                                {[...Array(survey?.unitBedrooms[unit+1]).keys()].map((bedroom, bIndex) => (
                                    <PictureContainer name={`${unit+1}_bedroom_${bedroom+1}`} key={bIndex} title={`Bedroom ${bedroom+1}`} images={photos} onChange={handleCapture} />
                                ))}
                                {[...Array(survey?.unitBathrooms[unit+1]).keys()].map((bathroom, bIndex) => (
                                    <PictureContainer name={`${unit+1}_bathroom_${bathroom+1}`} key={bIndex} title={`Bathroom ${bathroom+1}`} images={photos} onChange={handleCapture} />
                                ))}
                            </Tab.Pane>
                        )
                    }
                }),
            {
                menuItem: 'Misc',
                render: () => (
                    <Tab.Pane
                        //active={activeTab === survey?.units}
                        >
                        <PictureContainer name='basement' title='Basement' images={photos} onChange={handleCapture} />
                        <PictureContainer name='utilities' title='Utilities' images={photos} onChange={handleCapture} />
                        <PictureContainer name='exterior-front' title='Exterior Front' images={photos} onChange={handleCapture} />
                        <PictureContainer name='exterior-rear' title='Exterior Rear' images={photos} onChange={handleCapture} />
                        <PictureContainer name='street-view-1' title='Street View 1' images={photos} onChange={handleCapture} />
                        <PictureContainer name='street-view-2' title='Street View 2' images={photos} onChange={handleCapture} />
                    </Tab.Pane>
                )
            }
            ]   
        }
        />
        {/* {[...Array(survey?.units).keys()].map((unit, index) => (
            <Segment key={index}>
                <h3>Unit {unit+1} Pictures</h3>
                <PictureContainer title='Kitchen' />
                <PictureContainer title='Living Room' />
                <PictureContainer title='Dining Room' />
                {console.log([...Array(survey?.unitBedrooms[unit+1]).keys()])}
                {[...Array(survey?.unitBedrooms[unit+1]).keys()].map((bedroom, bIndex) => (
                    <PictureContainer key={bIndex} title={`Bedroom ${bedroom+1}`} />
                ))}
                {[...Array(survey?.unitBathrooms[unit+1]).keys()].map((bathroom, bIndex) => (
                    <PictureContainer key={bIndex} title={`Bathroom ${bathroom+1}`} />
                ))}
            </Segment>
        )
        )} */}
    </Segment>

    return (
        <LocationContext.Provider value={{userLocation, setUserLocation}}>
            <div className='resp-container'>
            <Segment basic style={{ minHeight: '100vh', background: '#f8f8f8', padding: '4em 2em 2em' }}>
                <Form size='small'>
                    <Form.Group>
                        <Form.Field className='comp-inline-field' inline>
                            <label>Subject Property</label>
                            <SearchLocationInput 
                                key={searchAddress}
                                className="fluid" 
                                name=''
                                label=''
                                icon='home'
                                value={searchAddress}
                                placeholder='Enter property address'
                                onChange={handleSearchChange} 
                                isLoading={isLoading} />
                        </Form.Field>
                        <Form.Field><Button icon='x' onClick={resetValues}></Button></Form.Field>
                    </Form.Group>
                </Form>
                <Divider />
                <Segment.Group className='basic' horizontal>
                    <Segment basic>
                        <Button disabled={pageNumber === 1} content='Previous' onClick={() => setPageNumber(pageNumber => pageNumber -= 1)} />
                    </Segment>
                    <Segment basic>
                        <Button floated='right' disabled={pageNumber === 2} content='Next' onClick={() => setPageNumber(pageNumber => pageNumber += 1)} />
                    </Segment>
                </Segment.Group>
                {target && (
                    <Container text>
                        {pageNumber === 1 ? (
                            <PageOne />
                        ) : pageNumber === 2 ? (
                            <>
                            {!hasLocation ? (
                                <Segment basic textAlign='center'>
                                    {/* <Button content='Request Location' onClick={() => setAskLocation(true)} /> */}
                                    <LocationSection />
                                </Segment>
                            ) : <PageTwo />}
                            </>
                        ) : null}
                    </Container>
                )}
            </Segment>
        </div>
        </LocationContext.Provider>
    )
}

export default Photos