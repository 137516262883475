import React, { Component } from 'react';
import Datatable from 'react-data-table-component'


// const DefaultSubheader = ({data}) => (
//   <Segment basic vertical style={{width: '100%'}}>
//   <ExportButton data={data} />
//   </Segment>
// )

const customStyles = (headerColor) => {
  return {
    header: {
      style: {
        
      }
    },
    subHeader: {
        style: {
            minHeight: '32px',
            padding: '0 1.1em',
            marginTop: '-12px'
        }
    },
    headRow: {
      style: {
          height: '32px',
          backgroundColor: headerColor
      }
    },
    headCells: {
      style: {
        color: '#202124',
        fontSize: '11px',
        fontWeight: 'bolder'
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: 'rgb(230, 244, 244)',
        borderBottomColor: '#FFFFFF',
        outline: '1px solid #FFFFFF',
      },
      style: {
          fontSize: '11px',
      }
    },
    pagination: {
      style: {
        border: 'none',
      },
    }
  }
}

  const DescriptionComponent = ({ data, field }) => (
    <span className='reports-sub-table-description-label'>
      {data[field]}
    </span>
  )

  const ExpanderDataTable = ({data, field, expandColumns, expandOptions}) => (
    <Datatable
    noHeader={true}
    persistTableHead={true}
    dense
    //striped
    columns={expandColumns}
    data={data[field]}
    highlightOnHover
    pointerOnHover
    customStyles={customStyles(expandOptions)}
    fixedHeader
    fixedHeaderScrollHeight='200px'
    onRowClicked={expandOptions?.rowClicked}
    />
  )

  export default class DataSubTable extends Component  {
      render () {
          const {data, columns, options, subheader, onRowClicked, fixedScrollHeight = '400px'} = this.props
        
          return (
              <Datatable
              title={options.title}
              noHeader={false}
              persistTableHead={true}
              columns={columns}
              data={data}
              highlightOnHover
              pointerOnHover
              customStyles={customStyles(options.headerColor)}
              defaultSortField={options.defaultSortField}
              defaultSortAsc={options.defaultSortAsc}
              fixedHeader
              fixedHeaderScrollHeight={fixedScrollHeight}
              //dense
              striped
              onRowClicked={onRowClicked}
              expandableRows={options.expand}
              expandableRowsComponent={options.expand && options.description ? 
              <DescriptionComponent field={options.description}/> : options.expand ? 
              <ExpanderDataTable field={options.expandData} expandColumns={options.expandColumns} expandOptions={options.expandOptions} /> : <></>}
              expandOnRowClicked
              subHeader={subheader !== null && subheader !== undefined}
              subHeaderAlign='left'
              subHeaderComponent={subheader}
              />
          )
      }
  }