import React, { useEffect, useState, useRef } from 'react'

const GoogleMap = ({ latitude, longitude }) => {
  const [isStreetView, setIsStreetView] = useState(true)
  const [isMouseOver, setIsMouseOver] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)
  
  const googleMapPanoramaContainer = useRef()
  
  useEffect(() => {
    
    let point = {
      lat: latitude,
      lng: longitude
    }

    const streetViewService = createGoogleStreetService();

    streetViewService.getPanoramaByLocation(point, 100, function (streetViewPanoramaData, status) {

      if (status === window.google.maps.StreetViewStatus.OK) {
        
        const oldPoint = point;
        point = streetViewPanoramaData.location.latLng;

        const heading = window.google.maps.geometry.spherical.computeHeading(point, oldPoint);

        const panorama = new window.google.maps.StreetViewPanorama(
          googleMapPanoramaContainer.current, {
          position: point,
          pov: {
            heading: heading,
            zoom: 0,
            pitch: 0
          }
        });

        const panoramaOptions = {
          disableDefaultUI: true,
          linksControl: false,
          enableCloseButton: false,
          addressControl: false,
          fullscreenControl: true,
          zoomControl: false
        };

        panorama.setOptions(panoramaOptions);

        setIsInitialized(true)        
      }
    });
  }, [latitude, longitude])

  // const createGoogleMap = () =>
  //   new window.google.maps.Map(this.googleMapContainer, {
  //     zoom: 16,
  //     center: {
  //       lat: this.props.latitude,
  //       lng: this.props.longitude,
  //     },
  //     disableDefaultUI: true,
  //     streetViewControl: false,
  //     fullscreenControl: true,
  //     zoomControl: false
  //   })

  const createGoogleStreetService = () =>
    new window.google.maps.StreetViewService()

  // const createTransitLayer = () =>
  //   new window.google.maps.TransitLayer();

  // const createMarker = () =>
  //   new window.google.maps.Marker({
  //     position: { lat: this.props.latitude, lng: this.props.longitude },
  //     map: this.googleMap,
  //   })

  // const handleMapChange = () => () => {
  //   setIsStreetView(!isStreetView)

  //   if (this.state.isStreetView) {
  //     googleMapContainer.style.display = "block"
  //     googleMapPanoramaContainer.style.display = "none"
  //   } else {
  //     googleMapContainer.style.display = "none"
  //     googleMapPanoramaContainer.style.display = "block"
  //   }
  // }

  // const handleMouseOver = (isMouseOver) => {
  //   resizeCanvas(isMouseOver)

  //   setIsMouseOver(isMouseOver)
  // }

  // const resizeCanvas = (isMouseOver) => {

  //   setTimeout(() => {
  //     //window.google.maps.event.trigger(this.panorama, 'resize')
  //     window.google.maps.event.trigger(googleMapPanoramaContainer, 'resize')
  //   }, 250)
  // }

  return (
    // <section className={`map-view-container${isMouseOver ? ' mouse-over' : ''}`}
    <section className={`map-view-container`}
    //onMouseEnter={(e) => {if (compsPanelVisible) {return} this.handleMouseOver(true)}}
    //onMouseLeave={(e) => {this.handleMouseOver(false)}}
    // onMouseDown={(e) => {console.log("Down")}}
    // onMouseUp={(e) => {console.log("Up")}}
    // onMouseMove={(e) => {console.log("Moving")}}
    >
      {/* <Button onClick={this.handleMapChange()} className="map-button" icon='redo' style={{display: `${isInitialized ? 'block' : 'none'}`}} /> */}
      {/* <div
            className="map-view"
            style={{display: 'none'}}
            ref={el => this.googleMapContainer = el}
          /> */}
      {/* <Image src={`http://maps.googleapis.com/maps/api/streetview?size=300x225&location=${this.props.latitude},${this.props.longitude}&fov=120&heading=${heading}&pitch=0&sensor=false&key=${process.env.REACT_APP_GOOGLE_API_KEY}`} /> */}
      <div
        className="map-view"
        id='panorama-view'
        ref={el => googleMapPanoramaContainer.current = el}
      />
    </section>
  )
}


export default GoogleMap