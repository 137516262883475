export function mean(numbers) {
    if (!Array.isArray(numbers) || numbers.length === 0) {
        return null; // Return null for empty array or invalid input
    }

    let sum = numbers.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
    }, 0);

    return sum / numbers.length;
}

export function max(array) {
    return array.length === 0 ? null : Math.max(...array);
}

export function min(array) {
    return array.length === 0 ? null : Math.min(...array);
}

export function std(values) {
    if (!Array.isArray(values) || values.length === 0) {
        return null; // Return null for an empty array or invalid input
    }

    const mean = values.reduce((acc, val) => acc + val, 0) / values.length;
    const variance = values.reduce((acc, val) => acc + ((val - mean) ** 2), 0) / values.length - 1; // Remove the -1 for population std

    return Math.sqrt(variance);
}

export function floor(value) {
    // If the value is already an integer, return it as is
    if (Number.isInteger(value)) {
        return value;
    }

    // If the value is positive, just return the integer part
    if (value > 0) {
        return parseInt(value, 10);
    }

    // If the value is negative, subtract 1 from the integer part
    // This is because parseInt rounds towards zero
    return parseInt(value, 10) - 1;
}